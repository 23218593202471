import { collection as CollectionApi } from '@vidispine/vdt-api';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useApi from './useApi';

export default function useGetCollection({
  collectionId,
  queryParams = {},
  refreshOnMount = true,
}) {
  const { request, data: collectionType = {}, isLoading, isError } = useApi(
    CollectionApi.getCollection,
  );
  const onRefresh = () => request({ collectionId, queryParams });
  useDeepCompareEffect(() => {
    if (refreshOnMount) onRefresh();
  }, [collectionId, queryParams]);
  return {
    collectionType,
    isLoading,
    isError,
    onRefresh,
  };
}
