import React from 'react';

import { SeekBar } from '@vidispine/vdt-videojs';

export class VideoSeekBar extends React.Component {
  componentDidMount() {
    const {
      player, bgColor, fillColor, markersEnabled, regions,
      onRegionClick,
    } = this.props; // player prop is the whole react component

    this.seekBar = new SeekBar({
      target: this.seekBarNode,
      player: player.current.player,
      bgColor,
      fillColor,
      markersEnabled,
      /* eslint-disable-next-line react/destructuring-assignment */
      in: this.props.in, // can not use "in" with destructuring statement - reserved word
      /* eslint-disable-next-line react/destructuring-assignment */
      out: this.props.out,
      regions,
      onRegionClick,
    });
  }

  UNSAFE_componentWillUpdate(props) {
    this.seekBar.handleNewProps(props);
  }

  render() {
    return (
      <div ref={(node) => { this.seekBarNode = node; }} />
    );
  }
}

VideoSeekBar.Region = SeekBar.Region;
