import { vFetch, isRequired } from './utils';

function getEntityTypeUrl(entityType) {
  if (entityType === 'field-group') {
    return 'metadata-field/field-group';
  }
  return entityType;
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/external-id.html APIdoc}
 *
 * `GET /API/external-id`
 *
 * @function externalid.listExternalIdNamespace
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listExternalIdNamespace({ ...props } = {}) {
  const path = '/API/external-id';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/external-id.html APIdoc}
 *
 * `GET /API/external-id/${idName}`
 *
 * @function externalid.getExternalIdNamespace
 * @param {Object} obj
 * @param {string} obj.idName
 * @returns {Promise<Object>}
 */
function getExternalIdNamespace({ idName = isRequired(), ...props }) {
  const path = `/API/external-id/${idName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/external-id.html APIdoc}
 *
 * `PUT /API/external-id/${idName}`
 *
 * @function externalid.updateExternalIdNamespace
 * @param {Object} obj
 * @param {string} obj.idName
 * @param {Object} obj.externalIdentifierNamespaceDocument
 * @returns {Promise<Object>}
 */
function updateExternalIdNamespace({
  idName = isRequired(),
  externalIdentifierNamespaceDocument,
  ...props
}) {
  const path = `/API/external-id/${idName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: externalIdentifierNamespaceDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/external-id.html APIdoc}
 *
 * `DELETE /API/external-id/${idName}`
 *
 * @function externalid.removeExternalIdNamespace
 * @param {Object} obj
 * @param {string} obj.idName
 * @returns {Promise<Object>}
 */
function removeExternalIdNamespace({ idName = isRequired(), ...props }) {
  const path = `/API/external-id/${idName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/external-id.html APIdoc}
 *
 * `GET /API/${getEntityTypeUrl(entityType)}/${entityId}/external-id/`
 *
 * @function externalid.listExternalId
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function listExternalId({ entityType = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${getEntityTypeUrl(entityType)}/${entityId}/external-id/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/external-id.html APIdoc}
 *
 * `PUT /API/${getEntityTypeUrl(entityType)}/${entityId}/external-id/${externalId}`
 *
 * @function externalid.createExternalId
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.externalId
 * @returns {Promise<Object>}
 */
function createExternalId({
  entityType = isRequired(),
  entityId = isRequired(),
  externalId = isRequired(),
  ...props
}) {
  const path = `/API/${getEntityTypeUrl(entityType)}/${entityId}/external-id/${externalId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/external-id.html APIdoc}
 *
 * `PUT /API/${getEntityTypeUrl(entityType)}/${entityId}/external-id/${externalId}`
 *
 * @function externalid.removeExternalId
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.externalId
 * @returns {Promise<Object>}
 */
function removeExternalId({
  entityType = isRequired(),
  entityId = isRequired(),
  externalId = isRequired(),
  ...props
}) {
  const path = `/API/${getEntityTypeUrl(entityType)}/${entityId}/external-id/${externalId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/external-id.html APIdoc}
 *
 * `DELETE /API/${getEntityTypeUrl(entityType)}/${entityId}/external-id`
 *
 * @function externalid.removeAllExternalId
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function removeAllExternalId({ entityType = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${getEntityTypeUrl(entityType)}/${entityId}/external-id`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listExternalIdNamespace,
  getExternalIdNamespace,
  updateExternalIdNamespace,
  removeExternalIdNamespace,
  listExternalId,
  createExternalId,
  removeExternalId,
  removeAllExternalId,
};
