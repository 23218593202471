import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/deletion-lock.html APIdoc}
 *
 * `GET /API/deletion-lock`
 *
 * @function deletionlock.listDeletionLock
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listDeletionLock({ ...props } = {}) {
  const path = '/API/deletion-lock';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/deletion-lock.html APIdoc}
 *
 * `GET /API/deletion-lock/${lockId}`
 *
 * @function deletionlock.getDeletionLock
 * @param {Object} obj
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function getDeletionLock({ lockId = isRequired(), ...props }) {
  const path = `/API/deletion-lock/${lockId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/deletion-lock.html APIdoc}
 *
 * `PUT /API/deletion-lock/${lockId`
 *
 * @function deletionlock.updateDeletionLock
 * @param {Object} obj
 * @param {string} obj.lockId
 * @param {Object} obj.deletionLockDocument
 * @returns {Promise<Object>}
 */
function updateDeletionLock({
  lockId = isRequired(),
  deletionLockDocument = isRequired(),
  ...props
}) {
  const path = `/API/deletion-lock/${lockId}`;
  return vFetch({
    path,
    method: 'PUT',
    body: deletionLockDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/deletion-lock.html APIdoc}
 *
 * `DELETE /API/deletion-lock/${lockId}`
 *
 * @function deletionlock.removeDeletionLock
 * @param {Object} obj
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function removeDeletionLock({ lockId = isRequired(), ...props }) {
  const path = `/API/deletion-lock/${lockId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/deletion-lock.html APIdoc}
 *
 * `GET /API/${entity}/${entityId}/deletion-lock/`
 *
 * @function deletionlock.listEntityDeletionLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function listEntityDeletionLock({ entity = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entity}/${entityId}/deletion-lock/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/deletion-lock.html APIdoc}
 *
 * `POST /API/${entity}/${entityId}/deletion-lock/`
 *
 * @function deletionlock.createEntityDeletionLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {Object} obj.deletionLockDocument
 * @returns {Promise<Object>}
 */
function createEntityDeletionLock({
  entity = isRequired(),
  entityId = isRequired(),
  deletionLockDocument = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/deletion-lock/`;
  return vFetch({
    path,
    method: 'POST',
    body: deletionLockDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/deletion-lock.html APIdoc}
 *
 * `GET /API/${entity}/${entityId}/deletion-lock/${lockId}`
 *
 * @function deletionlock.getEntityDeletionLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function getEntityDeletionLock({
  entity = isRequired(),
  entityId = isRequired(),
  lockId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/deletion-lock/${lockId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/deletion-lock.html APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/deletion-lock/${lockId}`
 *
 * @function deletionlock.updateEntityDeletionLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.lockId
 * @param {Object} obj.deletionLockDocument
 * @returns {Promise<Object>}
 */
function updateEntityDeletionLock({
  entity = isRequired(),
  entityId = isRequired(),
  lockId = isRequired(),
  deletionLockDocument = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/deletion-lock/${lockId}`;
  return vFetch({
    path,
    method: 'PUT',
    body: deletionLockDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/deletion-lock.html APIdoc}
 *
 * `DELETE /API/${entity}/${entityId}/deletion-lock/${lockId}`
 *
 * @function deletionlock.removeEntityDeletionLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function removeEntityDeletionLock({
  entity = isRequired(),
  entityId = isRequired(),
  lockId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/deletion-lock/${lockId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listDeletionLock,
  getDeletionLock,
  updateDeletionLock,
  removeDeletionLock,
  listEntityDeletionLock,
  createEntityDeletionLock,
  getEntityDeletionLock,
  updateEntityDeletionLock,
  removeEntityDeletionLock,
};
