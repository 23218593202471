import React from 'react';
import useSearchItem from '../hooks/useSearchItem';

export default function SearchItem({ itemSearchDocument, queryParams, matrixParams, children }) {
  const { itemListType, onRefresh, isLoading, isError } = useSearchItem({
    itemSearchDocument,
    queryParams,
    matrixParams,
  });
  const cloneProps = {
    itemSearchDocument,
    matrixParams,
    queryParams,
    itemListType,
    onRefresh,
    isLoading,
    isError,
  };
  const cloneChild = (child) => React.cloneElement(child, cloneProps);
  return React.Children.map(children, cloneChild);
}
