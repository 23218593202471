import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/auto-import.html APIdoc}
 *
 * `GET /API/storage/auto-import/`
 *
 * @function autoimport.listAutoImport
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listAutoImport({ ...props } = {}) {
  const path = '/API/storage/auto-import/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/auto-import.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/auto-import/`
 *
 * @function autoimport.updateAutoImport
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {Object} obj.autoImportRuleDocument
 * @returns {Promise<Object>}
 */
function updateAutoImport({ storageId = isRequired(), autoImportRuleDocument, ...props }) {
  const path = `/API/storage/${storageId}/auto-import/`;
  return vFetch({
    path,
    method: 'PUT',
    body: autoImportRuleDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/auto-import.html APIdoc}
 *
 * `GET /API/storage/${storageId}/auto-import/`
 *
 * @function autoimport.getAutoImport
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function getAutoImport({ storageId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/auto-import/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/auto-import.html APIdoc}
 *
 * `DELETE /API/storage/${storageId}/auto-import/`
 *
 * @function autoimport.removeAutoImport
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function removeAutoImport({ storageId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/auto-import/`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/auto-import.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/auto-import/enable`
 *
 * @function autoimport.enableAutoImport
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function enableAutoImport({ storageId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/auto-import/enable`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/auto-import.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/auto-import/disable`
 *
 * @function autoimport.disableAutoImport
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function disableAutoImport({ storageId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/auto-import/disable`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

export default {
  listAutoImport,
  updateAutoImport,
  getAutoImport,
  removeAutoImport,
  enableAutoImport,
  disableAutoImport,
};
