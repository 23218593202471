/* Note that VS may calculate +/- unit in another way */
const parseNowDate = (value = 'NOW') => {
  if (value.startsWith('NOW')) {
    if (value === 'NOW') return new Date();
    const sign = value[3];
    const number = Number(value.match(/(\d+)/)[0]);
    const unit = value.match(/(\d.*)/)[0].replace(/\d/g, '');
    const nowDiff = new Date();
    // eslint-disable-next-line default-case
    switch (unit) {
      case 'HOUR':
      case 'HOURS':
        if (sign === '+') nowDiff.setHours(nowDiff.getHours() + number);
        if (sign === '-') nowDiff.setHours(nowDiff.getHours() - number);
        break;
      case 'DAY':
      case 'DAYS':
        if (sign === '+') nowDiff.setDate(nowDiff.getDate() + number);
        if (sign === '-') nowDiff.setDate(nowDiff.getDate() - number);
        break;
      case 'MONTH':
      case 'MONTHS':
        if (sign === '+') nowDiff.setMonth(nowDiff.getMonth() + number);
        if (sign === '-') nowDiff.setMonth(nowDiff.getMonth() - number);
        break;
      case 'YEAR':
      case 'YEARS':
        if (sign === '+') nowDiff.setFullYear(nowDiff.getFullYear() + number);
        if (sign === '-') nowDiff.setFullYear(nowDiff.getFullYear() - number);
        break;
    }
    return nowDiff;
  }
  return new Date(value);
};

export default parseNowDate;
