import { formatTimeCodeText } from './time';
import { sortTimespanList, parseTimespan } from './parseMetadataType';

function timeToCueTime({ hours = 0, minutes = 0, seconds = 0, partialSeconds = 0 }) {
  const hourStr = hours.toFixed().padStart(2, '0');
  const minStr = minutes.toFixed().padStart(2, '0');
  const secondsStr = seconds.toFixed().padStart(2, '0');
  const partialStr = (partialSeconds * 10).toFixed().padStart(3, '0');
  return `${hourStr}:${minStr}:${secondsStr}.${partialStr}`;
}

export default function metadataTypeToWebVtt({
  metadataType,
  subtitleGroup = 'stl_subtitle',
  subtitleField = 'stl_text',
}) {
  const parseOptions = {
    includeTimespanAttributes: true,
    flatTimespan: true,
    flatGroup: true,
    joinValue: ',',
  };

  const { timespan: timespanList = [] } = metadataType || {};
  if (timespanList.length === 0) return '';
  const sortedTimespans = timespanList.sort(sortTimespanList);
  const parsedTimespans = sortedTimespans.map((timespanType) =>
    parseTimespan(timespanType, parseOptions),
  );
  const vttCues = parsedTimespans.map((thisTimespan) => {
    const {
      start: startText,
      end: endText,
      [subtitleGroup]: { [subtitleField]: text } = {},
    } = thisTimespan;
    if (!text) return '';
    const startTime = formatTimeCodeText(startText).toTime();
    const start = timeToCueTime(startTime);
    const endTime = formatTimeCodeText(endText).toTime();
    const end = timeToCueTime(endTime);
    return `${start} --> ${end} line:0%\r${text}\r`;
  });
  return `WEBVTT\n\n\n${vttCues.join('\n')}`;
}
