const parseHighlightTimespan = ({ field = [], start, end } = {}, opts = {}) => {
  const { arrayOnSingle = true, joinValue = undefined, timespanAsList = undefined } = opts;
  const initialValue = timespanAsList ? { start, end } : {};
  const fieldList = field.reduce((a, { name, value: valueList = [] }) => {
    if (valueList.length === 0) return a;
    let fieldValue;
    if (arrayOnSingle === false && valueList.length === 1) [fieldValue] = valueList;
    else if (joinValue) fieldValue = valueList.join(joinValue);
    else fieldValue = valueList;
    return { ...a, [name]: fieldValue };
  }, initialValue);
  return fieldList;
};

/**
 * Parses Highlight Timespans into key/value object.
 * The attributes can be targeted for each sub-type.
 * @param {Object} highlightTimespanList - A list of timespans.
 * @param {Object} options - Options which change how the timespans are parsed.
 * @param {Object} options.arrayOnSingle - Return an array if there is a single value.
 * @param {Object} options.joinValue - String to join the values, eg ','.
 * @param {Object} options.flat - Flatten to field-name/field-value (Note: field-values may be overwritten).
 * @param {Object} options.flatTimespan - Flatten timespan to object with start/end as key.
 * @param {Object} options.timespanAsList - Return timespans as list.
 * @param {Object} options.joinTimespan - String character to join the start/end timecodes.
 */
const parseHighlightTimespanList = (highlightTimespanList = [], opts = {}) => {
  const { flat, timespanAsList, joinTimespan = '_', flatTimespan } = opts;
  if (timespanAsList === true)
    return highlightTimespanList.map((timespan) => parseHighlightTimespan(timespan, opts));
  if (flat === true || flatTimespan === true)
    return highlightTimespanList.reduce(
      (a, timespan) => ({ ...a, ...parseHighlightTimespan(timespan, opts) }),
      {},
    );
  return highlightTimespanList.reduce(
    (a, timespan) => ({
      ...a,
      [[timespan.start, timespan.end].join(joinTimespan)]: parseHighlightTimespan(timespan, opts),
    }),
    {},
  );
};

export { parseHighlightTimespan, parseHighlightTimespanList };
