import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `GET /API/storage/`
 *
 * @function storage.listStorage
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listStorage({ ...props } = {}) {
  const path = '/API/storage/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `POST /API/storage/`
 *
 * @function storage.createStorage
 * @param {Object} obj
 * @param {Object} obj.storageDocument
 * @returns {Promise<Object>}
 */
function createStorage({ storageDocument, ...props }) {
  const path = '/API/storage/';
  return vFetch({
    path,
    method: 'POST',
    body: storageDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `GET /API/storage/${storageId}/`
 *
 * @function storage.getStorage
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function getStorage({ storageId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/`
 *
 * @function storage.modifyStorage
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {Object} obj.storageDocument
 * @returns {Promise<Object>}
 */
function modifyStorage({ storageId = isRequired(), storageDocument, ...props }) {
  const path = `/API/storage/${storageId}/`;
  return vFetch({
    path,
    method: 'PUT',
    body: storageDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/metadata`
 *
 * @function storage.modifyStorageMetadata
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function modifyStorageMetadata({ storageId = isRequired(), metadataDocument, ...props }) {
  const path = `/API/storage/${storageId}/metadata`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/metadata/${key}`
 *
 * @function storage.modifyStorageMetadataKey
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {string} obj.key
 * @param {string} obj.metadataValue
 * @returns {Promise<Object>}
 */
function modifyStorageMetadataKey({
  storageId = isRequired(),
  key = isRequired(),
  metadataValue,
  headers: propHeaders = {},
  ...props
}) {
  const headers = { contentType: 'text/plain', ...propHeaders };
  const path = `/API/storage/${storageId}/metadata/${key}`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataValue,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `DELETE /API/storage/${storageId}/metadata/${key}`
 *
 * @function storage.removeStorageMetadata
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {string} obj.key
 * @returns {Promise<Object>}
 */
function removeStorageMetadata({ storageId = isRequired(), key = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/metadata/${key}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `POST /API/storage/${storageId}/rescan`
 *
 * @function storage.rescanStorage
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function rescanStorage({ storageId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/rescan`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/method/`
 *
 * @function storage.createStorageMethod
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function createStorageMethod({ storageId = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/storage/${storageId}/method/`;
  return vFetch({
    path,
    method: 'PUT',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/method/${storageMethodId}/`
 *
 * @function storage.modifyStorageMethod
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {string} obj.storageMethodId
 * @returns {Promise<Object>}
 */
function modifyStorageMethod({
  storageId = isRequired(),
  storageMethodId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/storage/${storageId}/method/${storageMethodId}/`;
  return vFetch({
    path,
    method: 'PUT',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `DELETE /API/storage/${storageId}/method/${storageMethodId}`
 *
 * @function storage.removeStorageMethod
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {string} obj.storageMethodId
 * @returns {Promise<Object>}
 */
function removeStorageMethod({
  storageId = isRequired(),
  storageMethodId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/storage/${storageId}/method/${storageMethodId}`;
  return vFetch({
    path,
    method: 'DELETE',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `GET /API/storage/${storageId}/method/${storageMethodId}`
 *
 * @function storage.getStorageMethod
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {string} obj.storageMethodId
 * @returns {Promise<Object>}
 */
function getStorageMethod({ storageId = isRequired(), storageMethodId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/method/${storageMethodId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `GET /API/storage/${storageId}/method/`
 *
 * @function storage.listStorageMethod
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function listStorageMethod({ storageId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/method/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `DELETE /API/storage/${storageId}/`
 *
 * @function storage.removeStorage
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function removeStorage({ storageId = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/storage/${storageId}/`;
  return vFetch({
    path,
    method: 'DELETE',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/evacuate`
 *
 * @function storage.startEvacuateStorage
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function startEvacuateStorage({ storageId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/evacuate`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `DELETE /API/storage/${storageId}/evacuate`
 *
 * @function storage.cancelEvacuateStorage
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function cancelEvacuateStorage({ storageId = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/evacuate`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage.html APIdoc}
 *
 * `PUT /API/storage/${storageId}/type/${storageType}`
 *
 * @function storage.updateStorageType
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {string} obj.storageType
 * @returns {Promise<Object>}
 */
function updateStorageType({ storageId = isRequired(), storageType = isRequired(), ...props }) {
  const path = `/API/storage/${storageId}/type/${storageType}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

export default {
  listStorage,
  createStorage,
  getStorage,
  modifyStorage,
  modifyStorageMetadata,
  modifyStorageMetadataKey,
  removeStorageMetadata,
  rescanStorage,
  createStorageMethod,
  modifyStorageMethod,
  removeStorageMethod,
  getStorageMethod,
  listStorageMethod,
  removeStorage,
  startEvacuateStorage,
  cancelEvacuateStorage,
  updateStorageType,
};
