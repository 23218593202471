import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/projection.html APIdoc}
 *
 * `GET /API/projection/`
 *
 * @function projection.listProjection
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listProjection({ ...props } = {}) {
  const path = '/API/projection/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/projection.html APIdoc}
 *
 * `GET /API/projection/${projectionId}/outgoing`
 *
 * @function projection.getProjectionOutgoing
 * @param {Object} obj
 * @param {string} obj.projectionId
 * @returns {Promise<Object>}
 */
function getProjectionOutgoing({
  projectionId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/projection/${projectionId}/outgoing`;
  const headers = { accept: 'application/xml', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/projection.html APIdoc}
 *
 * `GET /API/projection/${projectionId}/incoming`
 *
 * @function projection.getProjectionIncoming
 * @param {Object} obj
 * @param {string} obj.projectionId
 * @returns {Promise<Object>}
 */
function getProjectionIncoming({
  projectionId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/projection/${projectionId}/incoming`;
  const headers = { accept: 'application/xml', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/projection.html APIdoc}
 *
 * `PUT /API/projection/${projectionId}/outgoing`
 *
 * @function projection.updateProjectionOutgoing
 * @param {Object} obj
 * @param {string} obj.projectionId
 * @param {Object} obj.projectionDocument
 * @returns {Promise<Object>}
 */
function updateProjectionOutgoing({
  projectionId = isRequired(),
  projectionDocument,
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/projection/${projectionId}/outgoing`;
  const headers = { contentType: 'application/xml', accept: 'application/xml', ...propHeaders };
  return vFetch({
    path,
    method: 'PUT',
    body: projectionDocument,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/projection.html APIdoc}
 *
 * `PUT /API/projection/${projectionId}/incoming`
 *
 * @function projection.updateProjectionIncoming
 * @param {Object} obj
 * @param {string} obj.projectionId
 * @param {Object} obj.projectionDocument
 * @returns {Promise<Object>}
 */
function updateProjectionIncoming({
  projectionId = isRequired(),
  projectionDocument,
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/projection/${projectionId}/incoming`;
  const headers = { contentType: 'application/xml', accept: 'application/xml', ...propHeaders };
  return vFetch({
    path,
    method: 'PUT',
    body: projectionDocument,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/projection.html APIdoc}
 *
 * `DELETE /API/projection/${projectionId}`
 *
 * @function projection.removeProjection
 * @param {Object} obj
 * @param {string} obj.projectionId
 * @returns {Promise<Object>}
 */
function removeProjection({ projectionId = isRequired(), ...props }) {
  const path = `/API/projection/${projectionId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listProjection,
  getProjectionOutgoing,
  getProjectionIncoming,
  updateProjectionOutgoing,
  updateProjectionIncoming,
  removeProjection,
};
