import { vFetch, isRequired } from './utils';
import metadataApi from './metadata';

const { getSimpleMetadata: getEntitySimpleMetadata } = metadataApi;

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `GET /API/metadata-field/field-group/`
 *
 * @function fieldgroup.listFieldGroup
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listFieldGroup({ ...props } = {}) {
  const path = '/API/metadata-field/field-group/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `GET /API/metadata-field/field-group/${groupName}/`
 *
 * @function fieldgroup.getFieldGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getFieldGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/metadata-field/field-group/${groupName}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `PUT /API/metadata-field/field-group/${groupName}`
 *
 * @function fieldgroup.updateFieldGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {Object} obj.metadataFieldGroupDocument
 * @returns {Promise<Object>}
 */
function updateFieldGroup({ groupName = isRequired(), metadataFieldGroupDocument, ...props }) {
  const path = `/API/metadata-field/field-group/${groupName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataFieldGroupDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `DELETE /API/metadata-field/field-group/${groupName}`
 *
 * @function fieldgroup.removeFieldGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function removeFieldGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/metadata-field/field-group/${groupName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `PUT /API/metadata-field/field-group/${groupName}/${fieldName}`
 *
 * @function fieldgroup.updateFieldGroupField
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.fieldName
 * @returns {Promise<Object>}
 */
function updateFieldGroupField({ groupName = isRequired(), fieldName = isRequired(), ...props }) {
  const path = `/API/metadata-field/field-group/${groupName}/${fieldName}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `DELETE /API/metadata-field/field-group/${groupName}/${fieldName}`
 *
 * @function fieldgroup.removeFieldGroupField
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.fieldName
 * @returns {Promise<Object>}
 */
function removeFieldGroupField({ groupName = isRequired(), fieldName = isRequired(), ...props }) {
  const path = `/API/metadata-field/field-group/${groupName}/${fieldName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `PUT /API/metadata-field/field-group/${groupName}/group/${childGroupName}`
 *
 * @function fieldgroup.updateFieldGroupChild
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.childGroupName
 * @returns {Promise<Object>}
 */
function updateFieldGroupChild({
  groupName = isRequired(),
  childGroupName = isRequired(),
  ...props
}) {
  const path = `/API/metadata-field/field-group/${groupName}/group/${childGroupName}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `DELETE /API/metadata-field/field-group/${groupName}/group/${childGroupName}`
 *
 * @function fieldgroup.removeFieldGroupChild
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.childGroupName
 * @returns {Promise<Object>}
 */
function removeFieldGroupChild({
  groupName = isRequired(),
  childGroupName = isRequired(),
  ...props
}) {
  const path = `/API/metadata-field/field-group/${groupName}/group/${childGroupName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `PUT /API/metadata-field/field-group/`
 *
 * @function fieldgroup.searchFieldGroup
 * @param {Object} obj
 * @param {Object} obj.metadataFieldGroupSearchDocument
 * @returns {Promise<Object>}
 */
function searchFieldGroup({ metadataFieldGroupSearchDocument, ...props }) {
  const path = '/API/metadata-field/field-group/';
  return vFetch({
    path,
    method: 'PUT',
    body: metadataFieldGroupSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `GET /API/metadata-field/field-group/metadata`
 *
 * @function fieldgroup.getSimpleMetadata
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getSimpleMetadata({ groupName = isRequired(), ...props }) {
  const entityType = 'metadata-field/field-group';
  return getEntitySimpleMetadata({
    entityType,
    entityId: groupName,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `GET /API/metadata-field/field-group/${groupName}/access`
 *
 * @function fieldgroup.listFieldGroupAccess
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function listFieldGroupAccess({ groupName = isRequired(), ...props }) {
  const path = `/API/metadata-field/field-group/${groupName}/access`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `POST /API/metadata-field/field-group/${groupName}/access`
 *
 * @function fieldgroup.createFieldGroupAccess
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {Object} obj.metadataFieldAccessControlDocument
 * @returns {Promise<Object>}
 */
function createFieldGroupAccess({
  groupName = isRequired(),
  metadataFieldAccessControlDocument = isRequired(),
  ...props
}) {
  const path = `/API/metadata-field/field-group/${groupName}/access`;
  return vFetch({
    path,
    method: 'POST',
    body: metadataFieldAccessControlDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `DELETE /API/metadata-field/field-group/${groupName}/access/${accessId}`
 *
 * @function fieldgroup.removeFieldGroupAccess
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.accessId
 * @returns {Promise<Object>}
 */
function removeFieldGroupAccess({ groupName = isRequired(), accessId = isRequired(), ...props }) {
  const path = `/API/metadata-field/field-group/${groupName}/access/${accessId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `GET /API/metadata-field/field-group/merged-access`
 *
 * @function fieldgroup.listFieldGroupMergedAccess
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listFieldGroupMergedAccess({ ...props } = {}) {
  const path = '/API/metadata-field/field-group/merged-access';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field-group.html APIdoc}
 *
 * `GET /API/metadata-field/field-group/${groupName}/merged-access`
 *
 * @function fieldgroup.getFieldGroupMergedAccess
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getFieldGroupMergedAccess({ groupName = isRequired(), ...props } = {}) {
  const path = `/API/metadata-field/field-group/${groupName}/merged-access`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  listFieldGroup,
  getFieldGroup,
  updateFieldGroup,
  removeFieldGroup,
  updateFieldGroupField,
  removeFieldGroupField,
  updateFieldGroupChild,
  removeFieldGroupChild,
  searchFieldGroup,
  getSimpleMetadata,
  listFieldGroupAccess,
  createFieldGroupAccess,
  removeFieldGroupAccess,
  listFieldGroupMergedAccess,
  getFieldGroupMergedAccess,
};
