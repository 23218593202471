import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/global.html APIdoc}
 *
 * `GET /API/metadata`
 *
 * @function globalmetadata.listGlobalMetadata
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listGlobalMetadata({ ...props } = {}) {
  const path = '/API/metadata';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/global.html APIdoc}
 *
 * `PUT /API/metadata`
 *
 * @function globalmetadata.updateGlobalMetadata
 * @param {Object} obj
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function updateGlobalMetadata({ metadataDocument = isRequired(), ...props }) {
  const path = '/API/metadata';
  return vFetch({
    path,
    method: 'PUT',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/global.html APIdoc}
 *
 * `GET /API/metadata/${uuid}`
 *
 * @function globalmetadata.getGlobalMetadata
 * @param {Object} obj
 * @param {string} obj.uuid
 * @returns {Promise<Object>}
 */
function getGlobalMetadata({ uuid = isRequired(), ...props }) {
  const path = `/API/metadata/${uuid}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/global.html APIdoc}
 *
 * `DELETE /API/metadata/${uuid}`
 *
 * @function globalmetadata.removeGlobalMetadata
 * @param {Object} obj
 * @param {string} obj.uuid
 * @returns {Promise<Object>}
 */
function removeGlobalMetadata({ uuid = isRequired(), ...props } = {}) {
  const path = `/API/metadata/${uuid}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listGlobalMetadata,
  updateGlobalMetadata,
  getGlobalMetadata,
  removeGlobalMetadata,
};
