import { vFetch, isRequired } from './utils';

/**
 * {@link  APIdoc}
 *
 * `GET /API/${entity}/${entityId}/metadata-lock`
 *
 * @function metadatalock.listEntityMetadataLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function listEntityMetadataLock({ entity = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entity}/${entityId}/metadata-lock`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link  APIdoc}
 *
 * `POST /API/${entity}/${entityId}/metadata-lock/`
 *
 * @function metadatalock.createEntityMetadataLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function createEntityMetadataLock({ entity = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entity}/${entityId}/metadata-lock`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link  APIdoc}
 *
 * `GET /API/${entity}/${entityId}/metadata-lock/${lockId}`
 *
 * @function metadatalock.getEntityMetadataLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function getEntityMetadataLock({
  entity = isRequired(),
  entityId = isRequired(),
  lockId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata-lock/${lockId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link  APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/metadata-lock/${lockId}`
 *
 * @function metadatalock.updateEntityMetadataLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function updateEntityMetadataLock({
  entity = isRequired(),
  entityId = isRequired(),
  lockId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata-lock/${lockId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link  APIdoc}
 *
 * `DELETE /API/${entity}/${entityId}/metadata-lock/${lockId}`
 *
 * @function metadatalock.removeEntityMetadataLock
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function removeEntityMetadataLock({
  entity = isRequired(),
  entityId = isRequired(),
  lockId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata-lock/${lockId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listEntityMetadataLock,
  createEntityMetadataLock,
  getEntityMetadataLock,
  updateEntityMetadataLock,
  removeEntityMetadataLock,
};
