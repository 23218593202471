import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/license.html APIdoc}
 *
 * `POST /UploadLicense/getlic`
 *
 * @function uploadlicense.createLicense
 * @param {Object} obj
 * @param {string} obj.username
 * @param {string} obj.password
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createLicense({
  username = isRequired(),
  password = isRequired(),
  file = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/html', ...propHeaders };
  headers.delete('content-type');
  const path = '/UploadLicense/getlic';
  const formData = new FormData();
  formData.append('user', username);
  formData.append('pass', password);
  formData.append('file', file);
  return vFetch({
    path,
    body: formData,
    method: 'POST',
    headers,
    ...props,
  });
}

export default { createLicense };
