import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/shape-tag.html APIdoc}
 *
 * `GET /API/shape-tag/`
 *
 * @function shapetag.listShapeTag
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listShapeTag({ ...props } = {}) {
  const path = '/API/shape-tag/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/shape-tag.html APIdoc}
 *
 * `GET /API/shape-tag/${tagName}`
 *
 * @function shapetag.getShapeTag
 * @param {Object} obj
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function getShapeTag({ tagName = isRequired(), ...props }) {
  const path = `/API/shape-tag/${tagName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/shape-tag.html APIdoc}
 *
 * `PUT /API/shape-tag/${tagName}`
 *
 * @function shapetag.updateShapeTag
 * @param {Object} obj
 * @param {string} obj.tagName
 * @param {Object} obj.transcodePresetDocument
 * @returns {Promise<Object>}
 */
function updateShapeTag({ tagName = isRequired(), transcodePresetDocument, ...props }) {
  const path = `/API/shape-tag/${tagName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: transcodePresetDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/shape-tag.html APIdoc}
 *
 * `DELETE /API/shape-tag/${tagName}`
 *
 * @function shapetag.removeShapeTag
 * @param {Object} obj
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function removeShapeTag({ tagName = isRequired(), ...props }) {
  const path = `/API/shape-tag/${tagName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/shape-tag.html APIdoc}
 *
 * `GET /API/shape-tag/${tagName}/script`
 *
 * @function shapetag.getShapeTagScript
 * @param {Object} obj
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function getShapeTagScript({ tagName = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'application/javascript', ...propHeaders };
  const path = `/API/shape-tag/${tagName}/script`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/shape-tag.html APIdoc}
 *
 * `PUT /API/shape-tag/${tagName}/script`
 *
 * @function shapetag.updateShapeTagScript
 * @param {Object} obj
 * @param {string} obj.tagName
 * @param {Object} obj.shapeTagScript
 * @returns {Promise<Object>}
 */
function updateShapeTagScript({
  tagName = isRequired(),
  shapeTagScript,
  headers: propHeaders = {},
  ...props
}) {
  const headers = { contentType: 'application/javascript', ...propHeaders };
  const path = `/API/shape-tag/${tagName}/script`;
  return vFetch({
    path,
    method: 'PUT',
    body: shapeTagScript,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/shape-tag.html APIdoc}
 *
 * `DELETE /API/shape-tag/${tagName}/script`
 *
 * @function shapetag.removeShapeTagScript
 * @param {Object} obj
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function removeShapeTagScript({ tagName = isRequired(), ...props }) {
  const path = `/API/shape-tag/${tagName}/script`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/shape-tag.html APIdoc}
 *
 * `GET /API/shape-tag/${tagName}/item/${itemId}/shape/${shapeId}/`
 *
 * @function shapetag.testShapeTagScript
 * @param {Object} obj
 * @param {string} obj.tagName
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function testShapeTagScript({
  tagName = isRequired(),
  itemId = isRequired(),
  shapeId = isRequired(),
  ...props
}) {
  const path = `/API/shape-tag/${tagName}/item/${itemId}/shape/${shapeId}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/shape-tag.html APIdoc}
 *
 * `PUT /APIinit/preset-templates`
 *
 * @function shapetag.createShapeTagPreset
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function createShapeTagPreset({ ...props } = {}) {
  const path = '/APIinit/preset-templates';
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

export default {
  listShapeTag,
  getShapeTag,
  updateShapeTag,
  removeShapeTag,
  getShapeTagScript,
  updateShapeTagScript,
  removeShapeTagScript,
  testShapeTagScript,
  createShapeTagPreset,
};
