import React from 'react';
import { useRoles } from '../context/RolesContext';

export default function HasRole({ roleName, allow, deny, loading }) {
  if (roleName === undefined) throw new Error('roleName is undefined');
  const { hasRole } = useRoles();
  const checkedRole = hasRole(roleName);
  if (React.isValidElement(allow) && checkedRole === true) {
    return allow;
  }
  if (React.isValidElement(deny) && checkedRole === false) {
    return deny;
  }
  if (React.isValidElement(loading) && checkedRole === null) {
    return loading;
  }
  return null;
}
