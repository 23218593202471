import { vFetch, isRequired } from './utils';

/**
 * `GET /APInoauth/is-online/`
 *
 * @function noauth.isOnline
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function isOnline({ ...props } = {}) {
  const path = '/APInoauth/is-online/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/self-test.html#get--APInoauth-selftest APIdoc}
 *
 * `GET /APInoauth/selftest/`
 *
 * @function noauth.getSelfTest
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getSelfTest({ ...props } = {}) {
  const path = '/APInoauth/selftest/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * `POST /APIinit/`
 *
 * @function noauth.createInit
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function createInit({ headers: propHeaders = {}, ...props } = {}) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = '/APIinit/';
  return vFetch({
    path,
    method: 'POST',
    headers,
    ...props,
  });
}

/**
 *
 * @function noauth.getFileRaw
 * @param {Object} obj
 * @param {string} obj.path
 * @returns {Promise<Object>}
 */
function getFileRaw({ path = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'application/octet-stream', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    responseType: 'blob',
    ...props,
  });
}

export default {
  getSelfTest,
  createInit,
  getFileRaw,
  isOnline,
};
