import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vidispine-service.html APIdoc}
 *
 * `GET /API/vidispine-service`
 *
 * @function service.getServiceList
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getServiceList({ ...props } = {}) {
  const path = '/API/vidispine-service';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vidispine-service.html APIdoc}
 *
 * `GET /API/vidispine-service/service/${serviceName}`
 *
 * @function service.getService
 * @param {Object} obj
 * @param {string} obj.serviceName
 * @returns {Promise<Object>}
 */
function getService({ serviceName = isRequired(), ...props }) {
  const path = `/API/vidispine-service/service/${serviceName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vidispine-service.html APIdoc}
 *
 * `PUT /API/vidispine-service/service/${serviceName}/enable`
 *
 * @function service.enableService
 * @param {Object} obj
 * @param {string} obj.serviceName
 * @returns {Promise<Object>}
 */
function enableService({ serviceName = isRequired(), ...props }) {
  const path = `/API/vidispine-service/service/${serviceName}/enable`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vidispine-service.html APIdoc}
 *
 * `PUT /API/vidispine-service/service/${serviceName}/disable`
 *
 * @function service.disableService
 * @param {Object} obj
 * @param {string} obj.serviceName
 * @returns {Promise<Object>}
 */
function disableService({ serviceName = isRequired(), ...props }) {
  const path = `/API/vidispine-service/service/${serviceName}/disable`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vidispine-service.html APIdoc}
 *
 * `GET /API/vidispine-service/stacktrace`
 *
 * @function service.getStackTrace
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getStackTrace({ headers: propHeaders = {}, ...props } = {}) {
  const path = '/API/vidispine-service/stacktrace';
  const headers = { accept: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

export default {
  getServiceList,
  getService,
  enableService,
  disableService,
  getStackTrace,
};
