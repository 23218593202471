import { vFetch, isRequired } from './utils';
import fileApi from './file';

const { createFileEntity, createFileTemporaryCredentials, updateFileState, abandonFile } = fileApi;

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/`
 *
 * @function vsimport.createImportUri
 * @param {Object} obj
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function createImportUri({ metadataDocument = {}, ...props } = {}) {
  const path = '/API/import/';
  return vFetch({
    path,
    method: 'POST',
    body: metadataDocument,
    ...props,
  });
}
/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html#find-destination-storage APIdoc}
 *
 * `GET /API/import/storage`
 *
 * @function vsimport.getImportStorage
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getImportStorage({ ...props } = {}) {
  const path = '/API/import/storage';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/raw/`
 *
 * @function vsimport.createImportRaw
 * @param {Object} obj
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createImportRaw({ file = isRequired(), headers: propHeaders = {}, ...props }) {
  const path = '/API/import/raw/';
  return vFetch({
    path,
    method: 'POST',
    body: file,
    headers: { contentType: 'application/octet-stream', ...propHeaders },
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /APInoauth/import/raw/`
 *
 * @function vsimport.createImportRawNoAuth
 * @param {Object} obj
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createImportRawNoAuth({ file = isRequired(), headers: propHeaders = {}, ...props }) {
  const path = '/APInoauth/import/raw/';
  return vFetch({
    path,
    method: 'POST',
    body: file,
    headers: { contentType: 'application/octet-stream', ...propHeaders },
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/raw-passkey/`
 *
 * @function vsimport.createImportRawPasskey
 * @param {Object} obj
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function createImportRawPasskey({ metadataDocument = {}, ...props } = {}) {
  const path = '/API/import/raw-passkey/';
  return vFetch({
    path,
    method: 'POST',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/imp/`
 *
 * @function vsimport.createImportImp
 * @param {Object} obj
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function createImportImp({ metadataDocument = {}, ...props } = {}) {
  const path = '/API/import/imp/';
  return vFetch({
    path,
    method: 'POST',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/placeholder/`
 *
 * @function vsimport.createImportPlaceholder
 * @param {Object} obj
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function createImportPlaceholder({ metadataDocument = {}, ...props } = {}) {
  const path = '/API/import/placeholder/';
  return vFetch({
    path,
    method: 'POST',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/placeholder/${itemId}/${component}/`
 *
 * @function vsimport.createImportComponent
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.component
 * @returns {Promise<Object>}
 */
function createImportComponent({ itemId = isRequired(), component = isRequired(), ...props }) {
  const path = `/API/import/placeholder/${itemId}/${component}/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/placeholder/${itemId}/${component}/raw/`
 *
 * @function vsimport.createImportComponentRaw
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.component
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createImportComponentRaw({
  itemId = isRequired(),
  component = isRequired(),
  file = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/import/placeholder/${itemId}/${component}/raw/`;
  return vFetch({
    path,
    method: 'POST',
    body: file,
    headers: { contentType: 'application/octet-stream', ...propHeaders },
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /APInoauth/import/placeholder/${itemId}/${component}/raw/`
 *
 * @function vsimport.createImportComponentRawNoAuth
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.component
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createImportComponentRawNoAuth({
  itemId = isRequired(),
  component = isRequired(),
  file = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/APInoauth/import/placeholder/${itemId}/${component}/raw/`;
  return vFetch({
    path,
    method: 'POST',
    body: file,
    headers: { contentType: 'application/octet-stream', ...propHeaders },
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/placeholder/${itemId}/`
 *
 * @function vsimport.createImportItem
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.placeholderImportRequestDocument
 * @returns {Promise<Object>}
 */
function createImportItem({ itemId = isRequired(), placeholderImportRequestDocument, ...props }) {
  const path = `/API/import/placeholder/${itemId}/`;
  return vFetch({
    path,
    method: 'POST',
    body: placeholderImportRequestDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/placeholder/${itemId}/raw-passkey/`
 *
 * @function vsimport.createImportItemPasskey
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createImportItemPasskey({ itemId = isRequired(), ...props }) {
  const path = `/API/import/placeholder/${itemId}/raw-passkey/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/placeholder/${itemId}/${component}/adopt/${fileId}/`
 *
 * @function vsimport.createImportItemAdoptFile
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.component
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function createImportItemAdoptFile({
  itemId = isRequired(),
  component = isRequired(),
  fileId = isRequired(),
  ...props
}) {
  const path = `/API/import/placeholder/${itemId}/${component}/adopt/${fileId}/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/sidecar/${itemId}/`
 *
 * @function vsimport.createImportItemSidecar
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createImportItemSidecar({ itemId = isRequired(), ...props }) {
  const path = `/API/import/sidecar/${itemId}/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import.html APIdoc}
 *
 * `POST /API/import/sidecar/${itemId}/raw/`
 *
 * @function vsimport.createImportItemSidecarRaw
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createImportItemSidecarRaw({
  itemId = isRequired(),
  file,
  headers: propHeaders = {},
  ...props
}) {
  const headers = { contentType: 'application/octet-stream', ...propHeaders };
  const path = `/API/import/sidecar/${itemId}/raw/`;
  return vFetch({
    path,
    method: 'POST',
    body: file,
    headers,
    ...props,
  });
}

function createImportS3Direct({ file = isRequired(), storageId = isRequired() }) {
  return new Promise((resolve, reject) => {
    let fileId;
    let fileDocument;

    createFileEntity({
      storageId,
      queryParams: {
        path: file.name,
      },
    })
      .then((response) => {
        fileDocument = response.data; // will return this on resolve
        fileId = response.data.id;

        return createFileTemporaryCredentials({
          fileId,
          queryParams: {
            write: true,
            scheme: 'https',
            duration: 720,
          },
        });
      })
      .then((response) => {
        const s3Url = response.data.uri[0];

        /*
        Upload to s3
        TODO - replace with vFetch once vFetch implements its own axios instance
        to avoid attaching auth and other vidispine headers
        */

        return fetch(s3Url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          body: file,
        });
      })
      .then(() => {
        // set file state to closed
        updateFileState({ fileId, state: 'CLOSED' });
      })
      .then(() => {
        // success, resolve
        resolve(fileDocument);
      })
      .catch((err) => {
        // do cleanup to avoid errors when same file is tried again
        if (fileId) {
          abandonFile({ fileId });
        } // else filename already exists (?)

        reject(err.response);
      });
  });
}

export default {
  createImportUri,
  getImportStorage,
  createImportRaw,
  createImportRawNoAuth,
  createImportRawPasskey,
  createImportImp,
  createImportPlaceholder,
  createImportComponent,
  createImportComponentRaw,
  createImportComponentRawNoAuth,
  createImportItem,
  createImportItemPasskey,
  createImportItemAdoptFile,
  createImportItemSidecar,
  createImportItemSidecarRaw,
  createImportS3Direct,
};
