import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `GET /API/jobtype`
 *
 * @function taskdefinition.listJobType
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listJobType({ ...props } = {}) {
  const path = '/API/jobtype';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `POST /API/task-definition/jobtype/${jobType}/`
 *
 * @function taskdefinition.createJobType
 * @param {Object} obj
 * @param {string} obj.jobType
 * @returns {Promise<Object>}
 */
function createJobType({ jobType = isRequired(), ...props }) {
  const path = `/API/task-definition/jobtype/${jobType}/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `DELETE /API/task-definition/jobtype/${jobType}/`
 *
 * @function taskdefinition.removeJobType
 * @param {Object} obj
 * @param {string} obj.jobType
 * @returns {Promise<Object>}
 */
function removeJobType({ jobType = isRequired(), ...props }) {
  const path = `/API/task-definition/jobtype/${jobType}/`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `GET /API/task-definition`
 *
 * @function taskdefinition.listTaskDefinition
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listTaskDefinition({ ...props } = {}) {
  const path = '/API/task-definition';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `GET /API/task-definition/jobtype/${taskDefinitionType}/`
 *
 * @function taskdefinition.getTaskDefinitionType
 * @param {Object} obj
 * @param {string} obj.taskDefinitionType
 * @returns {Promise<Object>}
 */
function getTaskDefinitionType({ taskDefinitionType = isRequired(), ...props }) {
  const path = `/API/task-definition/jobtype/${taskDefinitionType}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `POST /API/task-definition`
 *
 * @function taskdefinition.createTaskDefinition
 * @param {Object} obj
 * @param {Object} obj.taskDefinitionListDocument
 * @returns {Promise<Object>}
 */
function createTaskDefinition({ taskDefinitionListDocument, ...props }) {
  const path = '/API/task-definition';
  return vFetch({
    path,
    method: 'POST',
    body: taskDefinitionListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `GET /API/task-definition/${taskDefinitionId}`
 *
 * @function taskdefinition.getTaskDefinition
 * @param {Object} obj
 * @param {string} obj.taskDefinitionId
 * @returns {Promise<Object>}
 */
function getTaskDefinition({ taskDefinitionId = isRequired(), ...props }) {
  const path = `/API/task-definition/${taskDefinitionId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `GET /API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}`
 *
 * @function taskdefinition.getTaskDefinitionStep
 * @param {Object} obj
 * @param {string} obj.taskDefinitionType
 * @param {string} obj.taskDefinitionStep
 * @returns {Promise<Object>}
 */
function getTaskDefinitionStep({
  taskDefinitionType = isRequired(),
  taskDefinitionStep = isRequired(),
  ...props
}) {
  const path = `/API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `DELETE /API/task-definition/${taskDefinitionId}`
 *
 * @function taskdefinition.removeTaskDefinition
 * @param {Object} obj
 * @param {string} obj.taskDefinitionId
 * @returns {Promise<Object>}
 */
function removeTaskDefinition({ taskDefinitionId = isRequired(), ...props }) {
  const path = `/API/task-definition/${taskDefinitionId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `DELETE /API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}`
 *
 * @function taskdefinition.removeTaskDefinitionStep
 * @param {Object} obj
 * @param {string} obj.taskDefinitionType
 * @param {string} obj.taskDefinitionStep
 * @returns {Promise<Object>}
 */
function removeTaskDefinitionStep({
  taskDefinitionType = isRequired(),
  taskDefinitionStep = isRequired(),
  ...props
}) {
  const path = `/API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `PUT /API/task-definition/${taskDefinitionId}`
 *
 * @function taskdefinition.modifyTaskDefinition
 * @param {Object} obj
 * @param {string} obj.taskDefinitionId
 * @param {Object} obj.taskDefinitionDocument
 * @returns {Promise<Object>}
 */
function modifyTaskDefinition({
  taskDefinitionId = isRequired(),
  taskDefinitionDocument = isRequired(),
  ...props
}) {
  const path = `/API/task-definition/${taskDefinitionId}`;
  return vFetch({
    path,
    method: 'PUT',
    body: taskDefinitionDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `PUT /API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}`
 *
 * @function taskdefinition.modifyTaskDefinitionStep
 * @param {Object} obj
 * @param {string} obj.taskDefinitionType
 * @param {string} obj.taskDefinitionStep
 * @param {Object} obj.taskDefinitionDocument
 * @returns {Promise<Object>}
 */
function modifyTaskDefinitionStep({
  taskDefinitionType = isRequired(),
  taskDefinitionStep = isRequired(),
  taskDefinitionDocument,
  ...props
}) {
  const path = `/API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}`;
  return vFetch({
    path,
    method: 'PUT',
    body: taskDefinitionDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `GET /API/task-definition/${taskDefinitionId}/script`
 *
 * @function taskdefinition.getTaskDefinitionScript
 * @param {Object} obj
 * @param {string} obj.taskDefinitionId
 * @returns {Promise<Object>}
 */
function getTaskDefinitionScript({
  taskDefinitionId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'application/javascript', ...propHeaders };
  const path = `/API/task-definition/${taskDefinitionId}/script`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `GET /API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}/script`
 *
 * @function taskdefinition.getTaskDefinitionStepScript
 * @param {Object} obj
 * @param {string} obj.taskDefinitionType
 * @param {string} obj.taskDefinitionStep
 * @returns {Promise<Object>}
 */
function getTaskDefinitionStepScript({
  taskDefinitionType = isRequired(),
  taskDefinitionStep = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'application/javascript', ...propHeaders };
  const path = `/API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}/script`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `PUT /API/task-definition/${taskDefinitionId}/script`
 *
 * @function taskdefinition.updateTaskDefinitionScript
 * @param {Object} obj
 * @param {string} obj.taskDefinitionId
 * @param {Object} obj.scriptDocument
 * @returns {Promise<Object>}
 */
function updateTaskDefinitionScript({
  taskDefinitionId = isRequired(),
  scriptDocument,
  headers: propHeaders = {},
  ...props
}) {
  const headers = {
    contentType: 'application/javascript',
    accept: 'application/javascript',
    ...propHeaders,
  };
  const path = `/API/task-definition/${taskDefinitionId}/script`;
  return vFetch({
    path,
    method: 'PUT',
    body: scriptDocument,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-definition.html APIdoc}
 *
 * `PUT /API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}/script`
 *
 * @function taskdefinition.updateTaskDefinitionStepScript
 * @param {Object} obj
 * @param {string} obj.taskDefinitionType
 * @param {string} obj.taskDefinitionStep
 * @param {Object} obj.scriptDocument
 * @returns {Promise<Object>}
 */
function updateTaskDefinitionStepScript({
  taskDefinitionType = isRequired(),
  taskDefinitionStep = isRequired(),
  scriptDocument,
  headers: propHeaders = {},
  ...props
}) {
  const headers = {
    contentType: 'application/javascript',
    accept: 'application/javascript',
    ...propHeaders,
  };
  const path = `/API/task-definition/jobtype/${taskDefinitionType}/step/${taskDefinitionStep}/script`;
  return vFetch({
    path,
    method: 'PUT',
    body: scriptDocument,
    headers,
    ...props,
  });
}

export default {
  listJobType,
  createJobType,
  removeJobType,
  listTaskDefinition,
  getTaskDefinitionType,
  createTaskDefinition,
  getTaskDefinition,
  getTaskDefinitionStep,
  removeTaskDefinition,
  removeTaskDefinitionStep,
  modifyTaskDefinition,
  modifyTaskDefinitionStep,
  getTaskDefinitionScript,
  getTaskDefinitionStepScript,
  updateTaskDefinitionScript,
  updateTaskDefinitionStepScript,
};
