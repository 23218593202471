import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `GET /API/item/${itemId}/metadata/bulky`
 *
 * @function bulkymetadata.listItemBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listItemBulkyMetadata({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/metadata/bulky`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/metadata/bulky`
 *
 * @function bulkymetadata.listShapeBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function listShapeBulkyMetadata({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/metadata/bulky`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `PUT /API/item/${itemId}/metadata/bulky`
 *
 * @function bulkymetadata.updateAllItemBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.bulkyMetadataDocument
 * @returns {Promise<Object>}
 */
function updateAllItemBulkyMetadata({
  itemId = isRequired(),
  bulkyMetadataDocument = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/metadata/bulky`;
  return vFetch({
    path,
    method: 'PUT',
    body: bulkyMetadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `PUT /API/item/${itemId}/shape/${shapeId}/metadata/bulky`
 *
 * @function bulkymetadata.updateAllShapeBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {Object} obj.bulkyMetadataDocument
 * @returns {Promise<Object>}
 */
function updateAllShapeBulkyMetadata({
  itemId = isRequired(),
  shapeId = isRequired(),
  bulkyMetadataDocument = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/metadata/bulky`;
  return vFetch({
    path,
    method: 'PUT',
    body: bulkyMetadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/metadata/bulky`
 *
 * @function bulkymetadata.removeAllItemBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function removeAllItemBulkyMetadata({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/metadata/bulky`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/shape/${shapeId}/metadata/bulky`
 *
 * @function bulkymetadata.removeAllShapeBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function removeAllShapeBulkyMetadata({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/metadata/bulky`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `GET /API/item/${itemId}/metadata/bulky/${key}`
 *
 * @function bulkymetadata.getItemBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.key
 * @returns {Promise<Object>}
 */
function getItemBulkyMetadata({ itemId = isRequired(), key = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/metadata/bulky/${key}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/metadata/bulky/${key}`
 *
 * @function bulkymetadata.getShapeBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.key
 * @returns {Promise<Object>}
 */
function getShapeBulkyMetadata({
  itemId = isRequired(),
  shapeId = isRequired(),
  key = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/metadata/bulky/${key}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `PUT /API/item/${itemId}/metadata/bulky/${key}`
 *
 * @function bulkymetadata.updateItemBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.key
 * @param {string} obj.value
 * @param {Object} obj.headers
 * @returns {Promise<Object>}
 */
function updateItemBulkyMetadata({
  itemId = isRequired(),
  key = isRequired(),
  value = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/item/${itemId}/metadata/bulky/${key}`;
  const headers = { contentType: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'PUT',
    body: value,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `PUT /API/item/${itemId}/shape/${shapeId}/metadata/bulky/${key}`
 *
 * @function bulkymetadata.updateShapeBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.key
 * @param {string} obj.value
 * @returns {Promise<Object>}
 */
function updateShapeBulkyMetadata({
  itemId = isRequired(),
  shapeId = isRequired(),
  key = isRequired(),
  value = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/metadata/bulky/${key}`;
  const headers = { contentType: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'PUT',
    body: value,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/metadata/bulky/${key}`
 *
 * @function bulkymetadata.removeItemBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.key
 * @returns {Promise<Object>}
 */
function removeItemBulkyMetadata({ itemId = isRequired(), key = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/metadata/bulky/${key}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/bulky.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/shape/${shapeId}/metadata/bulky/${key}`
 *
 * @function bulkymetadata.removeShapeBulkyMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.key
 * @returns {Promise<Object>}
 */
function removeShapeBulkyMetadata({
  itemId = isRequired(),
  shapeId = isRequired(),
  key = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/metadata/bulky/${key}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listItemBulkyMetadata,
  listShapeBulkyMetadata,
  updateAllItemBulkyMetadata,
  updateAllShapeBulkyMetadata,
  removeAllItemBulkyMetadata,
  removeAllShapeBulkyMetadata,
  getItemBulkyMetadata,
  getShapeBulkyMetadata,
  updateItemBulkyMetadata,
  updateShapeBulkyMetadata,
  removeItemBulkyMetadata,
  removeShapeBulkyMetadata,
};
