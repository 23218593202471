import React from 'react';
import useGetCollection from '../hooks/useGetCollection';

export default function GetCollectionContent({
  collectionId,
  queryParams,
  refreshOnMount,
  children,
}) {
  const { collectionType, onRefresh, isLoading, isError } = useGetCollection({
    collectionId,
    queryParams,
    refreshOnMount,
  });
  const cloneProps = {
    collectionId,
    queryParams,
    collectionType,
    onRefresh,
    isLoading,
    isError,
  };
  const cloneChild = (child) => React.cloneElement(child, cloneProps);
  return React.Children.map(children, cloneChild);
}
