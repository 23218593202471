import { vFetch, isRequired } from './utils';

function getEntityTypeUrl(entityType) {
  if (entityType === 'file') {
    return 'storage/file';
  }
  if (['metadata', 'access'].includes(entityType)) {
    return 'item';
  }
  return entityType;
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `GET /API/${entityType}/notification/`
 *
 * @function notification.listNotification
 * @param {Object} obj
 * @param {string} obj.entityType
 * @returns {Promise<Object>}
 */
function listNotification({ entityType = isRequired(), ...props } = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/notification/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/notification/`
 *
 * @function notification.listNotificationEntity
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function listNotificationEntity({
  entityType = isRequired(),
  entityId = isRequired(),
  ...props
} = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/${entityId}/notification/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `GET /API/${entityType}/notification/${notificationId}`
 *
 * @function notification.getNotification
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.notificationId
 * @returns {Promise<Object>}
 */
function getNotification({
  notificationId = isRequired(),
  entityType = isRequired(),
  ...props
} = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/notification/${notificationId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `PUT /API/${entityType}/notification/${notificationId}`
 *
 * @function notification.updateNotification
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.notificationId
 * @param {Object} obj.notificationDocument
 * @returns {Promise<Object>}
 */
function updateNotification({
  notificationId = isRequired(),
  entityType = isRequired(),
  notificationDocument,
  ...props
} = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/notification/${notificationId}`;
  return vFetch({
    path,
    method: 'PUT',
    body: notificationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `POST /API/${entityType}/notification/`
 *
 * @function notification.createNotification
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {Object} obj.notificationDocument
 * @returns {Promise<Object>}
 */
function createNotification({ entityType = isRequired(), notificationDocument, ...props } = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/notification/`;
  return vFetch({
    path,
    method: 'POST',
    body: notificationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `DELETE /API/${entityType}/notification/${notificationId}`
 *
 * @function notification.removeNotification
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.notificationId
 * @returns {Promise<Object>}
 */
function removeNotification({
  notificationId = isRequired(),
  entityType = isRequired(),
  ...props
} = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/notification/${notificationId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/notification/${notificationId}`
 *
 * @function notification.getNotificationEntity
 * @param {Object} obj
 * @param {string} obj.entityId
 * @param {string} obj.entityType
 * @param {string} obj.notificationId
 * @returns {Promise<Object>}
 */
function getNotificationEntity({
  notificationId = isRequired(),
  entityType = isRequired(),
  entityId = isRequired(),
  ...props
} = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/${entityId}/notification/${notificationId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `PUT /API/${entityType}/${entityId}/notification/${notificationId}`
 *
 * @function notification.updateNotificationEntity
 * @param {Object} obj
 * @param {string} obj.entityId
 * @param {string} obj.entityType
 * @param {string} obj.notificationId
 * @param {Object} obj.notificationDocument
 * @returns {Promise<Object>}
 */
function updateNotificationEntity({
  notificationId = isRequired(),
  entityType = isRequired(),
  entityId = isRequired(),
  notificationDocument,
  ...props
} = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/${entityId}/notification/${notificationId}`;
  return vFetch({
    path,
    method: 'PUT',
    body: notificationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `POST /API/${entityType}/${entityId}/notification/`
 *
 * @function notification.createNotificationEntity
 * @param {Object} obj
 * @param {string} obj.entityId
 * @param {string} obj.entityType
 * @param {Object} obj.notificationDocument
 * @returns {Promise<Object>}
 */
function createNotificationEntity({
  entityType = isRequired(),
  notificationDocument,
  entityId,
  ...props
} = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/${entityId}/notification/`;
  return vFetch({
    path,
    method: 'POST',
    body: notificationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/notification/index.html APIdoc}
 *
 * `DELETE /API/${entityType}/${entityId}/notification/${notificationId}`
 *
 * @function notification.removeNotificationEntity
 * @param {Object} obj
 * @param {string} obj.entityId
 * @param {string} obj.entityType
 * @param {string} obj.notificationId
 * @returns {Promise<Object>}
 */
function removeNotificationEntity({
  notificationId = isRequired(),
  entityType = isRequired(),
  entityId = isRequired(),
  ...props
} = {}) {
  const entityTypeUrl = getEntityTypeUrl(entityType);
  const path = `/API/${entityTypeUrl}/${entityId}/notification/${notificationId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listNotification,
  listNotificationEntity,
  getNotification,
  updateNotification,
  createNotification,
  removeNotification,
  getNotificationEntity,
  updateNotificationEntity,
  createNotificationEntity,
  removeNotificationEntity,
};
