import { vFetch, isRequired } from './utils';

import metadataApi from './metadata';
import metadatalockApi from './metadatalock';

const {
  listEntityMetadataLock,
  createEntityMetadataLock,
  getEntityMetadataLock,
  updateEntityMetadataLock,
  removeEntityMetadataLock,
} = metadatalockApi;

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `GET /API/collection/`
 *
 * @function collection.listCollection
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listCollection({ ...props } = {}) {
  const path = '/API/collection/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `GET /API/collection/${collectionId}/`
 *
 * @function collection.getCollection
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function getCollection({ collectionId = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `GET /API/collection/${collectionId}/metadata`
 *
 * @function collection.getCollectionMetadata
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function getCollectionMetadata({ collectionId = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/metadata`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * @function collection.updateCollectionMetadata
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function updateCollectionMetadata({ collectionId, metadataDocument, ...props }) {
  return metadataApi.updateEntityMetadata({
    entity: 'collection',
    entityId: collectionId,
    metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `GET /API/collection/${collectionId}/ancestor`
 *
 * @function collection.getCollectionAncestor
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function getCollectionAncestor({ collectionId = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/ancestor`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `GET /API/collection/history`
 *
 * @function collection.listCollectionHistory
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listCollectionHistory({ ...props } = {}) {
  const path = '/API/collection/history';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `GET /API/collection/${collectionId}/item`
 *
 * @function collection.getCollectionItem
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function getCollectionItem({ collectionId = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/item`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `PUT /API/collection/`
 *
 * @function collection.searchCollection
 * @param {Object} obj
 * @param {Object} obj.itemSearchDocument
 * @returns {Promise<Object>}
 */
function searchCollection({ itemSearchDocument, ...props }) {
  const path = '/API/collection/';
  return vFetch({
    path,
    method: 'PUT',
    body: itemSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `PUT /API/collection/${collectionId}/item`
 *
 * @function collection.searchCollectionItem
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {Object} obj.searchDocument
 * @returns {Promise<Object>}
 */
function searchCollectionItem({ collectionId = isRequired(), searchDocument, ...props }) {
  const path = `/API/collection/${collectionId}/item`;
  return vFetch({
    path,
    method: 'PUT',
    body: searchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `POST /API/collection`
 *
 * @function collection.createCollection
 * @param {Object} obj
 * @param {Object} obj.collectionDocument
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function createCollection({ collectionDocument, queryParams, ...props }) {
  const path = '/API/collection';
  return vFetch({
    path,
    method: 'POST',
    body: collectionDocument,
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `DELETE /API/collection/${collectionId}/`
 *
 * @function collection.removeCollection
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function removeCollection({ collectionId = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `DELETE /API/collection`
 *
 * @function collection.removeCollectionList
 * @param {Object} obj
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function removeCollectionList({ queryParams = isRequired(), ...props }) {
  const path = '/API/collection';
  return vFetch({
    path,
    method: 'DELETE',
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `PUT /API/collection/${collectionId}/rename`
 *
 * @function collection.renameCollection
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function renameCollection({ collectionId = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/rename`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `PUT /API/collection/${collectionId}/${entityId}`
 *
 * @function collection.addCollectionEntity
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function addCollectionEntity({ collectionId = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/${entityId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `DELETE /API/collection/${collectionId}/${entityId}`
 *
 * @function collection.removeCollectionEntity
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function removeCollectionEntity({
  collectionId = isRequired(),
  entityId = isRequired(),
  ...props
}) {
  const path = `/API/collection/${collectionId}/${entityId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `PUT /API/collection/${collectionId}/`
 *
 * @function collection.updateCollection
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {Object} obj.collectionDocument
 * @returns {Promise<Object>}
 */
function updateCollection({ collectionId = isRequired(), collectionDocument, ...props }) {
  const path = `/API/collection/${collectionId}/`;
  return vFetch({
    path,
    method: 'PUT',
    body: collectionDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `POST /API/collection/${collectionId}/order`
 *
 * @function collection.orderCollection
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {Object} obj.collectionReorderDocument
 * @returns {Promise<Object>}
 */
function orderCollection({ collectionId = isRequired(), collectionReorderDocument, ...props }) {
  const path = `/API/collection/${collectionId}/order`;
  return vFetch({
    path,
    method: 'POST',
    body: collectionReorderDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `PUT /API/collection/${collectionId}/map-to-folder`
 *
 * @function collection.markFolderCollection
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function markFolderCollection({ collectionId = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/map-to-folder`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `DELETE /API/collection/${collectionId}/map-to-folder`
 *
 * @function collection.unMarkFolderCollection
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function unMarkFolderCollection({ collectionId = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/map-to-folder`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * `PUT /API/collection/${collectionId}/folder-name`
 *
 * @function collection.updateFolderCollection
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function updateFolderCollection({
  collectionId = isRequired(),
  queryParams = isRequired(),
  ...props
}) {
  const path = `/API/collection/${collectionId}/folder-name`;
  return vFetch({
    path,
    method: 'PUT',
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * @function collection.listCollectionMetadataLock
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function listCollectionMetadataLock({ collectionId = isRequired(), ...props }) {
  return listEntityMetadataLock({
    entity: 'collection',
    entityId: collectionId,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * @function collection.createCollectionMetadataLock
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function createCollectionMetadataLock({ collectionId = isRequired(), ...props }) {
  return createEntityMetadataLock({
    entity: 'collection',
    entityId: collectionId,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * @function collection.getCollectionMetadataLock
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function getCollectionMetadataLock({ collectionId = isRequired(), ...props }) {
  return getEntityMetadataLock({
    entity: 'collection',
    entityId: collectionId,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * @function collection.updateCollectionMetadataLock
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function updateCollectionMetadataLock({ collectionId = isRequired(), ...props }) {
  return updateEntityMetadataLock({
    entity: 'collection',
    entityId: collectionId,
    ...props,
  });
}
/**
 * {@link https://apidoc.vidispine.com/latest/ref/collection.html APIdoc}
 *
 * @function collection.removeCollectionMetadataLock
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function removeCollectionMetadataLock({ collectionId = isRequired(), ...props }) {
  return removeEntityMetadataLock({
    entity: 'collection',
    entityId: collectionId,
    ...props,
  });
}
export default {
  listCollection,
  getCollection,
  getCollectionMetadata,
  updateCollectionMetadata,
  getCollectionAncestor,
  listCollectionHistory,
  getCollectionItem,
  searchCollection,
  searchCollectionItem,
  createCollection,
  removeCollection,
  removeCollectionList,
  renameCollection,
  addCollectionEntity,
  removeCollectionEntity,
  updateCollection,
  orderCollection,
  markFolderCollection,
  unMarkFolderCollection,
  updateFolderCollection,
  listCollectionMetadataLock,
  createCollectionMetadataLock,
  getCollectionMetadataLock,
  updateCollectionMetadataLock,
  removeCollectionMetadataLock,
};
