import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `GET /API/storage/file/`
 *
 * @function file.listFile
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listFile({ ...props } = {}) {
  const path = '/API/storage/file/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `GET /API/storage/${storageId}/file/`
 *
 * @function file.listFileStorage
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function listFileStorage({ storageId = isRequired(), ...props } = {}) {
  const path = `/API/storage/${storageId}/file/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `GET /API/storage/file/importable/`
 *
 * @function file.listFileImportable
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listFileImportable({ ...props } = {}) {
  const path = '/API/storage/file/importable/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `GET `
 *
 * @function file.listFileStorageImportable
 * @param {Object} obj
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function listFileStorageImportable({ storageId = isRequired(), ...props } = {}) {
  const path = `/API/storage/${storageId}/file/importable/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `GET /API/storage/file/${fileId}/`
 *
 * @function file.getFile
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function getFile({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/${storageId}/file/data`
 *
 * @function file.createFileRaw
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createFileRaw({
  storageId = isRequired(),
  file = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { contentType: 'application/octet-stream', ...propHeaders };
  const path = `/API/storage/${storageId}/file/data`;
  return vFetch({
    path,
    method: 'POST',
    body: file,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `GET /API/storage/file/${fileId}/data`
 *
 * @function file.getFileRaw
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function getFileRaw({ fileId = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'application/octet-stream', ...propHeaders };
  const path = `/API/storage/file/${fileId}/data`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    responseType: 'blob',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/file/${fileId}/data/`
 *
 * @function file.updateFileRaw
 * @param {Object} obj
 * @param {string} obj.fileId
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function updateFileRaw({ fileId = isRequired(), file, headers: propHeaders = {}, ...props }) {
  const headers = { contentType: 'application/octet-stream', ...propHeaders };
  const path = `/API/storage/file/${fileId}/data/`;
  return vFetch({
    path,
    method: 'POST',
    body: file,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/file/${fileId}/import/`
 *
 * @function file.createFileImport
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function createFileImport({ fileId = isRequired(), metadataDocument, ...props }) {
  const path = `/API/storage/file/${fileId}/import/`;
  return vFetch({
    path,
    method: 'POST',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/file/${fileId}/import/assetmap`
 *
 * @function file.createFileImportAssetMap
 * @param {Object} obj
 * @param {string} obj.fileId
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function createFileImportAssetMap({ fileId = isRequired(), metadataDocument, ...props }) {
  const path = `/API/storage/file/${fileId}/import/assetmap`;
  return vFetch({
    path,
    method: 'POST',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/${storageId}/file/import/assetmap`
 *
 * @function file.createFilePathImportAssetMap
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function createFilePathImportAssetMap({ storageId = isRequired(), metadataDocument, ...props }) {
  const path = `/API/storage/${storageId}/file/import/assetmap`;
  return vFetch({
    path,
    method: 'POST',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/${storageId}/import/`
 *
 * @function file.createFileImportPath
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function createFileImportPath({ storageId = isRequired(), metadataDocument, ...props }) {
  const path = `/API/storage/${storageId}/import/`;
  return vFetch({
    path,
    method: 'POST',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/file/${fileId}/storage/${targetStorageId}/`
 *
 * @function file.createFileMove
 * @param {Object} obj
 * @param {string} obj.fileId
 * @param {string} obj.targetStorageId
 * @returns {Promise<Object>}
 */
function createFileMove({ fileId = isRequired(), targetStorageId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/storage/${targetStorageId}/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `DELETE /API/storage/file/${fileId}`
 *
 * @function file.removeFile
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function removeFile({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/${storageId}/file/`
 *
 * @function file.createFileEntity
 * @param {Object} obj
 * @param {string} obj.storageId
 * @param {Object} obj.fileDocument
 * @returns {Promise<Object>}
 */
function createFileEntity({ storageId = isRequired(), fileDocument, ...props }) {
  const path = `/API/storage/${storageId}/file/`;
  return vFetch({
    path,
    method: 'POST',
    body: fileDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `DELETE /API/storage/file/${fileId}/entity`
 *
 * @function file.removeFileEntity
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function removeFileEntity({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/entity`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/file/${fileId}/path/`
 *
 * @function file.createFilePath
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function createFilePath({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/path/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `PUT /API/storage/file/${fileId}/state/${state}`
 *
 * @function file.updateFileState
 * @param {Object} obj
 * @param {string} obj.fileId
 * @param {string} obj.state
 * @returns {Promise<Object>}
 */
function updateFileState({ fileId = isRequired(), state = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/state/${state}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}
/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html#set-file-hash APIdoc}
 *
 * `PUT /API/storage/file/${fileId}/hash/${hash}`
 *
 * @function file.updateFileHash
 * @param {Object} obj
 * @param {string} obj.fileId
 * @param {string} obj.hash
 * @returns {Promise<Object>}
 */
function updateFileHash({ fileId = isRequired(), hash = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/hash/${hash}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `PUT /API/storage/file/${fileId}/abandon`
 *
 * @function file.abandonFile
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function abandonFile({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/abandon`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `PUT /API/storage/file/${fileId}/re-index`
 *
 * @function file.reindexFile
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function reindexFile({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/re-index`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html APIdoc}
 *
 * `POST /API/storage/file/${fileId}/uri`
 *
 * @function file.createFileTemporaryCredentials
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function createFileTemporaryCredentials({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/uri`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html#perform-shape-deduction-on-a-file APIdoc}
 *
 * `POST /API/storage/file/${fileId}/analyze`
 *
 * @function file.analyzeFile
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function analyzeFile({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/analyze`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html#perform-shape-deduction-on-an-imf-package APIdoc}
 *
 * `POST /API/storage/file/${fileId}/analyze/imp`
 *
 * @function file.analyzeFileImp
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function analyzeFileImp({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/analyze/imp`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/file.html#get-shapes-for-a-file APIdoc}
 *
 * `GET /API/storage/file/${fileId}/shape`
 *
 * @function file.listFileShapes
 * @param {Object} obj
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function listFileShapes({ fileId = isRequired(), ...props }) {
  const path = `/API/storage/file/${fileId}/shape`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  listFile,
  listFileStorage,
  listFileImportable,
  listFileStorageImportable,
  getFile,
  createFileRaw,
  getFileRaw,
  updateFileRaw,
  createFileImport,
  createFileImportAssetMap,
  createFilePathImportAssetMap,
  createFileImportPath,
  createFileMove,
  removeFile,
  createFileEntity,
  removeFileEntity,
  createFilePath,
  updateFileState,
  updateFileHash,
  abandonFile,
  reindexFile,
  createFileTemporaryCredentials,
  analyzeFile,
  analyzeFileImp,
  listFileShapes,
};
