import { vFetch } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/index.html APIdoc}
 *
 * `GET /API/error`
 *
 * @function errorlog.listErrorLogs
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listErrorLogs({ ...props } = {}) {
  return vFetch({
    path: '/API/error',
    method: 'GET',
    ...props,
  });
}

export default { listErrorLogs };
