import { collection as CollectionApi } from '@vidispine/vdt-api';

export const handleAddEntity = ({ entityId, entityType, collectionId, rootParentId, onError }) => {
  if (collectionId && collectionId !== rootParentId) {
    try {
      return CollectionApi.addCollectionEntity({
        collectionId,
        entityId,
        queryParams: {
          type: entityType.toLowerCase(),
        },
      });
    } catch (error) {
      onError({
        message: `Could not add ${entityId} to collection ${collectionId}`,
        error,
      });
    }
  }
  return undefined;
};
export const handleRemoveEntity = ({
  entityId,
  entityType,
  collectionId,
  rootParentId,
  onError,
}) => {
  if (collectionId && collectionId !== rootParentId) {
    try {
      return CollectionApi.removeCollectionEntity({
        collectionId,
        entityId,
        queryParams: {
          type: entityType.toLowerCase(),
        },
      });
    } catch (error) {
      onError({
        message: `Could not remove ${entityId} from collection ${collectionId}`,
        error,
      });
    }
  }
  return undefined;
};

export const handleMoveEntity = ({
  entityId,
  entityType,
  addToCollectionId,
  removeFromCollectionId,
  rootParentId,
  onAddEntity = handleAddEntity,
  onRemoveEntity = handleRemoveEntity,
  onMoved = () => null,
  onAdded = () => null,
  onRemoved = () => null,
  // eslint-disable-next-line no-console
  onError = ({ message, error }) => console.error(`${message}\n${error}`),
}) => {
  if (!addToCollectionId) {
    onError({ message: 'Missing the collection id to add entity to', error: '' });
    return;
  }
  if (!removeFromCollectionId) {
    onError({ message: 'Missing the collection id to remove entity from', error: '' });
    return;
  }
  try {
    const addPromise = onAddEntity({
      entityId,
      entityType,
      rootParentId,
      collectionId: addToCollectionId,
      onError,
    });
    const removePromise = onRemoveEntity({
      entityId,
      entityType,
      rootParentId,
      collectionId: removeFromCollectionId,
      onError,
    });
    const promises = [addPromise, removePromise].filter((e) => e !== undefined);
    Promise.allSettled(promises)
      .then((results) => {
        const failedResults = results.filter((r) => r.status !== 'fulfilled');
        const allSucceeded = failedResults.length === 0;
        const allFailed = failedResults.length === promises.length;
        const oneFailed = failedResults.length === 1;
        if (allSucceeded) {
          onMoved();
          onAdded();
          onRemoved();
        } else if (allFailed) {
          onError({
            message:
              `Could not move ${entityId} from ` +
              `${removeFromCollectionId} to ${addToCollectionId}`,
            error: failedResults.map((fr) => fr.reason).join('\n'),
          });
        } else if (oneFailed) {
          const failedRequest = failedResults[0].reason.config.method;
          const failedToRemove = failedRequest === 'delete';
          const failedToAdd = failedRequest === 'put';
          onError({
            message: failedToRemove
              ? `Could not remove ${entityId} from collection ${removeFromCollectionId}`
              : `Could not add ${entityId} to collection ${addToCollectionId}`,
            error: failedResults[0].reason,
          });
          let rollbackPromise;
          if (failedToRemove) {
            rollbackPromise = onRemoveEntity({
              entityId,
              entityType,
              collectionId: addToCollectionId,
              onError,
            });
          } else if (failedToAdd) {
            rollbackPromise = onAddEntity({
              entityId,
              entityType,
              collectionId: removeFromCollectionId,
              onError,
            });
          }
          if (rollbackPromise) {
            rollbackPromise
              .then(() => {
                // eslint-disable-next-line no-console
                console.info(
                  `Rollbacked moving ${entityId} from ` +
                    `${removeFromCollectionId} to ${addToCollectionId}`,
                );
              })
              .catch((error) => {
                onError({ message: 'Could not rollback after moving entity', error });
              });
          }
        }
      })
      .catch((error) => {
        onError({
          message: `Could not add ${entityId} to collection ${addToCollectionId}`,
          error,
        });
      });
  } catch (error) {
    onError({
      message: `Could not move ${entityId} from ${removeFromCollectionId} to collection ${addToCollectionId}`,
      error,
    });
  }
};

export default { handleMoveEntity, handleAddEntity, handleRemoveEntity };
