import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/timeline.html APIdoc}
 *
 * `GET /API/item/${itemId}/timeline`
 *
 * @function timeline.getTimeline
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getTimeline({ itemId = isRequired(), headers: propsHeaders = {}, ...props }) {
  const path = `/API/item/${itemId}/timeline`;
  const headers = { accept: 'text/plain', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/timeline.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/timeline`
 *
 * @function timeline.removeTimeline
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function removeTimeline({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/timeline`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/timeline.html APIdoc}
 *
 * `GET /API/item/${itemId}/timeline/${format}`
 *
 * @function timeline.getTimelineFormat
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function getTimelineFormat({ itemId = isRequired(), format = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/timeline/${format}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/timeline.html APIdoc}
 *
 * `PUT /API/item/${itemId}/timeline/${format}`
 *
 * @function timeline.updateTimelineFormat
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.format
 * @param {Object} obj.body
 * @returns {Promise<Object>}
 */
function updateTimelineFormat({
  itemId = isRequired(),
  format = isRequired(),
  body = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/timeline/${format}`;
  return vFetch({
    path,
    method: 'PUT',
    body,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/timeline.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/timeline/${format}`
 *
 * @function timeline.removeTimelineFormat
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function removeTimelineFormat({ itemId = isRequired(), format = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/timeline/${format}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  getTimeline,
  removeTimeline,
  getTimelineFormat,
  updateTimelineFormat,
  removeTimelineFormat,
};
