import { vFetch, isRequired } from './utils';
import notificationApi from './notification';

const { listNotification } = notificationApi;

/**
 * {@link https://apidoc.vidispine.com/latest/ref/job.html APIdoc}
 *
 * `GET /API/job/`
 *
 * @function job.listJob
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listJob({ ...props } = {}) {
  const path = '/API/job/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/job.html#search-and-count-jobs APIdoc}
 *
 * `PUT /API/job/search`
 *
 * @function job.searchJob
 * @param {Object} obj
 * @param {Object} obj.jobSearchDocument
 * @returns {Promise<Object>}
 */
function searchJob({ jobSearchDocument = {}, ...props } = {}) {
  const path = '/API/job/search/';
  return vFetch({
    path,
    method: 'PUT',
    body: jobSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/job.html APIdoc}
 *
 * `GET /API/job/${jobId}`
 *
 * @function job.getJob
 * @param {Object} obj
 * @param {string} obj.jobId
 * @returns {Promise<Object>}
 */
function getJob({ jobId = isRequired(), ...props }) {
  const path = `/API/job/${jobId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/job.html APIdoc}
 *
 * `POST /API/job`
 *
 * @function job.createJob
 * @param {Object} obj
 * @param {Object} obj.simpleMetadataDocument
 * @returns {Promise<Object>}
 */
function createJob({ simpleMetadataDocument, ...props } = {}) {
  const path = '/API/job';
  return vFetch({
    path,
    method: 'POST',
    body: simpleMetadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/job.html APIdoc}
 *
 * `POST /API/job/${jobId}/re-run`
 *
 * @function job.duplicateJob
 * @param {Object} obj
 * @param {string} obj.jobId
 * @returns {Promise<Object>}
 */
function duplicateJob({ jobId = isRequired(), ...props }) {
  const path = `/API/job/${jobId}/re-run`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/job.html APIdoc}
 *
 * `PUT /API/job/${jobId}`
 *
 * @function job.modifyJob
 * @param {Object} obj
 * @param {string} obj.jobId
 * @returns {Promise<Object>}
 */
function modifyJob({ jobId = isRequired(), ...props }) {
  const path = `/API/job/${jobId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/job.html APIdoc}
 *
 * `DELETE /API/job/${jobId}`
 *
 * @function job.abortJob
 * @param {Object} obj
 * @param {string} obj.jobId
 * @returns {Promise<Object>}
 */
function abortJob({ jobId = isRequired(), ...props }) {
  const path = `/API/job/${jobId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/job.html APIdoc}
 *
 * `DELETE /API/job/`
 *
 * @function job.deleteJob
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function deleteJob({ ...props } = {}) {
  const path = '/API/job/';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/job.html APIdoc}
 *
 * @function job.listJobNotification
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listJobNotification({ ...props } = {}) {
  const entityType = 'job';
  return listNotification({
    entityType,
    ...props,
  });
}

export default {
  listJob,
  searchJob,
  getJob,
  createJob,
  duplicateJob,
  modifyJob,
  abortJob,
  deleteJob,
  listJobNotification,
};
