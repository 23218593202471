/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Player } from '@vidispine/vdt-videojs';
import '../../vdt-videojs/lib/style.css';


export class VideoPlayer extends React.Component {
  componentDidMount() {
    const { setPlayer, ...playerProps } = this.props;

    this.player = new Player({
      target: this.videoNode,
      ...playerProps,
    });

    if (setPlayer) { setPlayer(this.player); }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(props) {
    const createPlayer = () => {
      const { setPlayer, ...playerProps } = props;
      this.player = new Player({
        target: this.videoNode,
        ...playerProps,
      });
      if (setPlayer) { setPlayer(this.player); }
    };

    if (props.sources && props.sources[0] && props.sources[0].src) {
      // sources changed
      if (!(this.props.sources[0].src === props.sources[0].src)) {
        if (this.player) {
          this.player.videojs.pause();
          // Wait a frame until player is paused
          setTimeout(() => {
            this.player.videojs.dispose();
            createPlayer();
          }, 0);
        } else {
          createPlayer();
        }
      } else { // update reactive props
        this.player.handleNewProps(props);
      }
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    const { onUnmount } = this.props;

    if (this.player) {
      if (onUnmount) onUnmount(this.player);
      this.player.destroy();
    }
  }


  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div className="data-vjs-player">
        <div ref={(node) => { this.videoNode = node; }} />
      </div>
    );
  }
}
