import React from 'react';

export default function useUpdateEffect(fn, inputs) {
  const hasMountedRef = React.useRef(false);
  React.useEffect(() => {
    if (hasMountedRef.current) {
      fn();
    } else {
      hasMountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
