import React from 'react';
import {
  item as ItemApi,
  collection as CollectionApi,
  library as LibraryApi,
} from '@vidispine/vdt-api';

import useApi from './useApi';

const useGetEntity = ({ entityId, entityType }) => {
  let dataType;
  let entityApi;
  let entityTypeId;
  switch (entityType) {
    case 'collection':
      dataType = 'collectionType';
      entityTypeId = 'collectionId';
      entityApi = CollectionApi.getCollection;
      break;
    case 'library':
      dataType = 'libraryType';
      entityTypeId = 'libraryId';
      entityApi = LibraryApi.getLibrary;
      break;
    case 'item':
      dataType = 'itemType';
      entityTypeId = 'itemId';
      entityApi = ItemApi.getItem;
      break;
    default:
      throw new Error(`unknown entityType}`);
  }
  const { request, data, ...apiProps } = useApi(entityApi);
  const onGetEntity = React.useCallback(
    (props) => request({ [entityTypeId]: entityId, ...props }),
    [entityId, entityTypeId, request],
  );
  return {
    [dataType]: data,
    [entityTypeId]: entityId,
    onGetEntity,
    ...apiProps,
  };
};

export default useGetEntity;
