import React from 'react';
import { parseMetadataType } from '@vidispine/vdt-js';

export default function MetadataType({
  itemType,
  collectionType,
  joinValue = ',',
  flat = true,
  children,
  ...props
}) {
  const entityType = itemType || collectionType || {};
  const { metadata: metadataType } = entityType;
  const metadata = React.useMemo(() => parseMetadataType(metadataType, { joinValue, flat }), [
    flat,
    joinValue,
    metadataType,
  ]);
  const cloneProps = {
    ...props,
    ...metadata,
    itemType,
  };
  const cloneChild = (child) => React.cloneElement(child, cloneProps);
  return React.Children.map(children, cloneChild);
}
