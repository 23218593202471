import React from 'react';
import { user as UserApi } from '@vidispine/vdt-api';
import { roles as ROLES } from '@vidispine/vdt-js';

const RolesContext = React.createContext();

function RolesProvider({ intialState = {}, onRefresh, userName: knownUserName, children }) {
  const [groupListType, setGroupListType] = React.useState(intialState);
  const onGetUserRole = () => {
    if (knownUserName) {
      UserApi.getUserRole({ userName: knownUserName }).then(({ data }) => {
        setGroupListType(data);
        if (onRefresh) onRefresh(data);
      });
    } else {
      let iAm;
      UserApi.whoAmI()
        .then(({ data: userName }) => {
          iAm = userName;
          return UserApi.getUserRole({ userName });
        })
        .then(({ data }) => {
          setGroupListType(data);
          if (onRefresh) onRefresh(data, iAm);
        });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(onGetUserRole, [knownUserName]);
  return <RolesContext.Provider value={groupListType}>{children}</RolesContext.Provider>;
}

function useRoles() {
  const groupListType = React.useContext(RolesContext);
  if (groupListType === undefined) {
    throw new Error('useRoles must be used within RolesContext');
  }
  const hasRole = (roleName) => {
    if (roleName === undefined) throw new Error('roleName is undefined');
    const { group: groupList } = groupListType;
    if (!Array.isArray(groupList)) return null;
    const groupNames = groupList.map(({ groupName }) => groupName);
    if (groupNames.includes(ROLES.SUPER_ACCESS_USER)) return true;
    if (Array.isArray(roleName)) {
      return roleName.findIndex((thisRole) => groupNames.includes(thisRole)) > -1;
    }
    return groupNames.includes(roleName);
  };
  return { hasRole, groupListType };
}

export { RolesProvider, useRoles };
