import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `GET /API/group/`
 *
 * @function group.listGroup
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listGroup({ ...props } = {}) {
  const path = '/API/group/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `GET /API/group/${groupName}`
 *
 * @function group.getGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/group/${groupName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `PUT /API/group/${groupName}/`
 *
 * @function group.updateGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {Object} obj.groupDocument
 * @returns {Promise<Object>}
 */
function updateGroup({ groupName = isRequired(), groupDocument, ...props }) {
  const path = `/API/group/${groupName}/`;
  return vFetch({
    path,
    method: 'PUT',
    body: groupDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `DELETE /API/group/${groupName}`
 *
 * @function group.removeGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function removeGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/group/${groupName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html#delete-multiple-groups APIdoc}
 *
 * `DELETE /API/group/`
 *
 * @function group.removeGroupList
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeGroupList({ ...props }) {
  const path = '/API/group/';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `PUT /API/group/`
 *
 * @function group.searchGroup
 * @param {Object} obj
 * @param {Object} obj.groupSearchDocument
 * @returns {Promise<Object>}
 */
function searchGroup({ groupSearchDocument, ...props }) {
  const path = '/API/group/';
  return vFetch({
    path,
    method: 'PUT',
    body: groupSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `GET API/group/${groupName}/role`
 *
 * @function group.getGroupRoleStatus
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getGroupRoleStatus({ groupName = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/group/${groupName}/role`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `GET /API/group/${groupName}/description`
 *
 * @function group.getGroupDescription
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getGroupDescription({ groupName = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/group/${groupName}/description`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `PUT /API/group/${groupName}/description`
 *
 * @function group.updateGroupDescription
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.description
 * @returns {Promise<Object>}
 */
function updateGroupDescription({
  groupName = isRequired(),
  description,
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', contentType: 'text/plain', ...propHeaders };
  const path = `/API/group/${groupName}/description`;
  return vFetch({
    path,
    method: 'PUT',
    body: description,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `GET /API/group/${groupName}/parents/`
 *
 * @function group.getGroupParents
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getGroupParents({ groupName = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/group/${groupName}/parents/`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `GET /API/group/${groupName}/children/`
 *
 * @function group.getGroupChildren
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getGroupChildren({ groupName = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/group/${groupName}/children/`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `PUT /API/group/${groupName}/group/${childGroupName}`
 *
 * @function group.addGroupChildren
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.childGroupName
 * @returns {Promise<Object>}
 */
function addGroupChildren({ groupName = isRequired(), childGroupName = isRequired(), ...props }) {
  const path = `/API/group/${groupName}/group/${childGroupName}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `DELETE /API/group/${groupName}/group/${childGroupName}`
 *
 * @function group.removeGroupChildren
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.childGroupName
 * @returns {Promise<Object>}
 */
function removeGroupChildren({
  groupName = isRequired(),
  childGroupName = isRequired(),
  ...props
}) {
  const path = `/API/group/${groupName}/group/${childGroupName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `GET /API/group/${groupName}/users/`
 *
 * @function group.getGroupUser
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getGroupUser({ groupName = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/group/${groupName}/users/`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `PUT /API/group/${groupName}/user/${userName}`
 *
 * @function group.addGroupUser
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function addGroupUser({ groupName = isRequired(), userName = isRequired(), ...props }) {
  const path = `/API/group/${groupName}/user/${userName}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/group.html APIdoc}
 *
 * `DELETE /API/group/${groupName}/user/${userName}`
 *
 * @function group.removeGroupUser
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function removeGroupUser({ groupName = isRequired(), userName = isRequired(), ...props }) {
  const path = `/API/group/${groupName}/user/${userName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listGroup,
  getGroup,
  updateGroup,
  removeGroup,
  removeGroupList,
  searchGroup,
  getGroupRoleStatus,
  getGroupDescription,
  updateGroupDescription,
  getGroupParents,
  getGroupChildren,
  addGroupChildren,
  removeGroupChildren,
  getGroupUser,
  addGroupUser,
  removeGroupUser,
};
