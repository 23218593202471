import React from 'react';
import { user as UserApi } from '@vidispine/vdt-api';
import useApi from './useApi';

const CHANGE_PAGE = 'CHANGE_PAGE';
const CHANGE_ROWS = 'CHANGE_ROWS';
const CHANGE_SORT = 'CHANGE_SORT';
const CHANGE_QUERY = 'CHANGE_QUERY';

const ACTION_TYPE = {
  CHANGE_PAGE,
  CHANGE_ROWS,
  CHANGE_SORT,
  CHANGE_QUERY,
};

const setInitialDefaultState = ({ queryParams = {}, rowsPerPage = 10, page = 0 } = {}) => ({
  queryParams: {
    first: page * rowsPerPage + 1,
    number: rowsPerPage,
    ...queryParams,
  },
  rowsPerPage,
  page,
  userSearchDocument: {
    sort: [
      {
        field: 'username',
        order: 'ascending',
      },
    ],
  },
});

const searchReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case ACTION_TYPE.CHANGE_PAGE: {
      const { page } = action;

      return {
        ...state,
        page,
        queryParams: {
          ...state.queryParams,
          first: page * state.rowsPerPage + 1,
        },
      };
    }
    case ACTION_TYPE.CHANGE_ROWS: {
      const { rowsPerPage } = action;
      return {
        ...state,
        rowsPerPage,
        page: 0,
        queryParams: {
          ...state.queryParams,
          first: 1,
          number: rowsPerPage,
        },
      };
    }

    case ACTION_TYPE.CHANGE_QUERY: {
      return {
        ...state,
        page: 0,
        userSearchDocument: {
          ...state.userSearchDocument,
          field: [
            {
              name: action.field || 'username',
              value: action.query || '',
            },
          ],
        },
        queryParams: {
          ...state.queryParams,
          first: 1,
        },
      };
    }
    default:
      return state;
  }
};

export default function useSearchUser(initialState) {
  const initialDefaultState = setInitialDefaultState(initialState);
  const [state, dispatch] = React.useReducer(searchReducer, initialDefaultState);
  const { data: userListDocument, request: searchUser, hasLoaded, isError } = useApi(
    UserApi.searchUser,
  );

  const onSearchUser = () =>
    searchUser({
      userSearchDocument: state.userSearchDocument,
      queryParams: state.queryParams,
    });

  React.useEffect(() => {
    onSearchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.queryParams, state.userSearchDocument]);

  const onChangeRowsPerPage = ({ target: { value: rowsPerPage } } = {}) =>
    dispatch({
      type: ACTION_TYPE.CHANGE_ROWS,
      rowsPerPage,
    });
  const onChangePage = ({ page }) =>
    dispatch({
      type: ACTION_TYPE.CHANGE_PAGE,
      page,
    });
  const onChangeSort = (event, field, order) => {
    const fieldName = typeof field === 'object' ? field.value : field;
    dispatch({
      type: ACTION_TYPE.CHANGE_SORT,
      field: fieldName,
      order,
    });
  };
  const onChangeQuery = (field, query) => {
    dispatch({
      type: ACTION_TYPE.CHANGE_QUERY,
      field,
      query,
    });
  };
  return {
    ...state,
    hasLoaded,
    isError,
    userListDocument,
    onSearchUser,
    onChangePage,
    onChangeSort,
    onChangeRowsPerPage,
    onChangeQuery,
  };
}
