import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/subtitle.html APIdoc}
 *
 * `GET /API/item/${itemId}/metadata/export/scc`
 *
 * @function subtitle.getSubtitleSCC
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getSubtitleSCC({ itemId = isRequired(), headers: propHeaders = {}, ...props }) {
  const path = `/API/item/${itemId}/metadata/export/scc`;
  const headers = { accept: 'text/plain ', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/subtitle.html APIdoc}
 *
 * `GET /API/item/${itemId}/metadata/export/ttml`
 *
 * @function subtitle.getSubtitleTTML
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getSubtitleTTML({ itemId = isRequired(), headers: propHeaders = {}, ...props }) {
  const path = `/API/item/${itemId}/metadata/export/ttml`;
  const headers = { accept: 'application/xml', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

export default {
  getSubtitleSCC,
  getSubtitleTTML,
};
