import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/license.html APIdoc}
 *
 * `POST /API/license`
 *
 * @function license.createLicense
 * @param {Object} obj
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createLicense({ file = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { contentType: 'application/octet-stream', ...propHeaders };
  const path = '/API/license';
  return vFetch({
    path,
    method: 'POST',
    body: file,
    headers,
    ...props,
  });
}

export default { createLicense };
