export const ACCESSCONTROL_READ = '_accesscontrol_read';
export const ACCESSCONTROL_WRITE = '_accesscontrol_write';
export const ADMINISTRATOR = '_administrator';
export const AUTO_PROJECTION_READ = '_auto_projection_read';
export const AUTO_PROJECTION_WRITE = '_auto_projection_write';
export const COLLECTION_NOTIFICATION_READ = '_collection_notification_read';
export const COLLECTION_NOTIFICATION_WRITE = '_collection_notification_write';
export const COLLECTION_READ = '_collection_read';
export const COLLECTION_WRITE = '_collection_write';
export const DELETION_LOCK_NOTIFICATION_READ = '_deletion_lock_notification_read';
export const DELETION_LOCK_NOTIFICATION_WRITE = '_deletion_lock_notification_write';
export const DELETION_LOCK_READ = '_deletion_lock_read';
export const DELETION_LOCK_WRITE = '_deletion_lock_write';
export const DOCUMENT_NOTIFICATION_READ = '_document_notification_read';
export const DOCUMENT_NOTIFICATION_WRITE = '_document_notification_write';
export const DOCUMENT_READ = '_document_read';
export const DOCUMENT_WRITE = '_document_write';
export const ERROR_READ = '_error_read';
export const ERROR_WRITE = '_error_write';
export const EXPORT = '_export';
export const EXPORT_TEMPLATE_READ = '_export_template_read';
export const EXPORT_TEMPLATE_WRITE = '_export_template_write';
export const EXTERNAL_ID_READ = '_external_id_read';
export const EXTERNAL_ID_WRITE = '_external_id_write';
export const FILE_NOTIFICATION_READ = '_file_notification_read';
export const FILE_NOTIFICATION_WRITE = '_file_notification_write';
export const FILE_READ = '_file_read';
export const FILE_WRITE = '_file_write';
export const GROUP_READ = '_group_read';
export const GROUP_WRITE = '_group_write';
export const IMPORT_WRITE = '_import';
export const ITEM_ID_READ = '_item_id_read';
export const ITEM_ID_WRITE = '_item_id_write';
export const ITEM_NOTIFICATION_READ = '_item_notification_read';
export const ITEM_NOTIFICATION_WRITE = '_item_notification_write';
export const ITEM_WRITE = '_item_write';
export const ITEM_SEARCH = '_item_search';
export const ITEM_SHAPE_READ = '_item_shape_read';
export const ITEM_SHAPE_WRITE = '_item_shape_write';
export const ITEM_TIMELINE_READ = '_item_timeline_read';
export const ITEM_TIMELINE_WRITE = '_item_timeline_write';
export const ITEM_URI = '_item_uri';
export const JOB_NOTIFICATION_READ = '_job_notification_read';
export const JOB_NOTIFICATION_WRITE = '_job_notification_write';
export const JOB_READ = '_job_read';
export const JOB_WRITE = '_job_write';
export const LIBRARY_READ = '_library_read';
export const LIBRARY_WRITE = '_library_write';
export const LOCK_READ = '_lock_read';
export const LOCK_WRITE = '_lock_write';
export const LOG_READ = '_log_read';
export const METADATA_DATASET_READ = '_metadata_dataset_read';
export const METADATA_DATASET_WRITE = '_metadata_dataset_write';
export const METADATA_FIELD_GROUP_READ = '_metadata_field_group_read';
export const METADATA_FIELD_GROUP_WRITE = '_metadata_field_group_write';
export const METADATA_FIELD_READ = '_metadata_field_read';
export const METADATA_FIELD_WRITE = '_metadata_field_write';
export const METADATA_GLOBAL_READ = '_metadata_global_read';
export const METADATA_GLOBAL_WRITE = '_metadata_global_write';
export const METADATA_LOCK_READ = '_metadata_lock_read';
export const METADATA_LOCK_WRITE = '_metadata_lock_write';
export const METADATA_READ = '_metadata_read';
export const METADATA_SCHEMA_READ = '_metadata_schema_read';
export const METADATA_SCHEMA_WRITE = '_metadata_schema_write';
export const METADATA_WRITE = '_metadata_write';
export const OTIF_ANALYZE = '_otif_analyze';
export const OTIF_READ = '_otif_read';
export const OTIF_WRITE = '_otif_write';
export const PLACEHOLDER_NOTIFICATION_READ = '_placeholder_notification_read';
export const PLACEHOLDER_NOTIFICATION_WRITE = '_placeholder_notification_write';
export const PROJECTION_READ = '_projection_read';
export const PROJECTION_WRITE = '_projection_write';
export const QUOTA_NOTIFICATION_READ = '_quota_notification_read';
export const QUOTA_NOTIFICATION_WRITE = '_quota_notification_write';
export const QUOTA_READ = '_quota_read';
export const QUOTA_WRITE = '_quota_write';
export const RELATION_READ = '_relation_read';
export const RELATION_WRITE = '_relation_write';
export const RESOURCE_READ = '_resource_read';
export const RESOURCE_WRITE = '_resource_write';
export const RUN_AS = '_run_as';
export const SEARCH = '_search';
export const SEQUENCE_READ = '_sequence_read';
export const SEQUENCE_WRITE = '_sequence_write';
export const SHAPE_TAG_READ = '_shape_tag_read';
export const SHAPE_TAG_WRITE = '_shape_tag_write';
export const SITE_MANAGER = '_site_manager';
export const SITE_RULE_READ = '_site_rule_read';
export const SITE_RULE_WRITE = '_site_rule_write';
export const STORAGE_GROUP_READ = '_storage_group_read';
export const STORAGE_GROUP_WRITE = '_storage_group_write';
export const STORAGE_NOTIFICATION_READ = '_storage_notification_read';
export const STORAGE_NOTIFICATION_WRITE = '_storage_notification_write';
export const STORAGE_READ = '_storage_read';
export const STORAGE_RULE_READ = '_storage_rule_read';
export const STORAGE_RULE_WRITE = '_storage_rule_write';
export const STORAGE_WRITE = '_storage_write';
export const SUPER_ACCESS_USER = '_super_access_user';
export const TASKDEFINITION_READ = '_taskdefinition_read';
export const TASKDEFINITION_WRITE = '_taskdefinition_write';
export const THUMBNAIL_READ = '_thumbnail_read';
export const THUMBNAIL_WRITE = '_thumbnail_write';
export const TRANSCODER = '_transcoder';
export const TRANSFER_READ = '_transfer_read';
export const TRANSFER_WRITE = '_transfer_write';
export const USER = '_user';
export const VXA = '_vxa';
export const VXA_READ = '_vxa_read';
