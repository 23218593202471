import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/placeholder`
 *
 * @function shape.createShapePlaceholder
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function createShapePlaceholder({
  itemId = isRequired(),
  metadataDocument = {},
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/item/${itemId}/shape/placeholder`;
  return vFetch({
    path,
    method: 'POST',
    body: metadataDocument,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/create`
 *
 * @function shape.createShape
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.shapeDocument
 * @returns {Promise<Object>}
 */
function createShape({ itemId = isRequired(), shapeDocument = {}, ...props }) {
  const path = `/API/item/${itemId}/shape/create`;
  return vFetch({
    path,
    method: 'POST',
    body: shapeDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `PUT /API/item/${itemId}/shape/${shapeId}/placeholder`
 *
 * @function shape.updateShapePlaceholder
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {Object} obj.simpleMetadataDocument
 * @returns {Promise<Object>}
 */
function updateShapePlaceholder({
  itemId = isRequired(),
  shapeId = isRequired(),
  simpleMetadataDocument = {},
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/placeholder`;
  return vFetch({
    path,
    method: 'PUT',
    body: simpleMetadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/`
 *
 * @function shape.createShapeImport
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createShapeImport({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/imp`
 *
 * @function shape.createShapeImportImp
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createShapeImportImp({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/imp`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/raw`
 *
 * @function shape.createShapeImportRaw
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createShapeImportRaw({
  itemId = isRequired(),
  headers: propHeaders = {},
  file,
  ...props
}) {
  const path = `/API/item/${itemId}/shape/raw`;
  const headers = { contentType: 'application/octet-stream', ...propHeaders };
  return vFetch({
    path,
    method: 'POST',
    body: file,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/`
 *
 * @function shape.listShape
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listShape({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/`
 *
 * @function shape.getShape
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function getShape({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/graph`
 *
 * @function shape.getShapeGraph
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function getShapeGraph({
  itemId = isRequired(),
  shapeId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/graph`;
  const headers = { accept: 'image/png', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/graph/dot`
 *
 * @function shape.getShapeGraphDot
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function getShapeGraphDot({
  itemId = isRequired(),
  shapeId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/graph/dot`;
  const headers = { accept: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/cpl`
 *
 * @function shape.getShapeCpl
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function getShapeCpl({
  itemId = isRequired(),
  shapeId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/cpl`;
  const headers = { accept: 'application/xml', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/transcode`
 *
 * @function shape.createShapeTranscode
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function createShapeTranscode({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/transcode`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/version`
 *
 * @function shape.listShapeEssence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listShapeEssence({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/version`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/version/${versionId}`
 *
 * @function shape.getShapeEssence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.versionId
 * @returns {Promise<Object>}
 */
function getShapeEssence({ itemId = isRequired(), versionId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/version/${versionId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/shape/version/${versionId}`
 *
 * @function shape.removeShapeEssence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.versionId
 * @returns {Promise<Object>}
 */
function removeShapeEssence({ itemId = isRequired(), versionId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/version/${versionId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/version/`
 *
 * @function shape.copyShapeEssence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function copyShapeEssence({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/version/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/version/${versionId}`
 *
 * @function shape.copyShapeEssenceSpecific
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.versionId
 * @returns {Promise<Object>}
 */
function copyShapeEssenceSpecific({ itemId = isRequired(), versionId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/version/${versionId}`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/essence`
 *
 * @function shape.createShapeEssenceImport
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createShapeEssenceImport({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/essence`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/essence/raw`
 *
 * @function shape.createShapeEssenceImportRaw
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.file
 * @returns {Promise<Object>}
 */
function createShapeEssenceImportRaw({
  itemId = isRequired(),
  headers: propHeaders = {},
  file,
  ...props
}) {
  const path = `/API/item/${itemId}/shape/essence/raw`;
  const headers = { contentType: 'application/octet-stream', ...propHeaders };
  return vFetch({
    path,
    body: file,
    method: 'POST',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/shape/${shapeId}`
 *
 * @function shape.removeShape
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function removeShape({ itemId = isRequired(), shapeId = isRequired(), ...props } = {}) {
  const path = `/API/item/${itemId}/shape/${shapeId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/shape/`
 *
 * @function shape.removeShapeAll
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function removeShapeAll({ itemId = isRequired(), ...props } = {}) {
  const path = `/API/item/${itemId}/shape/`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/transcode.html#transcode-a-specific-shape APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/transcode`
 *
 * @function shape.createTranscode
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function createTranscode({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/transcode`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/thumbnail`
 *
 * @function shape.createThumbnail
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function createThumbnail({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/thumbnail`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/update`
 *
 * @function shape.createDeduction
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function createDeduction({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/update`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}file`
 *
 * @function shape.listShapeFile
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function listShapeFile({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}file`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/tag`
 *
 * @function shape.getShapeTag
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function getShapeTag({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/tag`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `PUT /API/item/${itemId}/shape/${shapeId}/tag/${tagName}`
 *
 * @function shape.updateShapeTag
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function updateShapeTag({
  itemId = isRequired(),
  shapeId = isRequired(),
  tagName = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/tag/${tagName}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/shape/${shapeId}/tag/${tagName}`
 *
 * @function shape.removeShapeTag
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function removeShapeTag({
  itemId = isRequired(),
  shapeId = isRequired(),
  tagName = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/tag/${tagName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/mime`
 *
 * @function shape.getShapeMime
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function getShapeMime({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/mime`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `PUT /API/item/${itemId}/shape/${shapeId}/mime/${mimeType}`
 *
 * @function shape.updateShapeMime
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.mimeType
 * @returns {Promise<Object>}
 */
function updateShapeMime({
  itemId = isRequired(),
  shapeId = isRequired(),
  mimeType = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/mime/${mimeType}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/shape/${shapeId}/mime/${mimeType}`
 *
 * @function shape.removeShapeMime
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.mimeType
 * @returns {Promise<Object>}
 */
function removeShapeMime({
  itemId = isRequired(),
  shapeId = isRequired(),
  mimeType = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/mime/${mimeType}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/filename`
 *
 * @function shape.getShapeFileNameRule
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
function getShapeFileNameRule({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/filename`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `PUT /API/item/${itemId}/shape/${shapeId}/filename/${storageId}`
 *
 * @function shape.createShapeFileNameRule
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.storageId
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function createShapeFileNameRule({
  itemId = isRequired(),
  shapeId = isRequired(),
  storageId = isRequired(),
  queryParams = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/filename/${storageId}`;
  return vFetch({
    path,
    method: 'PUT',
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/shape/${shapeId}/filename/${storageId}`
 *
 * @function shape.removeShapeFileNameRule
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function removeShapeFileNameRule({
  itemId = isRequired(),
  shapeId = isRequired(),
  storageId = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/filename/${storageId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/shape.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/analyze`
 *
 * @function shape.createShapeAnalyze
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {Object} obj.analyzeJobDocument
 * @returns {Promise<Object>}
 */
function createShapeAnalyze({
  itemId = isRequired(),
  shapeId = isRequired(),
  analyzeJobDocument,
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/analyze`;
  return vFetch({
    path,
    method: 'POST',
    body: analyzeJobDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#start-a-smart-cropping-job APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/smartcrop-render`
 *
 * @function shape.createSmartcrop
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
export function createSmartcrop({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/smartcrop-render`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#get-a-smart-cropping-edl APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/smartcrop-edl`
 *
 * @function shape.getSmartcrop
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
export function getSmartcrop({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/smartcrop-edl`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#start-a-highlight-reel-creation-job APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/highlight-render`
 *
 * @function shape.createHighlight
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
export function createHighlight({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/highlight-render`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#get-a-highlight-reel-edl APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/highlighter-edl`
 *
 * @function shape.getHighlight
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
export function getHighlight({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/highlighter-edl`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  createShapePlaceholder,
  createShape,
  updateShapePlaceholder,
  createShapeImport,
  createShapeImportImp,
  createShapeImportRaw,
  listShape,
  getShape,
  getShapeGraph,
  getShapeGraphDot,
  getShapeCpl,
  createShapeTranscode,
  listShapeEssence,
  getShapeEssence,
  removeShapeEssence,
  copyShapeEssence,
  copyShapeEssenceSpecific,
  createShapeEssenceImport,
  createShapeEssenceImportRaw,
  removeShape,
  removeShapeAll,
  createTranscode,
  createThumbnail,
  createDeduction,
  listShapeFile,
  getShapeTag,
  updateShapeTag,
  removeShapeTag,
  getShapeMime,
  updateShapeMime,
  removeShapeMime,
  getShapeFileNameRule,
  createShapeFileNameRule,
  removeShapeFileNameRule,
  createShapeAnalyze,
  createSmartcrop,
  getSmartcrop,
  createHighlight,
  getHighlight,
};
