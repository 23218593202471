import { vFetch, isRequired } from './utils';
import metadataApi from './metadata';
import metadataLockApi from './metadatalock';

const { updateEntityMetadata, getEntityMetadata } = metadataApi;
const {
  listEntityMetadataLock,
  createEntityMetadataLock,
  getEntityMetadataLock,
  updateEntityMetadataLock,
  removeEntityMetadataLock,
} = metadataLockApi;

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/`
 *
 * @function item.listItem
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listItem({ ...props } = {}) {
  const path = '/API/item/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/${itemId}/`
 *
 * @function item.getItem
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getItem({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/${itemId}/uri`
 *
 * @function item.getItemUri
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getItemUri({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/uri`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/`
 *
 * @function item.removeItem
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function removeItem({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `DELETE /API/item/`
 *
 * @function item.removeItemList
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeItemList({ ...props } = {}) {
  const path = '/API/item/';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `PUT /API/item/`
 *
 * @function item.searchItem
 * @param {Object} obj
 * @param {Object} obj.itemSearchDocument
 * @returns {Promise<Object>}
 */
function searchItem({ itemSearchDocument = {}, ...props } = {}) {
  return vFetch({
    path: '/API/item/',
    method: 'PUT',
    body: itemSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `PUT /API/item/metadata`
 *
 * @function item.updateItemMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function updateItemMetadata({ itemId = isRequired(), metadataDocument, ...props }) {
  return updateEntityMetadata({
    entity: 'item',
    entityId: itemId,
    metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/metadata`
 *
 * @function item.getItemMetadata
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getItemMetadata({ itemId = isRequired(), ...props }) {
  return getEntityMetadata({
    entity: 'item',
    entityId: itemId,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `POST /API/item/${itemId}/thumbnail`
 *
 * @function item.createThumbnail
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createThumbnail({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/thumbnail`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/transcode.html#transcode-an-item APIdoc}
 *
 * `POST /API/item/${itemId}/transcode`
 *
 * @function item.createTranscode
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createTranscode({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/transcode`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `POST /API/item/${itemId}/export`
 *
 * @function item.createExport
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createExport({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/export`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `POST /API/item/${itemId}/export/imp`
 *
 * @function item.createExportImp
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createExportImp({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/export/imp`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/${itemId}/relation`
 *
 * @function item.listItemRelation
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listItemRelation({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/relation`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `POST /API/item/${itemId}/relation/${relationItemId}`
 *
 * @function item.createItemRelation
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.relationItemId
 * @returns {Promise<Object>}
 */
function createItemRelation({ itemId = isRequired(), relationItemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/relation/${relationItemId}`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/relations.html#create-multiple-item-relations APIdoc}
 *
 * `POST /API/relation/`
 *
 * @function item.createItemRelationList
 * @param {Object} obj
 * @param {string} obj.itemRelationListDocument
 * @returns {Promise<Object>}
 */
function createItemRelationList({ itemRelationListDocument = isRequired(), ...props }) {
  const path = '/API/relation/';
  return vFetch({
    path,
    method: 'POST',
    body: itemRelationListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/relation/${relationItemId}`
 *
 * @function item.removeItemRelation
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.relationItemId
 * @returns {Promise<Object>}
 */
function removeItemRelation({ itemId = isRequired(), relationItemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/relation/${relationItemId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/relation/`
 *
 * @function item.removeAllItemRelation
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function removeAllItemRelation({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/relation/`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `POST /API/relation`
 *
 * @function item.createRelation
 * @param {Object} obj
 * @param {Object} obj.itemRelationListDocument
 * @returns {Promise<Object>}
 */
function createRelation({ itemRelationListDocument = isRequired(), ...props }) {
  const path = '/API/relation';
  return vFetch({
    path,
    method: 'POST',
    body: itemRelationListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/relation/${relationId}/`
 *
 * @function item.getRelation
 * @param {Object} obj
 * @param {string} obj.relationId
 * @returns {Promise<Object>}
 */
function getRelation({ relationId = isRequired(), ...props }) {
  const path = `/API/relation/${relationId}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `PUT /API/relation/${relationId}/`
 *
 * @function item.updateRelation
 * @param {Object} obj
 * @param {string} obj.relationId
 * @returns {Promise<Object>}
 */
function updateRelation({ relationId = isRequired(), ...props }) {
  const path = `/API/relation/${relationId}/`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `DELETE /API/relation/${relationId}/`
 *
 * @function item.removeRelation
 * @param {Object} obj
 * @param {string} obj.relationId
 * @returns {Promise<Object>}
 */
function removeRelation({ relationId = isRequired(), ...props }) {
  const path = `/API/relation/${relationId}/`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/history`
 *
 * @function item.getItemSearchHistory
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getItemSearchHistory({ ...props } = {}) {
  const path = '/API/item/history';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `PUT /API/item/${itemId}/re-index`
 *
 * @function item.reindexItem
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function reindexItem({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/re-index`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `POST /API/item/list`
 *
 * @function item.createItemList
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function createItemList({ ...props } = {}) {
  const path = '/API/item/list';
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/${itemId}/collections`
 *
 * @function item.listItemCollection
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listItemCollection({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/collections`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/${itemId}/library`
 *
 * @function item.listItemLibrary
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listItemLibrary({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/library`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `POST /API/item/${itemId}/lock`
 *
 * @function item.createItemLock
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createItemLock({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/lock`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/${itemId}/lock`
 *
 * @function item.getItemLock
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getItemLock({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/lock`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/lock`
 *
 * @function item.removeItemLock
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function removeItemLock({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/lock`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `PUT /API/item/${itemId}/lock`
 *
 * @function item.updateItemLock
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function updateItemLock({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/lock`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/${itemId}/metadata-lock`
 *
 * @function item.listItemMetadataLock
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listItemMetadataLock({ itemId = isRequired(), ...props }) {
  return listEntityMetadataLock({
    entity: 'item',
    entityId: itemId,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `POST /API/item/${itemId}/metadata-lock`
 *
 * @function item.createItemMetadataLock
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function createItemMetadataLock({ itemId = isRequired(), ...props }) {
  return createEntityMetadataLock({
    entity: 'item',
    entityId: itemId,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `GET /API/item/${itemId}/metadata-lock/${lockId}`
 *
 * @function item.getItemMetadataLock
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function getItemMetadataLock({ itemId = isRequired(), lockId = isRequired(), ...props }) {
  return getEntityMetadataLock({
    entity: 'item',
    entityId: itemId,
    lockId,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `PUT /API/item/${itemId}/metadata-lock/${lockId}`
 *
 * @function item.updateItemMetadataLock
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function updateItemMetadataLock({ itemId = isRequired(), lockId = isRequired(), ...props }) {
  return updateEntityMetadataLock({
    entity: 'item',
    entityId: itemId,
    lockId,
    ...props,
  });
}
/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/item.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/metadata-lock/${lockId}`
 *
 * @function item.removeItemMetadataLock
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.lockId
 * @returns {Promise<Object>}
 */
function removeItemMetadataLock({ itemId = isRequired(), lockId = isRequired(), ...props }) {
  return removeEntityMetadataLock({
    entity: 'item',
    entityId: itemId,
    lockId,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#get-loudness-values APIdoc}
 *
 * `GET /API/item/${itemId}/loudness`
 *
 * @function item.getItemLoudness
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getItemLoudness({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/loudness`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#get-loudness-values-for-interval APIdoc}
 *
 * `PUT /API/item/${itemId}/loudness`
 *
 * @function item.getItemLoudnessInterval
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
export function getItemLoudnessInterval({
  itemId = isRequired(),
  loudnessDocument = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/loudness`;
  return vFetch({
    path,
    method: 'PUT',
    body: loudnessDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#get-waveform-data APIdoc}
 *
 * `GET /API/item/${itemId}/waveform/values`
 *
 * @function item.getItemWaveformValue
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getItemWaveformValue({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/waveform/values`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#get-waveform-image APIdoc}
 *
 * `GET /API/item/${itemId}/waveform/image`
 *
 * @function item.getItemWaveformImage
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getItemWaveformImage({ itemId = isRequired(), headers: propsHeaders = {}, ...props }) {
  const path = `/API/item/${itemId}/waveform/image`;
  const headers = { accept: 'image/png', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#get-waveform-image-uri APIdoc}
 *
 * `GET /API/item/${itemId}/waveform/imageURI`
 *
 * @function item.getItemWaveformImageUri
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function getItemWaveformImageUri({ itemId = isRequired(), headers: propsHeaders = {}, ...props }) {
  const path = `/API/item/${itemId}/waveform/imageURI`;
  const headers = { accept: 'text/plain', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#get-waveform-images-for-all-audio-channels APIdoc}
 *
 * `GET /API/item/${itemId}/waveform/alltracks`
 *
 * @function item.getItemWaveformImageAllTracks
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
export function getItemWaveformImageAllTracks({
  itemId = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/item/${itemId}/waveform/alltracks`;
  const headers = { accept: 'text/html', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/analyze.html#analyze-an-item APIdoc}
 *
 * `GET /API/item/${itemId}/analyze`
 *
 * @function item.getItemWaveformImageUri
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.analyzeJobDocument
 * @returns {Promise<Object>}
 */
function createItemAnalyze({ itemId = isRequired(), analyzeJobDocument, ...props }) {
  const path = `/API/item/${itemId}/analyze`;
  return vFetch({
    path,
    method: 'POST',
    body: analyzeJobDocument,
    ...props,
  });
}

export default {
  listItem,
  getItem,
  getItemUri,
  removeItem,
  removeItemList,
  searchItem,
  updateItemMetadata,
  getItemMetadata,
  createThumbnail,
  createTranscode,
  createExport,
  createExportImp,
  listItemRelation,
  createItemRelation,
  createItemRelationList,
  removeItemRelation,
  removeAllItemRelation,
  createRelation,
  getRelation,
  updateRelation,
  removeRelation,
  getItemSearchHistory,
  reindexItem,
  createItemList,
  listItemCollection,
  listItemLibrary,
  createItemLock,
  getItemLock,
  removeItemLock,
  updateItemLock,
  listItemMetadataLock,
  createItemMetadataLock,
  getItemMetadataLock,
  updateItemMetadataLock,
  removeItemMetadataLock,
  getItemLoudness,
  getItemWaveformValue,
  getItemWaveformImage,
  getItemWaveformImageUri,
  createItemAnalyze,
};
