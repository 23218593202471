import { formatTimeBaseType } from './time';
import { parseAspectRatio } from './parseShapeType';

const parseMediaConvertPreset = (transcodePresetType) => {
  const output = {};
  const { description, mediaconvert: { outputSetting = [] } = {} } = transcodePresetType;
  output.description = description;
  if (outputSetting.length === 0) return output;
  const mc = JSON.parse(outputSetting[0]);
  const { Settings: { ContainerSettings, VideoDescription, AudioDescriptions } = {} } = mc;
  output.containerFormat = ContainerSettings.Container;
  if (ContainerSettings) {
    output.containerFormat = ContainerSettings.Container;
  }
  if (VideoDescription) {
    const { CodecSettings = {} } = VideoDescription;
    const { Codec, ...otherSettings } = CodecSettings;
    output.videoFormat = Codec;
    const { Height: height, Width: width } = VideoDescription;
    output.height = height;
    output.width = width;
    if (height && width) {
      output.dimension = `${width}x${height}`;
      output.aspectRatio = parseAspectRatio({ height, width });
    }
    const [codecSpecs] = Object.values(otherSettings);
    if (codecSpecs) {
      output.fieldOrder = codecSpecs.InterlaceMode;
      if (output.fieldOrder && output.fieldOrder.includes('TOP')) output.fieldOrder = 'interlaced';
      output.videoBitrate = codecSpecs.Bitrate;
      const numerator = codecSpecs.FramerateDenominator; // other way round to vs ¯\_(ツ)_/¯
      const denominator = codecSpecs.FramerateNumerator;
      const timeBase = formatTimeBaseType({ denominator, numerator });
      output.timeBase = timeBase;
      output.frameRate = timeBase.toRate(true);
    }
  }
  if (AudioDescriptions && AudioDescriptions.length > 0) {
    const { CodecSettings = {} } = AudioDescriptions[0];
    const { Codec, ...otherSettings } = CodecSettings;
    output.audioFormat = Codec;
    const [codecSpecs] = Object.values(otherSettings);
    if (codecSpecs) {
      output.audioSamplerate = codecSpecs.SampleRate;
      output.audioBitrate = codecSpecs.Bitrate;
      output.audioBitRateMode = codecSpecs.RateControlMode;
    }
  }
  return output;
};

const parseTranscodePreset = (transcodePresetType) => {
  const output = {};
  const { description, format, audio, video, mediaconvert } = transcodePresetType;
  if (mediaconvert) return parseMediaConvertPreset(transcodePresetType);
  output.description = description;
  output.containerFormat = format;
  if (video) {
    output.videoFormat = video.codec;
    output.videoBitrate = video.bitrate;
    if (video.framerate) {
      const timeBase = formatTimeBaseType(video.framerate);
      output.timeBase = timeBase;
      output.frameRate = timeBase.toRate(true);
    }
    if (video.scaling) {
      const { height, width } = video.scaling;
      output.height = height;
      output.width = width;
      if (height && width) {
        output.dimension = `${width}x${height}`;
        output.aspectRatio = parseAspectRatio({ height, width });
      }
    }
  }
  if (audio) {
    const { codec: audioFormat, channel } = audio;
    output.audioFormat = audioFormat;
    if (channel) output.audioChannels = channel.length;
  }
  return output;
};

export { parseTranscodePreset, parseMediaConvertPreset };
