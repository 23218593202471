/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable prefer-object-spread */
import { debounce, uuidv4 } from './utils';

function toPercent(numerator, denominator) {
  if (numerator === 0 || denominator === 0) {
    return 0;
  }

  return Math.floor((numerator / denominator) * 100);
}

const constants = Object.freeze({
  statuses: {
    transfering: 1,
    paused: 2,
    complete: 3,
    failed: 4,
    unstarted: 5,
    canceled: 6,
  },
  events: {
    progress: 'progress',
    update: 'update',
    complete: 'complete',
    error: 'error',
    added: 'upload-added',
    removed: 'upload-removed',
    retry: 'retry',
    pausing: 'pausing',
    paused: 'paused',
    resuming: 'resuming',
    offline: 'offline', // <-- don't change this one, www spec match
    online: 'online', // <-- don't change this one, www spec match
  },
  defaults: {
    targetRate: 4000000, // 4mb sec,
    chunkSize: 10000000, // 10mb chunks
    minChunkSize: 8388608, // 8mb
    maxConcurrentTransfers: 1,
    maxConcurrentFileTransfers: 1,
    autoMaxConcurrentTransfers: false,
    autoResume: true, // ChunkedTransferManager -> automatically resume transfers on network
    api: {
      props: {},
    },
    minFileSize: 104857600, // 100MB
  },
});

class CUEvents {
  // custom events model
  constructor() {
    this.events = {};
  }

  on(event, callback) {
    // add handlers
    const handlers = this.events[event] || [];
    handlers.push(callback);
    this.events[event] = handlers;
  }

  off(event) {
    // remove handlers
    if (this.events[event]) {
      this.events[event] = [];
    }
  }

  trigger(event, data) {
    // trigger event handler with data
    const handlers = this.events[event];

    if (!handlers || handlers.length < 1) return;

    handlers.forEach((handler) => {
      handler(data);
    });
  }
}

export class Chunk {
  constructor(props = {}) {
    const defaultProps = {
      blobSlice: null,
      fileSize: null,
      start: null,
      end: null,
      api: null, // constants.defaults.api
    };

    Object.assign(this, defaultProps, props);

    this.state = {
      retries: 0,
      status: constants.statuses.unstarted,
      startTime: null,
      completeTime: null,
      response: null,
      bytesTransfered: null,
    };

    this.events = new CUEvents();
  }

  // try to transfer
  try() {
    this.state.status = constants.statuses.transfering;
    this.state.startTime = performance.now();

    return new Promise((resolve, reject) => {
      const tryTransfer = () => {
        this.transfer()
          .then((response) => {
            this.state.response = response;
            this.onComplete();
            resolve();
          })
          .catch(() => {
            if (this.state.retries > 3) {
              this.state.status = constants.statuses.failed;
              reject(new Error(`Chunk ${this.start}-${this.end} failed`));
            } else if (this.state.status !== constants.statuses.paused) {
              this.state.retries += 1;
              // wait a sec and call again
              this.tryTimeout = setTimeout(() => {
                tryTransfer(); // retry
              }, 1000);
            } else {
              clearTimeout(this.tryTimeout);
            }
          });
      };

      tryTransfer(); // try again and eventually reject if nothing improves
    });
  }

  transfer() {
    let options = {
      file: this.blobSlice,
      onUploadProgress: (progressEvent) => {
        if (this.state.status !== constants.statuses.complete) {
          this.state.bytesTransfered = progressEvent.loaded;
          this.events.trigger(constants.events.progress, this.state);
        }
      },
    };
    if (this.end - this.start !== this.fileSize) {
      // only add headers if chunk !== whole file
      options.headers = {
        index: this.start,
        size: this.fileSize,
      };
    }
    options = Object.assign({}, options, this.api.props);

    this.transferPromise = this.api.method(options); // TODO implement axios onUploadProgress
    this.state.status = constants.statuses.transfering;

    return this.transferPromise;
  }

  onComplete() {
    this.state.status = constants.statuses.complete;
    this.state.bytesTransfered = this.end - this.start;
    this.state.completeTime = performance.now();
    this.events.trigger(constants.events.complete);
  }

  pause() {
    if (this.state.status !== constants.statuses.complete) {
      this.state.status = constants.statuses.paused;
    }
  }

  cancel() {
    if (this.state.status !== constants.statuses.complete) {
      this.state.status = constants.statuses.canceled;

      // reject promise
      // this.transferPromise && this.transferPromise.reject(new Error(`Chunk ${this.start}-${this.end} canceled.`))
    }
  }

  get transferRate() {
    if (this.state.bytesTransfered) {
      const secondsTaken = (this.state.completeTime - this.state.startTime) / 1000;
      const bytesPerSecond = this.state.bytesTransfered / secondsTaken;
      return bytesPerSecond;
    }
    return 0;
  }
}

export default class ChunkedUpload {
  constructor(param, options) {
    this.events = new CUEvents();

    // can be blob or transfer if for download (?)
    if (!options.api.method) throw new Error('No api method specified');
    this.defaultOptions = {
      ...constants.defaults,
      targetRate: 4000000, // bytes / second
      chunkSize: constants.defaults.minChunkSize, // the 8mb
      autoMaxConcurrentTransfers: false,
      api: {
        props: Object.assign(
          {},
          constants.defaults.api.props,
          {
            queryParams: {
              transferId: uuidv4(),
            },
          },
          options.api.props,
        ),
      },
    };

    this.options = Object.assign({}, this.defaultOptions, options);

    this.state = {
      blob: null,
      chunks: [],
      transferPromises: [],
      targetRate: this.options.targetRate,
      rate: 0, // bytes / sec transfer rate updated on progress
      autoMaxConcurrentTransfers: this.options.autoMaxConcurrentTransfers,
      // this one is self adjusting, start off small...
      maxConcurrentTransfers: this.options.maxConcurrentTransfers,
      mime: '',
      status: constants.statuses.unstarted,
      transferId: options.api.props.queryParams.transferId,
      lastStats: {},
    };

    if (typeof param === 'object') {
      // upload scenario, expect a blob
      this.state.blob = param;
      this.state.mime = param.type;
      this.addChunksFromBlob(param);
    } else {
      console.error(new Error(`Expected a File or a Blob object, but got ${typeof param}`));
    }
  }

  upload() {
    // used to upload in chunks, will look for anything that passes as uploadable
    const { status } = this.state;

    if (status === constants.statuses.complete) {
      return;
    }

    // can do uploads now
    if (status === constants.statuses.unstarted || status === constants.statuses.transfering) {
      this.state.transferPromises = []; // reset transfer promises, a queue of sorts
      /* eslint-disable-next-line max-len */
      const isOKToAddMoreTransfers = () => {
        const firstChunkStatus = this.state.chunks[0].state.status;
        const isOK =
          this.state.transferPromises.length < this.state.maxConcurrentTransfers &&
          (firstChunkStatus !== constants.statuses.transfering ||
            firstChunkStatus === constants.statuses.unstarted);

        return isOK;
      };

      // check if not too many transfers exist in transferPromises arr
      if (isOKToAddMoreTransfers()) {
        // look for unfinished business
        this.state.chunks.forEach((chunk) => {
          /* eslint-disable-next-line max-len */
          const isChunkAvailable =
            chunk.state.status !== constants.statuses.complete &&
            chunk.state.status !== constants.statuses.transfering;

          if (isChunkAvailable && isOKToAddMoreTransfers()) {
            this.state.status = constants.statuses.transfering;
            // truggers upload for chunk
            this.state.transferPromises.push(chunk.try());
          }
          // TODO - forEach looks pretty but is not the fastest, consider if perf is optimal
        });

        // reinvoke this method for next chunk batch
        if (this.state.transferPromises.length > 0) {
          // there is more work to do
          Promise.all(this.state.transferPromises)
            .then(() => {
              this.upload();
            })
            .catch((error) => {
              console.log('Failed on this upload() cycle, check connectivity and try to resume :(');
              this.state.status = constants.statuses.failed;
              this.events.trigger(constants.events.failed, { error, transfer: this });
              this.options.onFail && this.options.onFail(error);
            });
        }
      }
    } else {
      // Operation unavailable, try .resume() instead.
    }
  }

  // get all byte ranges for splitting in chunks
  getByteRangesForChunks(blob, chunkSize = this.options.chunkSize) {
    const byteRanges = []; // output

    const { minChunkSize } = this.options;
    const targetChunkSize = chunkSize >= minChunkSize ? chunkSize : minChunkSize;
    let finalChunkSize = targetChunkSize;

    // determine the number of chunks
    let times = Math.floor(blob.size / finalChunkSize);

    // if last chunk happens to bee too small, reduce the denominator
    if (!(blob.size - (blob.size - blob.size / times) >= minChunkSize)) {
      // TODO - ^^ this conditional could be more readable with variables
      times -= 1;
      finalChunkSize = Math.floor(blob.size / times); // adjust final chunk size
    }

    if (times < 2 || blob.size < this.options.minFileSize) {
      // single chunk it is
      byteRanges.push({ start: 0, end: blob.size });
    } else {
      for (let i = 0; i <= times; i++) {
        const start = finalChunkSize * i;
        let end = finalChunkSize * i + finalChunkSize;

        // if the remainig bytes to be transfered is less than minChunkSize
        const remainder = blob.size - finalChunkSize * i;
        if (remainder <= minChunkSize) {
          end = blob.size;
        }

        const range = {
          start,
          end,
        };

        byteRanges.push(range);

        if (end === blob.size) {
          break; // all byteranges till the end are covered, no more chunks
        }
      }
    }

    return byteRanges;
  }

  addChunksFromBlob(blob) {
    const byteranges = this.getByteRangesForChunks(blob);

    byteranges.forEach((byterange) => {
      const chunk = new Chunk({
        start: byterange.start,
        end: byterange.end,
        blobSlice: blob.slice(byterange.start, byterange.end),
        fileSize: blob.size,
        api: this.options.api,
      });

      chunk.events.on(constants.events.progress, () => {
        this.onProgress();
      });

      chunk.events.on(constants.events.complete, () => {
        this.onProgress();
      });

      this.state.chunks.push(chunk);
    });
  }

  retry() {
    if (this.state.status === constants.statuses.failed) {
      this.resume();
      this.events.trigger(constants.events.retry);
    }
  }

  resume() {
    this.state.status = constants.statuses.transfering;
    this.upload();
  }

  pause() {
    this.state.status = constants.statuses.paused;

    // run a loop that pauses all Chunk instances (cancel network request / promise?)
    this.state.chunks.length > 0 &&
      this.state.chunks.forEach((chunkInstance) => {
        chunkInstance.pause();
      });

    // wait for the rest of chunks to complete
    const pausedPromise = Promise.all(this.state.transferPromises);
    this.events.trigger(constants.events.pausing);

    pausedPromise.then(() => {
      this.events.trigger(constants.events.paused);
    });
    return Promise.all(this.state.transferPromises);
  }

  cancel() {
    // TODO - check what to destruct
    this.state.status = constants.statuses.canceled;

    this.state.chunks.length > 0 &&
      this.state.chunks.forEach((chunkInstance) => {
        chunkInstance.cancel();
      });

    this.upload = () => {};

    const transfersFinishedPromise = Promise.all(this.state.transferPromises);
    transfersFinishedPromise.then(() => {
      this.events.trigger(constants.events.removed, this);
    });

    // remaining transfers finishing, this can take a long time
    return transfersFinishedPromise;
  }

  // TODO - remove this, seems to be too flaky
  autoAdjustMaxConcurrentTransfers(stats) {
    // adjust max concurrent transfers based on speed (too fast - more concurrency and vice versa)
    if (
      this.state.autoMaxConcurrentTransfers &&
      this.state.status !== constants.statuses.complete
    ) {
      const speedRatio = stats.avgTransferRate / this.state.targetRate;
      if (speedRatio > 1.2) {
        // going reasonably fast, increase concurrency (this will slow things down)
        this.state.maxConcurrentTransfers += 1;
      } else if (speedRatio < 0.3) {
        // suboptimal speeds, reduce to 1 chunk at a time
        this.state.maxConcurrentTransfers = 1;
      } else {
        // reduce to no less than default (clamp)
        const newConcurrencyValue = this.options.maxConcurrentTransfers - 1;
        this.state.maxConcurrentTransfers = Math.max(
          this.options.maxConcurrentTransfers,
          Math.min(newConcurrencyValue, constants.defaults.maxConcurrentTransfers),
        );
      }
    }
  }

  onProgress() {
    try {
      // prevent promise rejection if any of this throws
      const stats = this.getStats();

      if (stats !== this.state.lastStats) {
        this.state.lastStats = stats;

        debounce(() => {
          this.autoAdjustMaxConcurrentTransfers(stats);
        }, 1000)();

        if (this.state.status !== constants.statuses.complete) {
          if (stats.totalChunks === stats.completedChunks) {
            this.onComplete();
          } else {
            this.events.trigger(constants.events.progress, stats);
            this.options.onProgress && this.options.onProgress(stats);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
    // run callback that passes exampleParams
  }

  onComplete() {
    const stats = this.getStats();

    this.state.lastStats = stats;
    this.state.status = constants.statuses.complete;

    // success callback
    this.events.trigger(constants.events.complete);
    this.options.onComplete && this.options.onComplete(this.finalResponse);
  }

  getStats() {
    const totalChunks = this.state.chunks.length;
    const completedChunks = this.state.chunks.filter(
      (chunk) => chunk.state.status === constants.statuses.complete,
    );
    const incompleteChunks = this.state.chunks.filter(
      (chunk) => chunk.state.status === constants.statuses.transfering,
    );
    const numberOfCompletedChunks = completedChunks.length;

    // determine average speed of completed transfers (bytes / second)
    let transferRateSum = 0;
    let transferedBytes = 0;
    completedChunks.forEach((chunk) => {
      transferRateSum += chunk.transferRate;
      transferedBytes += chunk.blobSlice.size;
    });

    incompleteChunks.forEach((chunk) => {
      transferedBytes += chunk.state.bytesTransfered;
    });

    const avgSpeed = transferRateSum / numberOfCompletedChunks;

    const stats = {
      totalChunks,
      completedChunks: numberOfCompletedChunks,
      totalBytes: this.state.blob.size,
      transferedBytes,
      avgTransferRate: avgSpeed,
      concurrentTransfers: this.state.maxConcurrentTransfers,
    };

    // keep in state for other computed state values
    this.state.rate = stats.avgTransferRate;

    return stats;
  }

  // if a setter is used, stop the automatic adjustment
  set concurrentTransfers(int) {
    this.state.autoMaxConcurrentTransfers = false;
    this.state.maxConcurrentTransfers = int;
  }

  set rate(int) {
    // sets target rate
    this.state.targetRate = int;
  }

  get rate() {
    // gets actual rate
    return this.state.rate;
  }

  get finalResponse() {
    const times = this.state.chunks.length - 1;
    const response = false;

    for (let i = times; i >= 0; i--) {
      const chunk = this.state.chunks[i];
      if (typeof chunk.state.response.data === 'object') {
        return chunk.state.response;
      }
    }

    return response;
  }

  get transferId() {
    return this.state.transferId;
  }
}

export class ChunkedTransferManager {
  constructor(options) {
    this.initialState = Object.freeze({
      status: constants.statuses.unstarted,
      transfers: {},
      bytesDone: 0,
      bytesTotal: 0,
      lastStats: {
        // helps evaluate progress change
        bytesDone: 0,
        bytesTotal: 0,
      },
    });
    this.state = Object.assign({}, this.initialState);

    this.options = {
      maxConcurrentFileTransfers: constants.defaults.maxConcurrentFileTransfers,
      autoResume: constants.defaults.autoResume,
      ...options,
    };

    this.events = new CUEvents();
    this.bindNetworkEvents();
  }

  bindNetworkEvents() {
    if (!(typeof window === 'undefined')) {
      // check if on browser
      window.addEventListener(constants.events.online, () => {
        this.events.trigger(constants.events.online, this.state);
        if (this.options.autoResume) {
          setTimeout(() => {
            this.retry();
          }, 1500);
        }
      });

      window.addEventListener(constants.events.offline, () => {
        this.events.trigger(constants.events.offline, this.state);
      });
    }
  }

  addChunkedUpload(cuInstance) {
    // for chunked uploads
    if (cuInstance.transferId !== null) {
      this.state.transfers[cuInstance.transferId] = cuInstance;

      // bind to ChunkedUpload events
      cuInstance.events.on(constants.events.progress, () => {
        // a chunk is finished
        this.onProgress();
      });

      cuInstance.events.on(constants.events.complete, () => {
        // a transfer is finished
        this.upload(); // start the next transfer
        this.onProgress();
      });

      cuInstance.events.on(constants.events.failed, ({ error, transfer }) => {
        this.onFail(error, transfer);
      });

      cuInstance.events.on(constants.events.retry, () => {
        this.events.trigger(constants.events.retry, this.state);
      });

      cuInstance.events.on(constants.events.paused, () => {
        if (this.paused) {
          this.events.trigger(constants.events.paused);
        }
      });

      this.updateStats();
      this.events.trigger(constants.events.added, this.state);
      this.events.trigger(constants.events.update, this.state);
    }
  }

  // param can be file or a reference to transfer, or a transferId
  // eslint-disable-next-line class-methods-use-this
  removeChunkedUpload(param, force = false) {
    const removeTransfer = (transfer) => {
      if (transfer) {
        // avoid complete transfers
        if (transfer.state.status === constants.statuses.complete && !force) {
          console.error(
            "Can't cancel complete transfers, pass true as a second argument in .removeChunkedUpload() to force removal.",
          );
          return false;
        }

        // find transfer and remove from state
        Object.keys(this.state.transfers).forEach((transferKey) => {
          if (transfer === this.state.transfers[transferKey]) {
            delete this.state.transfers[transferKey];
          }
        });

        // trigger something that will cause filelist update
        this.events.trigger(constants.events.removed);

        // stop transfering chunks
        transfer.cancel();

        return true;
      }
      // if no transfer found
      console.error('Could not find a matching transfer to cancel.');
      return false;
    };

    switch (param.constructor.name) {
      case ChunkedUpload.name:
        removeTransfer(param);
        break;
      case 'File': // find the transfer by matching file (.exists)
        removeTransfer(this.exists(param));
        break;
      case String.name: // assume a transfer id
        // get transfer by transfer id
        // then remove
        break;
      default:
        break;
    }
  }

  onProgress() {
    this.updateStats();

    if (this.hasCompleted) {
      this.onComplete();
    } else if (this.state.bytesDone !== this.state.lastStats.bytesDone) {
      this.state.status = constants.statuses.transfering;
      this.events.trigger(constants.events.progress, this.state);
    }
  }

  onComplete() {
    this.state.status = constants.statuses.complete;
    this.events.trigger(constants.events.complete, this.state);
  }

  exists(file) {
    // for checking if a file already assigned to a transfer
    let transfer = false;
    for (let index = 0; index < this.transfers.length; index++) {
      const transferToCheck = this.transfers[index];
      if (
        transferToCheck.state.blob.name === file.name &&
        transferToCheck.state.blob.lastModified === file.lastModified &&
        transferToCheck.state.blob.size &&
        file.size
      ) {
        transfer = transferToCheck;
        return transfer;
      }
    }

    return transfer;
  }

  // eslint-disable-next-line class-methods-use-this
  isTransferAvailable(transfer) {
    // to avoid operating on completed / in progress transfers
    const isAvailable =
      (transfer.state.status !== constants.statuses.complete &&
        transfer.state.status !== constants.statuses.transfering) ||
      transfer.state.status === constants.statuses.unstarted;

    return isAvailable;
  }

  pause() {
    // will resolve if not paused
    let resultingPromise = Promise.resolve(true);

    if (!this.paused) {
      this.events.trigger(constants.events.pausing, this.state);
      const pausePromises = [];
      this.transfers.forEach((transfer) => {
        pausePromises.push(transfer.pause());
      });

      resultingPromise = Promise.all(pausePromises);

      resultingPromise.then(() => {
        this.events.trigger(constants.events.paused, this.state);
      });
    }

    return resultingPromise;
  }

  resume() {
    // good for resuming transfers after network failure or after pause
    this.events.trigger(constants.events.update, this.state);
    this.transfers.forEach((transfer) => {
      if (
        this.isTransferAvailable(transfer) &&
        transfer.state.status !== constants.statuses.unstarted
      ) {
        transfer.resume();
      }
    });
  }

  retry() {
    if (this.status === constants.statuses.failed) {
      this.resume();
      this.events.trigger(constants.events.retry, this.state);
    }
  }

  upload() {
    // transfers can also be added in an already uploading state
    let ongoingTransfers = 0;

    this.transfers.forEach((transfer) => {
      if (this.isTransferAvailable(transfer)) {
        if (ongoingTransfers < this.options.maxConcurrentFileTransfers) {
          this.events.trigger(constants.events.update, this.state);
          transfer.upload();
          ongoingTransfers++;
        }
      } else if (transfer.state.status !== constants.statuses.complete) {
        ongoingTransfers++;
      }
    });

    if (ongoingTransfers > 0) {
      this.status = constants.statuses.transfering;
    }

    // trigger a simultanious upload if the number of transfers is still less than allowed
    if (
      ongoingTransfers < this.options.maxConcurrentFileTransfers &&
      this.transfers.length < ongoingTransfers
    ) {
      this.upload();
    }
  }

  onFail(error, transfer) {
    this.status = constants.statuses.failed;
    this.events.trigger(constants.events.error, { error, transfer, file: transfer.state.blob });
  }

  reset() {
    // user canceled scenario
    this.transfers.forEach((transfer) => {
      if (transfer.constructor.name === ChunkedUpload.name) {
        this.removeChunkedUpload(transfer, true);
      }
    });

    this.state = Object.assign({}, this.initialState);

    this.events.trigger(constants.statuses.update, this.state);
    this.events.trigger(constants.statuses.complete, this.state);
  }

  destroy() {
    this.reset();
  }

  get hasCompleted() {
    let completedAllTransfers = true;
    this.transfers.forEach((transfer) => {
      if (transfer.state.status !== constants.statuses.complete) {
        completedAllTransfers = false;
      }
    });

    // const uploadedAllBytes = (this.state.bytesTotal > 0 && (this.state.bytesTotal === this.state.bytesDone))

    return completedAllTransfers;
  }

  get transfers() {
    return Object.values(this.state.transfers) || [];
  }

  get status() {
    return this.state.status;
  }

  set status(value) {
    this.events.trigger(constants.statuses.update, this.state);
    this.state.status = value;
  }

  get bytesTotal() {
    let bytesTotal = 0;
    this.transfers.forEach((transfer) => {
      bytesTotal += transfer.state.blob.size;
    });

    return bytesTotal;
  }

  get bytesDone() {
    let bytesDone = 0;
    this.transfers.forEach((transfer) => {
      bytesDone += transfer.getStats().transferedBytes;
    });
    return bytesDone;
  }

  updateStats() {
    // register last stats
    this.state.lastStats.bytesDone = this.state.bytesDone;
    this.state.lastStats.bytesTotal = this.state.bytesTotal;

    // apply new stats
    this.state.bytesTotal = this.bytesTotal;
    this.state.bytesDone = this.bytesDone;
  }

  // these are convenient for displaying data
  get percentDone() {
    return toPercent(this.state.bytesDone, this.state.bytesTotal);
  }

  get fileList() {
    // produces a upload file list with status report
    const fileListArr = [];

    this.transfers.forEach((transfer) => {
      const transferStats = transfer.getStats();
      const { totalChunks, completedChunks } = transferStats;

      const fileInfo = {
        name: transfer.state.blob.name,
        bytesDone: transferStats.transferedBytes,
        bytesTotal: transfer.state.blob.size,
        totalChunks,
        completedChunks,
        transfer,
      };

      fileListArr.push(fileInfo);
    });

    return fileListArr;
  }

  get isUploading() {
    return this.state.status === constants.statuses.transfering;
  }

  get paused() {
    let allPaused = true;
    this.transfers.forEach((transfer) => {
      if (transfer.state.status !== constants.statuses.paused) {
        allPaused = false;
      }
    });

    return allPaused;
  }
}

ChunkedTransferManager.constants = constants;
ChunkedUpload.constants = constants;
