/* eslint-disable import/prefer-default-export */
import React from 'react';

import { ExternalControls } from '@vidispine/vdt-videojs';

export class VideoControls extends React.Component {
  componentDidMount() {
    const { player } = this.props; // player prop is the whole react component
    this.controls = new ExternalControls({
      target: this.controlsNode,
      ...this.props,
      player: player.current.player,
    });

    this.controls.handleNewProps(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(props) {
    this.controls.handleNewProps(props);
  }

  render() {
    return (
      <div ref={(node) => { this.controlsNode = node; }} />
    );
  }
}

VideoControls.buttonNames = ExternalControls.buttonNames;
