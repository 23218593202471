import React from 'react';
import {
  library as LibraryApi,
  item as ItemApi,
  collection as CollectionApi,
} from '@vidispine/vdt-api';
import { createMetadataType } from '@vidispine/vdt-js';
import useSearch from './useSearch';

// Has to be set so that the library isn't auto-deleted
const UPDATE_FREQUENCY = 1000;

// Prevents access controls set on the library (i.e. saved search) from cascading down to items
const UPDATE_MODE = 'TRANSIENT';

function saveSearch({ metadata, markerField, markerValue, itemSearchDocument }) {
  return ItemApi.searchItem({
    queryParams: {
      result: 'library',
      updateMode: UPDATE_MODE,
      updateFrequency: UPDATE_FREQUENCY,
    },
    itemSearchDocument,
  }).then(({ data: { library } }) =>
    CollectionApi.createCollection({
      collectionDocument: {
        metadata: markerField
          ? createMetadataType({
              [markerField]: markerValue || library,
              ...metadata,
            })
          : metadata,
        content: [
          {
            id: library,
            type: 'library',
          },
        ],
      },
    }),
  );
}

const getLibraryFromCollection = ({ collectionId }) =>
  new Promise((resolve, reject) =>
    CollectionApi.getCollection({ collectionId, queryParams: { children: 'library' } }).then(
      ({ data: collectionType }) => {
        const { content = [] } = collectionType;
        const [libraryType] = content;
        if (!libraryType) reject(new Error('No Libraries'));
        resolve(libraryType);
      },
    ),
  );

const deleteSearch = ({ collectionId }) =>
  getLibraryFromCollection({ collectionId }).then(({ id: libraryId }) =>
    Promise.all([
      LibraryApi.removeLibrary({ libraryId }),
      CollectionApi.removeCollection({ collectionId }),
    ]),
  );

function getLibrarySettings({ libraryId }) {
  return new Promise((resolve) =>
    LibraryApi.getLibrarySettings({ libraryId }).then(({ data: { query } }) => {
      resolve(query);
    }),
  );
}

const getSavedSearchSettings = ({ collectionId }) =>
  new Promise((resolve) =>
    getLibraryFromCollection({ collectionId })
      .then(({ id: libraryId }) => getLibrarySettings({ libraryId }))
      .then(resolve),
  );

export default function useSavedSearch({
  markerField,
  markerValue,
  initialSearchState,
  onSaveSearch = saveSearch,
  onDeleteSearch = deleteSearch,
  onUpdateSearchMetadata = CollectionApi.updateCollectionMetadata,
  onGetSavedSearchSettings = getSavedSearchSettings,
}) {
  const saveSearchWithMarker = React.useCallback(
    ({ metadata, itemSearchDocument }) =>
      onSaveSearch({
        metadata,
        markerField,
        markerValue,
        itemSearchDocument,
      }),
    [markerField, markerValue, onSaveSearch],
  );

  const mergeMarkerField = (itemSearchDocument = {}) => ({
    ...itemSearchDocument,
    operator: {
      field: [
        {
          name: markerField,
          value: [{ value: markerValue || '*' }],
        },
      ],
      operator: itemSearchDocument.operator ? [itemSearchDocument.operator] : [],
      operation: 'AND',
    },
  });

  const searchProps = useSearch({
    ...initialSearchState,
    itemSearchDocument: mergeMarkerField(),
  });

  const { setItemSearchDocument, ...props } = searchProps;

  return {
    onSaveSearch: saveSearchWithMarker,
    onDeleteSearch,
    onUpdateSearchMetadata,
    onGetSavedSearchSettings,
    searchProps: {
      setItemSearchDocument: (itemSearchDocument) =>
        setItemSearchDocument(mergeMarkerField(itemSearchDocument)),
      ...props,
    },
  };
}
