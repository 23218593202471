const parseFacetType = (facetType = []) => {
  const output = {};
  const facetFieldReducer = (a, { fieldValue, value }) => ({ ...a, [fieldValue]: value });
  facetType.forEach(({ name, field, count }) => {
    output[name || field] = count.reduce(facetFieldReducer, {});
  });
  return output;
};

// eslint-disable-next-line import/prefer-default-export
export { parseFacetType };
