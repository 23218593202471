import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/auto-projection.html APIdoc}
 *
 * `GET /API/auto-projection`
 *
 * @function autoprojection.listAutoProjection
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listAutoProjection({ ...props } = {}) {
  const path = '/API/auto-projection';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/auto-projection.html APIdoc}
 *
 * `GET /API/auto-projection/disable`
 *
 * @function autoprojection.listDisabledAutoProjection
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listDisabledAutoProjection({ ...props } = {}) {
  const path = '/API/auto-projection/disable';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/auto-projection.html APIdoc}
 *
 * `GET /API/auto-projection/enable`
 *
 * @function autoprojection.listEnabledAutoProjection
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listEnabledAutoProjection({ ...props } = {}) {
  const path = '/API/auto-projection/enable';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/auto-projection.html APIdoc}
 *
 * `GET /API/auto-projection/${projectionName}`
 *
 * @function autoprojection.getAutoProjection
 * @param {Object} obj
 * @param {string} obj.projectionName
 * @returns {Promise<Object>}
 */
function getAutoProjection({ projectionName = isRequired(), ...props }) {
  const path = `/API/auto-projection/${projectionName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/auto-projection.html APIdoc}
 *
 * `PUT /API/auto-projection/${projectionName}`
 *
 * @function autoprojection.createAutoProjection
 * @param {Object} obj
 * @param {string} obj.projectionName
 * @param {Object} obj.autoProjectionRuleDocument
 * @returns {Promise<Object>}
 */
function createAutoProjection({
  projectionName = isRequired(),
  autoProjectionRuleDocument = isRequired(),
  ...props
}) {
  const path = `/API/auto-projection/${projectionName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: autoProjectionRuleDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/auto-projection.html APIdoc}
 *
 * `DELETE /API/auto-projection/${projectionName}`
 *
 * @function autoprojection.removeAutoProjection
 * @param {Object} obj
 * @param {string} obj.projectionName
 * @returns {Promise<Object>}
 */
function removeAutoProjection({ projectionName = isRequired(), ...props }) {
  const path = `/API/auto-projection/${projectionName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/auto-projection.html APIdoc}
 *
 * `PUT /API/auto-projection/${projectionName}/enable`
 *
 * @function autoprojection.enableAutoProjection
 * @param {Object} obj
 * @param {string} obj.projectionName
 * @returns {Promise<Object>}
 */
function enableAutoProjection({ projectionName = isRequired(), ...props }) {
  const path = `/API/auto-projection/${projectionName}/enable`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/auto-projection.html APIdoc}
 *
 * `PUT /API/auto-projection/${projectionName}/disable`
 *
 * @function autoprojection.disableAutoProjection
 * @param {Object} obj
 * @param {string} obj.projectionName
 * @returns {Promise<Object>}
 */
function disableAutoProjection({ projectionName = isRequired(), ...props }) {
  const path = `/API/auto-projection/${projectionName}/disable`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

export default {
  listAutoProjection,
  listDisabledAutoProjection,
  listEnabledAutoProjection,
  getAutoProjection,
  createAutoProjection,
  removeAutoProjection,
  enableAutoProjection,
  disableAutoProjection,
};
