import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/export-location.html APIdoc}
 *
 * `GET /API/export-location`
 *
 * @function exportlocation.listExportLocation
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listExportLocation({ ...props } = {}) {
  return vFetch({
    path: '/API/export-location',
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/export-location.html APIdoc}
 *
 * `GET /API/export-location/${locationName}`
 *
 * @function exportlocation.getExportLocation
 * @param {Object} obj
 * @param {string} obj.locationName
 * @returns {Promise<Object>}
 */
function getExportLocation({ locationName = isRequired(), ...props }) {
  return vFetch({
    path: `/API/export-location/${locationName}`,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/export-location.html APIdoc}
 *
 * `PUT /API/export-location/${locationName}`
 *
 * @function exportlocation.updateExportLocation
 * @param {Object} obj
 * @param {string} obj.locationName
 * @param {Object} obj.exportLocationDocument
 * @returns {Promise<Object>}
 */
function updateExportLocation({ locationName = isRequired(), exportLocationDocument, ...props }) {
  const path = `/API/export-location/${locationName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: exportLocationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/export-location.html APIdoc}
 *
 * `DELETE /API/export-location/${locationName}`
 *
 * @function exportlocation.removeExportLocation
 * @param {Object} obj
 * @param {string} obj.locationName
 * @returns {Promise<Object>}
 */
function removeExportLocation({ locationName = isRequired(), ...props }) {
  const path = `/API/export-location/${locationName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/export-location.html APIdoc}
 *
 * `GET /API/export-location/${locationName}/script`
 *
 * @function exportlocation.getExportLocationScript
 * @param {Object} obj
 * @param {string} obj.locationName
 * @returns {Promise<Object>}
 */
function getExportLocationScript({
  locationName = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/export-location/${locationName}/script`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/export-location.html APIdoc}
 *
 * `PUT /API/export-location/${locationName}/script`
 *
 * @function exportlocation.updateExportLocationScript
 * @param {Object} obj
 * @param {string} obj.locationName
 * @param {string} obj.exportScript
 * @returns {Promise<Object>}
 */
function updateExportLocationScript({
  locationName = isRequired(),
  exportScript = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', contentType: 'text/plain', ...propHeaders };
  const path = `/API/export-location/${locationName}/script`;
  return vFetch({
    path,
    method: 'PUT',
    body: exportScript,
    headers,
    ...props,
  });
}

export default {
  listExportLocation,
  getExportLocation,
  updateExportLocation,
  removeExportLocation,
  getExportLocationScript,
  updateExportLocationScript,
};
