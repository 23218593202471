const IMAGE = 'IMAGE';
const AUDIO = 'AUDIO';
const VIDEO = 'VIDEO';

const getShapeMediaType = (shape = {}) => {
  const { mimeType, videoCodec, audioCodec } = shape;
  if (!mimeType) return undefined;
  if (mimeType.startsWith('audio/')) {
    if (audioCodec !== undefined) return AUDIO;
  }
  if (mimeType.startsWith('video/')) {
    if (videoCodec !== undefined) return VIDEO;
    if (audioCodec !== undefined) return AUDIO;
  }
  if (mimeType.startsWith('image/')) return IMAGE;
  return undefined;
};

export default getShapeMediaType;
