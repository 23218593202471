import { useCallback } from 'react';
import { access as AccessApi } from '@vidispine/vdt-api';
import useApi from './useApi';

const useEntityAccess = ({ entityId, entityType }) => {
  const { request: listEntityAccess, data: accessControlList = {}, isLoading } = useApi(
    AccessApi.listEntityAccess,
  );
  const onList = useCallback(
    (props = {}) =>
      listEntityAccess({
        entityId,
        entityType,
        ...props,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entityId, entityType],
  );
  const onRemove = useCallback(
    ({ accessId, ...props }) =>
      AccessApi.removeEntityAccess({
        accessId,
        entityId,
        entityType,
        ...props,
      }),
    [entityId, entityType],
  );
  const onAdd = useCallback(
    ({ accessControlDocument, ...props }) =>
      AccessApi.createEntityAccess({
        entityType,
        entityId,
        accessControlDocument,
        ...props,
      }),
    [entityId, entityType],
  );
  return {
    accessControlList,
    isLoading,
    onList,
    onRemove,
    onAdd,
  };
};

export default useEntityAccess;
