import { vFetch } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/javascript.html APIdoc}
 *
 * `POST /API/javascript/test`
 *
 * @function javascript.test
 * @param {Object} obj
 * @param {Object} obj.javascriptDocument
 * @returns {Promise<Object>}
 */
function test({ javascriptDocument, headers: propHeaders = {}, ...props }) {
  const headers = { contentType: 'application/javascript', ...propHeaders };
  const path = '/API/javascript/test';
  return vFetch({
    path,
    method: 'POST',
    body: javascriptDocument,
    headers,
    ...props,
  });
}

export default { test };
