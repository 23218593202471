import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#retrieve-the-access-control-list-for-an-entity APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/access/`
 *
 * @function access.listEntityAccess
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function listEntityAccess({ entityType = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entityType}/${entityId}/access/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#create-an-access-control-entry APIdoc}
 *
 * `POST /API/${entityType}/${entityId}/access/`
 *
 * @function access.createEntityAccess
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {Object} obj.accessControlDocument
 * @returns {Promise<Object>}
 */
function createEntityAccess({
  entityType = isRequired(),
  entityId = isRequired(),
  accessControlDocument,
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/access/`;
  return vFetch({
    path,
    method: 'POST',
    body: accessControlDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#retrieve-an-access-control-entry APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/access/${accessId}`
 *
 * @function access.getEntityAccess
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.accessId
 * @returns {Promise<Object>}
 */
function getEntityAccess({
  entityType = isRequired(),
  entityId = isRequired(),
  accessId = isRequired(),
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/access/${accessId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#list-the-applied-access-control-entries-for-an-entity APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/merged-access/`
 *
 * @function access.getEntityAccessMerged
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function getEntityAccessMerged({ entityType = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entityType}/${entityId}/merged-access/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#list-the-applied-access-control-entries-that-affects-groups APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/merged-access/group`
 *
 * @function access.getEntityAccessMergedGroup
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function getEntityAccessMergedGroup({
  entityType = isRequired(),
  entityId = isRequired(),
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/merged-access/group`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#delete-an-access-control-entry APIdoc}
 *
 * `DELETE /API/${entityType}/${entityId}/access/${accessId}`
 *
 * @function access.removeEntityAccess
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.accessId
 * @returns {Promise<Object>}
 */
function removeEntityAccess({
  entityType = isRequired(),
  entityId = isRequired(),
  accessId = isRequired(),
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/access/${accessId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#update-the-owner-of-an-entity APIdoc}
 *
 * `PUT /API/${entityType}/${entityId}/access/owner/${userName}`
 *
 * @function access.updateEntityOwner
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function updateEntityOwner({
  entityType = isRequired(),
  entityId = isRequired(),
  userName = isRequired(),
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/access/owner/${userName}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#create-multiple-entry-access-control-entries APIdoc}
 *
 * `POST /API/${entityType}/${entityId}/access/bulk`
 *
 * @function access.createEntityAccessBulk
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {Object} obj.accessControlListDocument
 * @returns {Promise<Object>}
 */
function createEntityAccessBulk({
  entityType = isRequired(),
  entityId = isRequired(),
  accessControlListDocument,
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/access/bulk`;
  return vFetch({
    path,
    method: 'POST',
    body: accessControlListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#delete-multiple-access-control-entries APIdoc}
 *
 * `DELETE /API/${entityType}/${entityId}/access/bulk`
 *
 * @function access.removeEntityAccessBulk
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {Object} obj.accessControlListDocument
 * @returns {Promise<Object>}
 */
function removeEntityAccessBulk({
  entityType = isRequired(),
  entityId = isRequired(),
  accessControlListDocument,
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/access/bulk`;
  return vFetch({
    path,
    method: 'DELETE',
    body: accessControlListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#retrieve-the-access-graph APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/access/graph`
 *
 * @function access.getEntityAccessGraph
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function getEntityAccessGraph({
  entityType = isRequired(),
  entityId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/access/graph`;
  const headers = { accept: 'image/png', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#retrieve-the-access-graph-as-dot-file APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/access/graph/dot`
 *
 * @function access.getEntityAccessGraphDot
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function getEntityAccessGraphDot({
  entityType = isRequired(),
  entityId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/access/graph/dot`;
  const headers = { accept: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#add-access-control-entries-to-all-items APIdoc}
 *
 * `GET /API/item/access/`
 *
 * @function access.createItemAllAccess
 * @param {Object} obj
 * @param {Object} obj.accessControlDocument
 * @returns {Promise<Object>}
 */
function createItemAllAccess({ accessControlDocument, ...props }) {
  const path = '/API/item/access/';
  return vFetch({
    path,
    method: 'POST',
    body: accessControlDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#delete-all-access-control-entries-from-all-items APIdoc}
 *
 * `DELETE /API/item/access/`
 *
 * @function access.removeItemAllAccess
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeItemAllAccess({ ...props } = {}) {
  const path = '/API/item/access/';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#list-the-default-access-controls-for-the-current-user APIdoc}
 *
 * `GET /API/import/access/`
 *
 * @function access.getImportAccess
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getImportAccess({ ...props } = {}) {
  const path = '/API/import/access/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#add-a-group-to-the-default-access-control-list APIdoc}
 *
 * `PUT /API/import/access/group/${groupName}`
 *
 * @function access.updateImportAccessGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function updateImportAccessGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/import/access/group/${groupName}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/access-control.html#remove-a-group-from-the-default-access-control-list APIdoc}
 *
 * `DELETE /API/import/access/group/${groupName}`
 *
 * @function access.removeImportAccessGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function removeImportAccessGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/import/access/group/${groupName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listEntityAccess,
  createEntityAccess,
  getEntityAccess,
  getEntityAccessMerged,
  getEntityAccessMergedGroup,
  removeEntityAccess,
  updateEntityOwner,
  createEntityAccessBulk,
  removeEntityAccessBulk,
  getEntityAccessGraph,
  getEntityAccessGraphDot,
  createItemAllAccess,
  removeItemAllAccess,
  getImportAccess,
  updateImportAccessGroup,
  removeImportAccessGroup,
};
