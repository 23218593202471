import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vsa.html APIdoc}
 *
 * `GET /API/vxa/`
 *
 * @function vxa.listVxa
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listVxa({ ...props } = {}) {
  const path = '/API/vxa/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vsa.html APIdoc}
 *
 * `PUT /API/vxa/enable-ssh/`
 *
 * @function vxa.createVxa
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function createVxa({ headers: propHeaders = {}, ...props } = {}) {
  const path = '/API/vxa/enable-ssh/';
  const headers = { accept: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'PUT',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vsa.html APIdoc}
 *
 * `GET /API/vxa/${vxaUuid}`
 *
 * @function vxa.getVxa
 * @param {Object} obj
 * @param {string} obj.vxaUuid
 * @returns {Promise<Object>}
 */
function getVxa({ vxaUuid = isRequired(), ...props }) {
  const path = `/API/vxa/${vxaUuid}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vsa.html APIdoc}
 *
 * `GET /API/vxa/${vxaUuid}/configuration`
 *
 * @function vxa.getVxaConfiguration
 * @param {Object} obj
 * @param {string} obj.vxaUuid
 * @returns {Promise<Object>}
 */
function getVxaConfiguration({ vxaUuid = isRequired(), ...props }) {
  const path = `/API/vxa/${vxaUuid}/configuration`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vsa.html APIdoc}
 *
 * `DELETE /API/vxa/${vxaUuid}`
 *
 * @function vxa.removeVxa
 * @param {Object} obj
 * @param {string} obj.vxaUuid
 * @returns {Promise<Object>}
 */
function removeVxa({ vxaUuid = isRequired(), ...props }) {
  const path = `/API/vxa/${vxaUuid}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listVxa,
  createVxa,
  getVxa,
  getVxaConfiguration,
  removeVxa,
};
