import React from 'react';
import { utils as api } from '@vidispine/vdt-api';

const setDefaultState = (
  {
    hasLoaded = false,
    isLoading = true,
    isError = false,
    response = undefined,
    data = undefined,
  } = {},
  responseRef,
) => ({
  isLoading,
  hasLoaded,
  isError,
  response,
  data: responseRef && responseRef.current ? responseRef.current : data,
});

const apiReducer = (state, action) => {
  switch (action.type) {
    case 'START': {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case 'SUCCESS': {
      const { responseType } = action.response;
      const newState = {
        isLoading: false,
        isError: false,
        hasLoaded: true,
        response: action.response,
        data: action.response.data,
      };
      if (responseType) newState[responseType] = action.response[responseType];
      return newState;
    }
    case 'ERROR': {
      return {
        isLoading: false,
        isError: true,
        hasLoaded: true,
        response: action.response,
        data: action.response.data,
      };
    }
    case 'RESET': {
      return {
        ...action.initialState,
      };
    }
    default: {
      return state;
    }
  }
};

const canceltoken = api.CancelToken.source();

export default function useApi(apiHandler, initialState, responseRef) {
  const initialDefaultState = setDefaultState(initialState, responseRef);
  const initialStateRef = React.useRef(initialDefaultState);
  const [state, dispatch] = React.useReducer(apiReducer, initialDefaultState);
  const request = React.useCallback(
    (props) => {
      dispatch({ type: 'START' });
      return new Promise((resolve, reject) => {
        apiHandler({ canceltoken, ...props })
          .then((response) => {
            resolve(response);
            dispatch({ type: 'SUCCESS', response });
            // eslint-disable-next-line no-param-reassign
            if (responseRef) responseRef.current = response.data;
          })
          .catch((response) => {
            reject(response);
            dispatch({ type: 'ERROR', response });
          });
      });
    },
    [apiHandler, responseRef],
  );
  const reset = React.useCallback(
    () => dispatch({ type: 'RESET', initialState: initialStateRef.current }),
    [],
  );
  React.useEffect(() => canceltoken.cancel, []);
  return {
    request,
    reset,
    ...state,
  };
}
