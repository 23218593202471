import { formatTimeCodeText } from './time';

const findNearestThumbnail = (itemType = {}, timeCodeText) => {
  const tcSeconds = formatTimeCodeText(timeCodeText).toSeconds();
  const { thumbnails = {} } = itemType;
  const { uri: srcList = [] } = thumbnails;
  const srcTcSeconds = srcList.map((url) => {
    const tc = new URL(url).pathname.split('/').pop();
    return formatTimeCodeText(tc).toSeconds();
  });

  const nearestSrcIndex = srcTcSeconds.reduce(
    (nearestIndex, curr, currIndex) =>
      Math.abs(curr - tcSeconds) < Math.abs(srcTcSeconds[nearestIndex] - tcSeconds)
        ? currIndex
        : nearestIndex,
    0,
  );
  return srcList[nearestSrcIndex];
};

export default findNearestThumbnail;
