/* eslint-disable import/prefer-default-export */
import { vFetch } from './utils';

/**
 * `GET /APInoauth/stitch`
 *
 * @function stitch.getStitch
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getStitch({ headers: propHeaders = {}, ...props } = {}) {
  const headers = { accept: 'image/png', ...propHeaders };
  const path = '/APInoauth/stitch';
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

export default { getStitch };
