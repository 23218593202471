import React from 'react';
import { metadata as MetadataApi } from '@vidispine/vdt-api';

const SettingsDispatchContext = React.createContext();
const SettingsStateContext = React.createContext();

const UPDATE_ALL_SETTINGS = 'UPDATE_ALL_SETTINGS';
const UPDATE_SETTING = 'UPDATE_SETTING';
const UPDATE_USERNAME = 'UPDATE_USERNAME';
const REMOVE_SETTING = 'REMOVE_SETTING';

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_USERNAME: {
      return { ...state, userName: action.userName };
    }
    case UPDATE_ALL_SETTINGS: {
      return { ...state, settings: action.settings };
    }
    case UPDATE_SETTING: {
      const settings = { ...state.settings, ...action.setting };
      return { ...state, settings };
    }
    case REMOVE_SETTING: {
      const settings = { ...state.settings };
      delete settings[action.key];
      return { ...state, settings };
    }
    default:
      return state;
  }
};

const SettingsProvider = ({
  userName: initialUsername,
  settingsKey,
  children,
  initialSettings = {},
  onChange,
  getSettingsApi = MetadataApi.getSimpleMetadataKey,
  updateSettingsApi = MetadataApi.updateSimpleMetadataKey,
}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    settings: initialSettings,
    userName: initialUsername,
  });
  const currentSettingsFromServer = React.useRef();

  React.useEffect(() => {
    const { userName } = state;
    if (!getSettingsApi || !userName) return;
    getSettingsApi({
      entityType: 'user',
      entityId: userName,
      key: settingsKey,
    })
      .then(({ data: settings }) => {
        dispatch({
          type: UPDATE_ALL_SETTINGS,
          settings,
          userName,
          settingsKey,
        });
        currentSettingsFromServer.current = JSON.stringify(settings);
      })
      .catch((error) => {
        const { response } = error;
        if (response.status !== 404) {
          throw new Error(error);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userName]);

  React.useEffect(() => {
    if (onChange) onChange(state.settings);
    const { userName } = state;
    if (!updateSettingsApi || !userName) return;
    const newValue = JSON.stringify(state.settings);
    const currentValue = currentSettingsFromServer.current;
    if (!currentValue || newValue === currentValue) return;
    updateSettingsApi({
      entityType: 'user',
      entityId: userName,
      key: settingsKey,
      value: newValue,
    });
    currentSettingsFromServer.current = newValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settings]);

  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
};

const useSettingsState = () => {
  const state = React.useContext(SettingsStateContext);
  if (!state) throw new Error('useSettingsState must be used within SettingsStateContext');
  return state;
};

const useSettingsDispatch = () => {
  const dispatch = React.useContext(SettingsDispatchContext);
  if (!dispatch) throw new Error('useSettingsDispatch must be used within SettingsDispatchContext');
  return dispatch;
};

const useSettingsActions = () => {
  const dispatch = useSettingsDispatch();
  const updateAllSettings = React.useCallback(
    (settings) => dispatch({ type: UPDATE_ALL_SETTINGS, settings }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const updateSetting = React.useCallback(
    (setting) => dispatch({ type: UPDATE_SETTING, setting }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const updateUsername = React.useCallback(
    (userName) => dispatch({ type: UPDATE_USERNAME, userName }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeSetting = React.useCallback((key) => dispatch({ type: REMOVE_SETTING, key }), []);
  return {
    updateAllSettings,
    updateSetting,
    updateUsername,
    removeSetting,
  };
};

const useSettings = () => {
  const { settings = {} } = useSettingsState();
  const actions = useSettingsActions();
  return {
    ...actions,
    settings,
  };
};

export { SettingsProvider, useSettingsActions, useSettings };
