/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import ErrorBoundary from '../wrappers/ErrorBoundary';

const withErrorBoundary = (WrappedComponent, errorBoundaryProps = {}) => (props) => (
  <ErrorBoundary {...errorBoundaryProps}>
    <WrappedComponent {...props} />
  </ErrorBoundary>
);

export default withErrorBoundary;
