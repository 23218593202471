import { vFetch } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/search.html APIdoc}
 *
 * `GET /API/search/`
 *
 * @function search.listItemCollection
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listItemCollection({ ...props } = {}) {
  return vFetch({
    path: '/API/search/',
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/search.html APIdoc}
 *
 * `PUT /API/search/`
 *
 * @function search.searchItemCollection
 * @param {Object} obj
 * @param {Object} obj.itemSearchDocument
 * @returns {Promise<Object>}
 */
function searchItemCollection({ itemSearchDocument, ...props }) {
  return vFetch({
    path: '/API/search/',
    method: 'PUT',
    body: itemSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/search.html APIdoc}
 *
 * `PUT /API/search/shape/`
 *
 * @function search.searchShape
 * @param {Object} obj
 * @param {Object} obj.shapeSearchDocument
 * @returns {Promise<Object>}
 */
function searchShape({ shapeSearchDocument, ...props }) {
  return vFetch({
    path: '/API/search/shape/',
    method: 'PUT',
    body: shapeSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/search.html APIdoc}
 *
 * `PUT /API/search/file/`
 *
 * @function search.searchFile
 * @param {Object} obj
 * @param {Object} obj.fileSearchDocument
 * @returns {Promise<Object>}
 */
function searchFile({ fileSearchDocument, ...props }) {
  return vFetch({
    path: '/API/search/file/',
    method: 'PUT',
    body: fileSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/search.html APIdoc}
 *
 * `PUT /API/search/autocomplete/`
 *
 * @function search.searchAutoComplete
 * @param {Object} obj
 * @param {Object} obj.autocompleteRequestDocument
 * @returns {Promise<Object>}
 */
function searchAutoComplete({ autocompleteRequestDocument, ...props }) {
  return vFetch({
    path: '/API/search/autocomplete/',
    method: 'PUT',
    body: autocompleteRequestDocument,
    ...props,
  });
}

export default {
  listItemCollection,
  searchItemCollection,
  searchShape,
  searchFile,
  searchAutoComplete,
};
