import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `GET /API/library/`
 *
 * @function library.listLibrary
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listLibrary({ ...props } = {}) {
  const path = '/API/library/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `POST /API/library/`
 *
 * @function library.createLibrary
 * @param {Object} obj
 * @param {Object} obj.itemListDocument
 * @returns {Promise<Object>}
 */
function createLibrary({ itemListDocument = {}, ...props }) {
  const path = '/API/library';
  return vFetch({
    path,
    method: 'POST',
    body: itemListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `DELETE /API/library/${libraryId}`
 *
 * @function library.removeLibrary
 * @param {Object} obj
 * @param {string} obj.libraryId
 * @returns {Promise<Object>}
 */
function removeLibrary({ libraryId = isRequired(), ...props }) {
  const path = `/API/library/${libraryId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `DELETE /API/library`
 *
 * @function library.removeLibraryList
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeLibraryList({ ...props } = {}) {
  const path = '/API/library';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `GET /API/library/${libraryId}`
 *
 * @function library.getLibrary
 * @param {Object} obj
 * @param {string} obj.libraryId
 * @returns {Promise<Object>}
 */
function getLibrary({ libraryId = isRequired(), ...props }) {
  const path = `/API/library/${libraryId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `PUT /API/library/${libraryId}`
 *
 * @function library.updateLibrary
 * @param {Object} obj
 * @param {string} obj.libraryId
 * @param {Object} obj.itemListDocument
 * @returns {Promise<Object>}
 */
function updateLibrary({ libraryId = isRequired(), itemListDocument, ...props }) {
  const path = `/API/library/${libraryId}`;
  return vFetch({
    path,
    method: 'PUT',
    body: itemListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `DELETE /API/library/${libraryId}/${itemId}`
 *
 * @function library.addLibraryItem
 * @param {Object} obj
 * @param {string} obj.libraryId
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function addLibraryItem({ libraryId = isRequired(), itemId = isRequired(), ...props }) {
  const path = `/API/library/${libraryId}/${itemId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `PUT /API/library/${libraryId}/${itemId}`
 *
 * @function library.removeLibraryItem
 * @param {Object} obj
 * @param {string} obj.libraryId
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function removeLibraryItem({ libraryId = isRequired(), itemId = isRequired(), ...props }) {
  const path = `/API/library/${libraryId}/${itemId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `PUT /API/library/${libraryId}/item-metadata`
 *
 * @function library.updateLibraryItemMetadata
 * @param {Object} obj
 * @param {string} obj.libraryId
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function updateLibraryItemMetadata({ libraryId = isRequired(), metadataDocument, ...props }) {
  const path = `/API/library/${libraryId}/item-metadata`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `GET /API/library/${libraryId}/settings`
 *
 * @function library.getLibrarySettings
 * @param {Object} obj
 * @param {string} obj.libraryId
 * @returns {Promise<Object>}
 */
function getLibrarySettings({ libraryId = isRequired(), ...props }) {
  const path = `/API/library/${libraryId}/settings`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `PUT /API/library/${libraryId}/settings`
 *
 * @function library.updateLibrarySettings
 * @param {Object} obj
 * @param {string} obj.libraryId
 * @param {Object} obj.librarySettingsDocument
 * @returns {Promise<Object>}
 */
function updateLibrarySettings({ libraryId = isRequired(), librarySettingsDocument, ...props }) {
  const path = `/API/library/${libraryId}/settings`;
  return vFetch({
    path,
    method: 'PUT',
    body: librarySettingsDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/library.html APIdoc}
 *
 * `POST /API/library/${libraryId}/list`
 *
 * @function library.getLibraryBatch
 * @param {Object} obj
 * @param {string} obj.libraryId
 * @returns {Promise<Object>}
 */
function getLibraryBatch({ libraryId = isRequired(), ...props }) {
  const path = `/API/library/${libraryId}/list`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

export default {
  listLibrary,
  createLibrary,
  removeLibrary,
  removeLibraryList,
  getLibrary,
  updateLibrary,
  addLibraryItem,
  removeLibraryItem,
  updateLibraryItemMetadata,
  getLibrarySettings,
  updateLibrarySettings,
  getLibraryBatch,
};
