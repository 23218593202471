import { vFetch, isRequired } from './utils';

/**
 * {@link n/a}
 *
 * `GET /API/analyze-preset`
 *
 * @function analyzepreset.listAnalyzePreset
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listAnalyzePreset({ ...props } = {}) {
  const path = '/API/analyze-preset';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link n/a}
 *
 * `GET /API/analyze-preset/${presetName}`
 *
 * @function analyzepreset.getAnalyzePreset
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getAnalyzePreset({ preset = isRequired(), ...props } = {}) {
  const path = `/API/analyze-preset/${preset}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  listAnalyzePreset,
  getAnalyzePreset,
};
