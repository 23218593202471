import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/resource.html APIdoc}
 *
 * `GET /API/resource/`
 *
 * @function resource.listResource
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listResource({ ...props } = {}) {
  const path = '/API/resource/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/resource.html APIdoc}
 *
 * `GET /API/resource/${resourceType}`
 *
 * @function resource.listResourceType
 * @param {Object} obj
 * @param {string} obj.resourceType
 * @returns {Promise<Object>}
 */
function listResourceType({ resourceType = isRequired(), ...props }) {
  const path = `/API/resource/${resourceType}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/resource.html APIdoc}
 *
 * `POST /API/resource/${resourceType}`
 *
 * @function resource.createResource
 * @param {Object} obj
 * @param {string} obj.resourceType
 * @param {Object} obj.resourceDocument
 * @returns {Promise<Object>}
 */
function createResource({ resourceType = isRequired(), resourceDocument, ...props }) {
  const path = `/API/resource/${resourceType}`;
  return vFetch({
    path,
    method: 'POST',
    body: resourceDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/resource.html APIdoc}
 *
 * `PUT /API/resource/${resourceType}/${resourceId}`
 *
 * @function resource.modifyResource
 * @param {Object} obj
 * @param {string} obj.resourceType
 * @param {string} obj.resourceId
 * @param {Object} obj.resourceDocument
 * @returns {Promise<Object>}
 */
function modifyResource({
  resourceType = isRequired(),
  resourceId = isRequired(),
  resourceDocument,
  ...props
}) {
  const path = `/API/resource/${resourceType}/${resourceId}`;
  return vFetch({
    path,
    method: 'PUT',
    body: resourceDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/resource.html APIdoc}
 *
 * `GET /API/resource/${resourceType}/${resourceId}`
 *
 * @function resource.getResource
 * @param {Object} obj
 * @param {string} obj.resourceId
 * @param {string} obj.resourceType
 * @returns {Promise<Object>}
 */
function getResource({ resourceType = isRequired(), resourceId = isRequired(), ...props }) {
  const path = `/API/resource/${resourceType}/${resourceId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/resource.html APIdoc}
 *
 * `DELETE /API/resource/${resourceType}/${resourceId}`
 *
 * @function resource.removeResource
 * @param {Object} obj
 * @param {string} obj.resourceType
 * @param {string} obj.resourceId
 * @returns {Promise<Object>}
 */
function removeResource({ resourceType = isRequired(), resourceId = isRequired(), ...props }) {
  const path = `/API/resource/${resourceType}/${resourceId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/resource.html APIdoc}
 *
 * `GET /API/resource/${resourceType}/${resourceId}/status`
 *
 * @function resource.statusResource
 * @param {Object} obj
 * @param {string} obj.resourceType
 * @param {string} obj.resourceId
 * @returns {Promise<Object>}
 */
function statusResource({
  resourceType = isRequired(),
  resourceId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/resource/${resourceType}/${resourceId}/status`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

export default {
  listResource,
  listResourceType,
  createResource,
  modifyResource,
  getResource,
  removeResource,
  statusResource,
};
