import React from 'react';

import { TimeCodeDisplay } from '@vidispine/vdt-videojs';

export class VideoTimeCode extends React.Component {
  componentDidMount() {
    const { player } = this.props; // player prop is the whole react component
    this.timeCode = new TimeCodeDisplay({
      target: this.timecodeNode,
      player: player.current.player,
      onInput: (player.current.player),
    });

    this.timeCode.update(this.props);
    player.current.player.setupTimeCode(this.timeCode);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(props) {
    this.timeCode.update(props);
  }

  render() {
    const { className, styles } = this.props;
    const ref = (node) => { this.timecodeNode = node; };
    return (
      <div
        styles={styles}
        className={className}
        ref={ref}
      />
    );
  }
}

export default VideoTimeCode;
