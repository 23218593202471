import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vidinet.html APIdoc}
 *
 * `GET /API/cost/${costPath}`
 *
 * @function vidinet.createCostEstimate
 * @param {Object} obj
 * @param {string} obj.path
 * @returns {Promise<Object>}
 */
function createCostEstimate({ path: costPath = isRequired(), ...props }) {
  const path = `/API/cost/${costPath}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/vidinet.html APIdoc}
 *
 * `GET /API/cost/estimate/${costEstimateId}`
 *
 * @function vidinet.getCostEstimate
 * @param {Object} obj
 * @param {string} obj.costEstimateId
 * @returns {Promise<Object>}
 */
function getCostEstimate({ costEstimateId = isRequired(), ...props }) {
  const path = `/API/cost/estimate/${costEstimateId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  createCostEstimate,
  getCostEstimate,
};
