import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/metadata`
 *
 * @function metadata.updateEntityMetadata
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function updateEntityMetadata({
  entity = isRequired(),
  entityId = isRequired(),
  metadataDocument,
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/metadata/move`
 *
 * @function metadata.moveEntityMetadata
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function moveEntityMetadata({ entity = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entity}/${entityId}/metadata/move`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entity}/${entityId}/metadata/changes`
 *
 * @function metadata.listEntityMetadataChange
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function listEntityMetadataChange({ entity = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entity}/${entityId}/metadata/changes`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entity}/${entityId}/metadata/changes/${changesetId}`
 *
 * @function metadata.getEntityMetadataChange
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.changesetId
 * @returns {Promise<Object>}
 */
function getEntityMetadataChange({
  entity = isRequired(),
  entityId = isRequired(),
  changesetId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/changes/${changesetId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entity}/${entityId}/metadata/changes/${changesetId}/compareTo/previous`
 *
 * @function metadata.previousEntityMetadataChange
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.changesetId
 * @returns {Promise<Object>}
 */
function previousEntityMetadataChange({
  entity = isRequired(),
  entityId = isRequired(),
  changesetId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/changes/${changesetId}/compareTo/previous`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entity}/${entityId}/metadata/changes/${changesetId}/compareTo/${fromChangesetId}`
 *
 * @function metadata.compareEntityMetadataChange
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.changesetId
 * @param {string} obj.fromChangesetId
 * @returns {Promise<Object>}
 */
function compareEntityMetadataChange({
  entity = isRequired(),
  entityId = isRequired(),
  changesetId = isRequired(),
  fromChangesetId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/changes/${changesetId}/compareTo/${fromChangesetId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/metadata/changes/${changesetId}/`
 *
 * @function metadata.updateEntityMetadataChange
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.changesetId
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function updateEntityMetadataChange({
  entity = isRequired(),
  entityId = isRequired(),
  changesetId = isRequired(),
  metadataDocument = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/changes/${changesetId}/`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/metadata/changes`
 *
 * @function metadata.updateAllEntityMetadataChange
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {Object} obj.metadataChangeSetDocument
 * @returns {Promise<Object>}
 */
function updateAllEntityMetadataChange({
  entity = isRequired(),
  entityId = isRequired(),
  metadataChangeSetDocument = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/changes`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataChangeSetDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/metadata/changes/trim`
 *
 * @function metadata.trimAllEntityMetadataChange
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function trimAllEntityMetadataChange({ entity = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entity}/${entityId}/metadata/changes/trim`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/metadata/changes/${changesetId}/trim`
 *
 * @function metadata.trimEntityMetadataChange
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.changesetId
 * @returns {Promise<Object>}
 */
function trimEntityMetadataChange({
  entity = isRequired(),
  entityId = isRequired(),
  changesetId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/changes/${changesetId}/trim`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `DELETE /API/${entity}/${entityId}/metadata/changes/${changesetId}`
 *
 * @function metadata.removeEntityMetadataChange
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.changesetId
 * @returns {Promise<Object>}
 */
function removeEntityMetadataChange({
  entity = isRequired(),
  entityId = isRequired(),
  changesetId = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/changes/${changesetId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/metadata/entry/${uuid}`
 *
 * @function metadata.updateEntityMetadataEntry
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {string} obj.uuid
 * @param {Object} obj.metadataEntryDocument
 * @returns {Promise<Object>}
 */
function updateEntityMetadataEntry({
  entity = isRequired(),
  entityId = isRequired(),
  uuid = isRequired(),
  metadataEntryDocument = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/entry/${uuid}`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataEntryDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entity}/${entityId}/metadata/entry/`
 *
 * @function metadata.updateAllEntityMetadataEntry
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @param {Object} obj.metadataEntryListDocument
 * @returns {Promise<Object>}
 */
function updateAllEntityMetadataEntry({
  entity = isRequired(),
  entityId = isRequired(),
  metadataEntryListDocument = isRequired(),
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/entry/`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataEntryListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entity}/${entityId}/metadata/graph`
 *
 * @function metadata.getEntityMetadataGraph
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function getEntityMetadataGraph({
  entity = isRequired(),
  entityId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/graph`;
  const headers = { accept: 'image/png', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entity}/${entityId}/metadata/graph/dot`
 *
 * @function metadata.getEntityMetadataGraphDot
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function getEntityMetadataGraphDot({
  entity = isRequired(),
  entityId = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/${entity}/${entityId}/metadata/graph/dot`;
  const headers = { accept: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entity}/${entityId}/metadata`
 *
 * @function metadata.getEntityMetadata
 * @param {Object} obj
 * @param {string} obj.entity
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function getEntityMetadata({ entity = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entity}/${entityId}/metadata`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/metadata/${metadataUuid}/`
 *
 * @function metadata.getMetadata
 * @param {Object} obj
 * @param {string} obj.metadataUuid
 * @returns {Promise<Object>}
 */
function getMetadata({ metadataUuid = isRequired(), ...props }) {
  const path = `/API/metadata/${metadataUuid}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `DELETE /API/metadata/${metadataUuid}/`
 *
 * @function metadata.removeMetadata
 * @param {Object} obj
 * @param {string} obj.metadataUuid
 * @returns {Promise<Object>}
 */
function removeMetadata({ metadataUuid = isRequired(), ...props }) {
  const path = `/API/metadata/${metadataUuid}/`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/metadata/`
 *
 * @function metadata.getMetadataGlobal
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getMetadataGlobal({ ...props } = {}) {
  const path = '/API/metadata/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/metadata/`
 *
 * @function metadata.updateMetadataGlobal
 * @param {Object} obj
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function updateMetadataGlobal({ metadataDocument, ...props } = {}) {
  const path = '/API/metadata/';
  return vFetch({
    path,
    method: 'PUT',
    body: metadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/metadata`
 *
 * @function metadata.getSimpleMetadata
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function getSimpleMetadata({ entityType = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entityType}/${entityId}/metadata`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/metadata/${subpath}
 *
 * @function metadata.getSimpleMetadataSubpath
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.subpath
 * @returns {Promise<Object>}
 */
function getSimpleMetadataSubpath({
  entityType = isRequired(),
  entityId = isRequired(),
  subpath,
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/metadata/${subpath}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entityType}/${entityId}/metadata
 *
 * @function metadata.updateSimpleMetadata
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {Object} obj.simpleMetadataDocument
 * @returns {Promise<Object>}
 */
function updateSimpleMetadata({
  simpleMetadataDocument,
  entityType = isRequired(),
  entityId = isRequired(),
  ...props
} = {}) {
  const path = `/API/${entityType}/${entityId}/metadata`;
  return vFetch({
    path,
    method: 'PUT',
    body: simpleMetadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entityType}/${entityId}/metadata/${subpath}`
 *
 * @function metadata.updateSimpleMetadataSubpath
 * @param {Object} obj
 * @param {string} obj.subpath
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {Object} obj.simpleMetadataDocument
 * @returns {Promise<Object>}
 */
function updateSimpleMetadataSubpath({
  simpleMetadataDocument,
  entityType = isRequired(),
  entityId = isRequired(),
  subpath,
  ...props
} = {}) {
  const path = `/API/${entityType}/${entityId}/metadata/${subpath}`;
  return vFetch({
    path,
    method: 'PUT',
    body: simpleMetadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `DELETE /API/${entityType}/${entityId}/metadata`
 *
 * @function metadata.removeSimpleMetadata
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function removeSimpleMetadata({
  entityType = isRequired(),
  entityId = isRequired(),
  ...props
} = {}) {
  const path = `/API/${entityType}/${entityId}/metadata`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/metadata/${key}`
 *
 * @function metadata.getSimpleMetadataKey
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.key
 * @returns {Promise<Object>}
 */
function getSimpleMetadataKey({
  entityType = isRequired(),
  entityId = isRequired(),
  key = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/metadata/${key}`;
  const headers = { accept: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `DELETE /API/${entityType}/${entityId}/metadata/${key}`
 *
 * @function metadata.removeSimpleMetadataKey
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.key
 * @returns {Promise<Object>}
 */
function removeSimpleMetadataKey({
  entityType = isRequired(),
  entityId = isRequired(),
  key = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/metadata/${key}`;
  const headers = { accept: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'DELETE',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/metadata.html APIdoc}
 *
 * `PUT /API/${entityType}/${entityId}/metadata/${key}`
 *
 * @function metadata.updateSimpleMetadataKey
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.key
 * @param {string} obj.value
 * @returns {Promise<Object>}
 */
function updateSimpleMetadataKey({
  entityType = isRequired(),
  entityId = isRequired(),
  key = isRequired(),
  value,
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/metadata/${key}`;
  const headers = { contentType: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'PUT',
    body: value,
    headers,
    ...props,
  });
}

export default {
  updateEntityMetadata,
  moveEntityMetadata,
  listEntityMetadataChange,
  getEntityMetadataChange,
  previousEntityMetadataChange,
  compareEntityMetadataChange,
  updateEntityMetadataChange,
  updateAllEntityMetadataChange,
  trimAllEntityMetadataChange,
  trimEntityMetadataChange,
  removeEntityMetadataChange,
  updateEntityMetadataEntry,
  updateAllEntityMetadataEntry,
  getEntityMetadataGraph,
  getEntityMetadataGraphDot,
  getEntityMetadata,
  getMetadata,
  removeMetadata,
  getMetadataGlobal,
  updateMetadataGlobal,
  getSimpleMetadata,
  getSimpleMetadataSubpath,
  updateSimpleMetadata,
  updateSimpleMetadataSubpath,
  removeSimpleMetadata,
  getSimpleMetadataKey,
  removeSimpleMetadataKey,
  updateSimpleMetadataKey,
};
