import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/reindex.html APIdoc}
 *
 * `GET /API/reindex/${indexName}`
 *
 * @function reindex.getReindex
 * @param {Object} obj
 * @param {string} obj.indexName
 * @returns {Promise<Object>}
 */
function getReindex({ indexName = isRequired(), ...props }) {
  const path = `/API/reindex/${indexName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/reindex.html APIdoc}
 *
 * `PUT /API/reindex/${indexName}`
 *
 * @function reindex.updateReindex
 * @param {Object} obj
 * @param {string} obj.indexName
 * @returns {Promise<Object>}
 */
function updateReindex({ indexName = isRequired(), ...props }) {
  const path = `/API/reindex/${indexName}/`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

export default {
  getReindex,
  updateReindex,
};
