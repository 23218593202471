import { vFetch, isRequired } from './utils';
import metadataApi from './metadata';

const { getSimpleMetadata: getEntitySimpleMetadata } = metadataApi;

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `GET /API/metadata-field/`
 *
 * @function metadatafield.listMetadataField
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listMetadataField({ ...props } = {}) {
  const path = '/API/metadata-field/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `GET /API/metadata-field/${fieldName}/`
 *
 * @function metadatafield.getMetadataField
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @returns {Promise<Object>}
 */
function getMetadataField({ fieldName = isRequired(), ...props }) {
  const path = `/API/metadata-field/${fieldName}/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `PUT /API/metadata-field/${fieldName}`
 *
 * @function metadatafield.updateMetadataField
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @param {Object} obj.metadataFieldDocument
 * @returns {Promise<Object>}
 */
function updateMetadataField({ fieldName = isRequired(), metadataFieldDocument, ...props }) {
  const path = `/API/metadata-field/${fieldName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataFieldDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `DELETE /API/metadata-field/${fieldName}`
 *
 * @function metadatafield.removeMetadataField
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @returns {Promise<Object>}
 */
function removeMetadataField({ fieldName = isRequired(), ...props }) {
  const path = `/API/metadata-field/${fieldName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `GET /API/metadata-field/${fieldName}/values/`
 *
 * @function metadatafield.getMetadataFieldValues
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @returns {Promise<Object>}
 */
function getMetadataFieldValues({ fieldName = isRequired(), ...props }) {
  const path = `/API/metadata-field/${fieldName}/values/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `GET/POST /API/metadata-field/${fieldName}/allowed-values/`
 *
 * @function metadatafield.getMetadataFieldAllowedValues
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @param {Object} obj.metadataFieldValueConstraintListDocument
 * @returns {Promise<Object>}
 */
function getMetadataFieldAllowedValues({
  fieldName = isRequired(),
  metadataFieldValueConstraintListDocument,
  ...props
}) {
  const path = `/API/metadata-field/${fieldName}/allowed-values/`;
  let method = 'GET';
  if (metadataFieldValueConstraintListDocument) {
    method = 'POST';
  }
  return vFetch({
    path,
    method,
    body: metadataFieldValueConstraintListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `PUT /API/metadata-field/${fieldName}/values`
 *
 * @function metadatafield.updateMetadataFieldValues
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @param {Object} obj.simpleMetadataDocument
 * @returns {Promise<Object>}
 */
function updateMetadataFieldValues({ fieldName = isRequired(), simpleMetadataDocument, ...props }) {
  const path = `/API/metadata-field/${fieldName}/values`;
  return vFetch({
    path,
    method: 'PUT',
    body: simpleMetadataDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `GET /API/metadata-field/${fieldName}/metadata`
 *
 * @function metadatafield.getSimpleMetadata
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @returns {Promise<Object>}
 */
function getSimpleMetadata({ fieldName = isRequired(), ...props }) {
  const entityType = 'metadata-field';
  return getEntitySimpleMetadata({
    entityType,
    entityId: fieldName,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `GET /API/metadata-field/${fieldName}/access`
 *
 * @function metadatafield.listMetadataFieldAccess
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @returns {Promise<Object>}
 */
function listMetadataFieldAccess({ fieldName = isRequired(), ...props }) {
  const path = `/API/metadata-field/${fieldName}/access`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `POST /API/metadata-field/${fieldName}/access`
 *
 * @function metadatafield.createMetadataFieldAccess
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @param {Object} obj.metadataFieldAccessControlDocument
 * @returns {Promise<Object>}
 */
function createMetadataFieldAccess({
  fieldName = isRequired(),
  metadataFieldAccessControlDocument = isRequired(),
  ...props
}) {
  const path = `/API/metadata-field/${fieldName}/access`;
  return vFetch({
    path,
    method: 'POST',
    body: metadataFieldAccessControlDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `DELETE /API/metadata-field/${fieldName}/access/${accessId}`
 *
 * @function metadatafield.removeMetadataFieldAccess
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @param {string} obj.accessId
 * @returns {Promise<Object>}
 */
function removeMetadataFieldAccess({
  fieldName = isRequired(),
  accessId = isRequired(),
  ...props
}) {
  const path = `/API/metadata-field/${fieldName}/access/${accessId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `GET /API/metadata-field/merged-access`
 *
 * @function metadatafield.listMetadataFieldMergedAccess
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listMetadataFieldMergedAccess({ ...props } = {}) {
  const path = '/API/metadata-field/merged-access';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/field.html APIdoc}
 *
 * `GET /API/metadata-field/${fieldName}/merged-access`
 *
 * @function metadatafield.getMetadataFieldMergedAccess
 * @param {Object} obj
 * @param {string} obj.fieldName
 * @returns {Promise<Object>}
 */
function getMetadataFieldMergedAccess({ fieldName = isRequired(), ...props } = {}) {
  const path = `/API/metadata-field/${fieldName}/merged-access`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  listMetadataField,
  getMetadataField,
  updateMetadataField,
  removeMetadataField,
  getMetadataFieldValues,
  getMetadataFieldAllowedValues,
  updateMetadataFieldValues,
  getSimpleMetadata,
  listMetadataFieldAccess,
  createMetadataFieldAccess,
  removeMetadataFieldAccess,
  listMetadataFieldMergedAccess,
  getMetadataFieldMergedAccess,
};
