import React from 'react';

const ErrorBoundaryComponent = ({ error, info }) => (
  <div
    style={{
      height: '100%',
      maxHeight: '100vh',
      width: '100%',
      maxWidth: '100vw',
      backgroundColor: 'red',
      color: 'white',
    }}
  >
    {error && <div>{error.toString()}</div>}
    {info && <div>{info.componentStack}</div>}
  </div>
);

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { error, hasError: true };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { hasError, info } = this.state;
    const { info: nextInfo } = nextState;
    if (info === nextInfo && hasError === true) return false;
    return true;
  }

  componentDidCatch(error, info) {
    const { onError } = this.props;
    if (typeof onError === 'function') {
      try {
        onError(error, info);
      } catch (onErrorError) {
        return;
      }
    }
    this.setState({ info });
  }

  render() {
    const { children } = this.props;
    const { hasError, error, info } = this.state;
    if (hasError) {
      return <ErrorBoundaryComponent info={info} error={error} />;
    }
    return children;
  }
}
