import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `GET /API/storage-rule/`
 *
 * @function storagerule.listStorageRule
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listStorageRule({ ...props } = {}) {
  const path = '/API/storage-rule/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/storage-rule`
 *
 * @function storagerule.getEntityStorageRule
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function getEntityStorageRule({ entityType = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entityType}/${entityId}/storage-rule`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `PUT /API/${entityType}/${entityId}/storage-rule`
 *
 * @function storagerule.updateEntityStorageRule
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {Object} obj.storageRuleDocument
 * @returns {Promise<Object>}
 */
function updateEntityStorageRule({
  entityType = isRequired(),
  entityId = isRequired(),
  storageRuleDocument,
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/storage-rule`;
  return vFetch({
    path,
    method: 'PUT',
    body: storageRuleDocument,
    ...props,
  });
}

export const createEntityStorageRule = updateEntityStorageRule;

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `DELETE /API/${entityType}/${entityId}/storage-rule`
 *
 * @function storagerule.removeEntityStorageRule
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @returns {Promise<Object>}
 */
function removeEntityStorageRule({ entityType = isRequired(), entityId = isRequired(), ...props }) {
  const path = `/API/${entityType}/${entityId}/storage-rule`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `GET /API/${entityType}/${entityId}/storage-rule/${tagName}`
 *
 * @function storagerule.getEntityStorageRuleTag
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function getEntityStorageRuleTag({
  entityType = isRequired(),
  entityId = isRequired(),
  tagName = isRequired(),
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/storage-rule/${tagName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `PUT /API/${entityType}/${entityId}/storage-rule/${tagName}`
 *
 * @function storagerule.updateEntityStorageRuleTag
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.tagName
 * @param {Object} obj.storageRuleDocument
 * @returns {Promise<Object>}
 */
function updateEntityStorageRuleTag({
  entityType = isRequired(),
  entityId = isRequired(),
  tagName = isRequired(),
  storageRuleDocument,
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/storage-rule/${tagName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: storageRuleDocument,
    ...props,
  });
}

export const createEntityStorageRuleTag = updateEntityStorageRuleTag;

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `DELETE /API/${entityType}/${entityId}/storage-rule/${tagName}`
 *
 * @function storagerule.removeEntityStorageRuleTag
 * @param {Object} obj
 * @param {string} obj.entityType
 * @param {string} obj.entityId
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function removeEntityStorageRuleTag({
  entityType = isRequired(),
  entityId = isRequired(),
  tagName = isRequired(),
  ...props
}) {
  const path = `/API/${entityType}/${entityId}/storage-rule/${tagName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `GET /API/shape-tag/storage-rule/${tagName}`
 *
 * @function storagerule.getStorageRuleShapeTag
 * @param {Object} obj
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function getStorageRuleShapeTag({ tagName = isRequired(), ...props }) {
  const path = `/API/shape-tag/storage-rule/${tagName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `PUT /API/shape-tag/storage-rule/${tagName}`
 *
 * @function storagerule.updateStorageRuleShapeTag
 * @param {Object} obj
 * @param {string} obj.tagName
 * @param {Object} obj.storageRuleDocument
 * @returns {Promise<Object>}
 */
function updateStorageRuleShapeTag({ tagName = isRequired(), storageRuleDocument, ...props }) {
  const path = `/API/shape-tag/storage-rule/${tagName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: storageRuleDocument,
    ...props,
  });
}

export const createStorageRuleShapeTag = updateStorageRuleShapeTag;

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-rule.html APIdoc}
 *
 * `DELETE /API/shape-tag/storage-rule/${tagName}`
 *
 * @function storagerule.removeStorageRuleShapeTag
 * @param {Object} obj
 * @param {string} obj.tagName
 * @returns {Promise<Object>}
 */
function removeStorageRuleShapeTag({ tagName = isRequired(), ...props }) {
  const path = `/API/shape-tag/storage-rule/${tagName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listStorageRule,
  getEntityStorageRule,
  updateEntityStorageRule,
  removeEntityStorageRule,
  getEntityStorageRuleTag,
  updateEntityStorageRuleTag,
  removeEntityStorageRuleTag,
  getStorageRuleShapeTag,
  updateStorageRuleShapeTag,
  removeStorageRuleShapeTag,
};
